export enum UserRoleOptions {
  GSAdmin = 'GS_ADMIN',
  SellerAdmin = 'SELLER_ADMIN',
}

export const UserRoleOptionsToDisplayText: { [key in UserRoleOptions]: string } = {
  [UserRoleOptions.SellerAdmin]: 'Seller Admin',
  [UserRoleOptions.GSAdmin]: 'GS Admin',
};

export const UserRoleOptionsComboboxItems = Object.values(UserRoleOptions).map((val) => ({
  value: val,
  displayValue: UserRoleOptionsToDisplayText[val],
}));
