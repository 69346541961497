import { Alert, Switch } from 'antd';
import { useState } from 'react';
import {
  Request,
  useGetArchivedRequestsBySellerIdQuery,
  useGetNonArchivedRequestsBySellerIdQuery,
} from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { useCurrentUserStore } from '../../shared/hooks/UseCurrentUserStore';
import RequestTable from '../components/RequestTable';

type Props = {
  sellerId?: string;
  admin?: boolean;
};

export default function SellerRequestPage({ admin, sellerId }: Props) {
  const currentUser = useCurrentUserStore((state) => state.user);
  const handleGetSellerId = () => {
    if (sellerId) {
      return sellerId;
    } else {
      return currentUser?.sellerId;
    }
  };

  const {
    data: nonArchivedData,
    loading: nonArchivedLoading,
    error: nonArchivedError,
  } = useGetNonArchivedRequestsBySellerIdQuery({
    variables: {
      sellerId: handleGetSellerId() as string,
    },
  });

  const {
    data: archivedData,
    loading: archivedLoading,
    error: archivedError,
  } = useGetArchivedRequestsBySellerIdQuery({
    variables: {
      sellerId: handleGetSellerId() as string,
    },
  });

  const openRequests = nonArchivedData?.nonArchivedRequestsBySellerId?.nodes?.filter((val) => val.isOpen);
  const closedResourceRequests = nonArchivedData?.nonArchivedRequestsBySellerId?.nodes?.filter((val) => !val.isOpen);
  const [showClosed, setShowClosed] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  if (nonArchivedLoading || archivedLoading) {
    return <SpinLoader />;
  } else if (nonArchivedError || archivedError) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching Requests, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Active Requests</h2>
      </div>
      <RequestTable requests={(openRequests as unknown as Array<Request>) ?? []} createSubmission={!admin} />
      <Switch
        className="my-5"
        unCheckedChildren="Hide Closed"
        checkedChildren="Show Closed"
        onChange={() => setShowClosed(!showClosed)}
      />
      <Switch
        className="my-5 ml-4"
        unCheckedChildren="Hide Archived"
        checkedChildren="Show Archived"
        onChange={() => setShowArchived(!showArchived)}
      />
      {showClosed && (
        <>
          <div className="flex flex-row justify-between">
            <h2>Closed Requests</h2>
          </div>
          <RequestTable requests={(closedResourceRequests as Array<Request>) ?? []} createSubmission={!admin} />
        </>
      )}
      {showArchived && (
        <>
          <div className="flex flex-row justify-between">
            <h2>Archived Requests</h2>
          </div>
          <RequestTable
            requests={(archivedData?.archivedRequestsBySellerId?.nodes as Array<Request>) ?? []}
            createSubmission={!admin}
          />
        </>
      )}
    </div>
  );
}
