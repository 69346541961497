import { Select } from 'antd';
import { useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import FormError from './FormError';

interface ComboBoxItem {
  value: string;
  displayValue: string;
}

interface ComboBoxInputProps {
  disabled?: boolean;
  name: string;
  placeholder: string;
  label: string;
  availableItems: Array<ComboBoxItem>;
  appendedElement?: ReactNode;
  required?: boolean;
  selectMultiple?: boolean;
  defaultValue?: string | string[];
}

export default function SelectInput(props: ComboBoxInputProps) {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <div className="flex flex-col w-full]">
      <label className="font-bold whitespace-nowrap	">
        {props.label} {props.required && '*'}
      </label>
      <div className="flex flex-row  w-full">
        <Select
          disabled={props.disabled}
          mode={props.selectMultiple ? 'multiple' : undefined}
          className="flex-grow flex-1 w-full"
          defaultValue={
            props.defaultValue ||
            props.availableItems.find((x) => {
              return x.value === field.value;
            })?.displayValue
          }
          status={meta.error && meta.touched ? 'error' : ''}
          placeholder={props.placeholder}
          onBlur={() => setFieldTouched(props.name, true)}
          onChange={(value) => {
            setFieldValue(props.name, value);
          }}
        >
          {props.availableItems.map((x, idx) => {
            return (
              <Select.Option key={idx} value={x.value}>
                {x.displayValue}
              </Select.Option>
            );
          })}
        </Select>
        {props.appendedElement}
      </div>
      <FormError>{meta.error && meta.touched ? meta.error : ''}</FormError>
    </div>
  );
}
