import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal, Tabs } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Seller, useDeleteSellerMutation } from '../../../graphql/generated';
import SellerRequestPage from '../../requests/pages/SellerRequestPage';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import { removeSellerFromCache } from '../SellerUtils';

interface IndividualSellerFormProps {
  seller: Seller;
}

export default function IndividualSellerForm(props: IndividualSellerFormProps) {
  const [deleteSeller] = useDeleteSellerMutation();
  const navigate = useNavigate();

  const confirmDeleteSeller = (seller: Seller) => {
    Modal.confirm({
      title: `Delete Seller: ${seller.name}`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Seller? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteSeller({
          variables: {
            id: seller.id,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying to delete the Seller, please try again in 5 minutes or contact support.',
            );
          },
        });
        removeSellerFromCache(seller.id);
        navigate(localRoutingConstants.rfqs.root);
        message.success(`Seller: ${seller.name} was successfully deleted.`);
      },
    });
  };

  return (
    <>
      <div className="flex flex-row justify-end gap-2">
        <Link to={`edit`}>
          <Button type="primary">Edit</Button>
        </Link>
        <Button onClick={() => confirmDeleteSeller(props.seller)} type="primary" danger>
          Delete
        </Button>
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="View Active Requests" key="2">
          <SellerRequestPage sellerId={props.seller.id} admin />
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
