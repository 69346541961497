import { Button, message } from 'antd';
import Table from 'antd/lib/table';
import { FieldArray, Form, Formik } from 'formik';
import lodash from 'lodash';
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCurrentUserStore } from '../../../features/shared/hooks/UseCurrentUserStore';
import {
  ChargeType,
  CreateMultiRequestSubmissionInput,
  CreateRequestSubmissionInput,
  Request,
  RequestSubmission,
  SubmissionStatus,
  useCreateMultiRequestSubmissionsMutation,
  useGetRequestSubmissionsBySellerAndRequestQuery,
  useGetSellersQuery,
} from '../../../graphql/generated';
import MultiFileUploader from '../../shared/components/files/MultiFileUploader';
import { ComboBoxItem } from '../../shared/components/form/ComboBoxInput';
import NumberFormInput from '../../shared/components/form/NumberFormInput';
import SelectInput from '../../shared/components/form/SelectInput';
import TextFormInput from '../../shared/components/form/TextFormInput';
import FileUploadError from '../../shared/exceptions/FileUploadError';
import { useCitiesState } from '../../shared/hooks/UseCitiesState';
import useMultiFileUpload, { AttachmentInfo, GSFile, GSFiles } from '../../shared/hooks/UseMultiFileUpload';
import { ResidenciesToComboBoxItems } from '../../shared/models/rfq/Residencies';
import { ResourceAvailabilityComboBoxItems } from '../../shared/models/rfq/ResourceAvailability';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceComboboxItems } from '../../shared/models/rfq/RfqSecurityClearance';
import { getApolloErrorType } from '../../shared/utils/ApolloErrorUtils';
import { createSubmissionValidator } from '../validators/RequestSubmissionValidator';
import SpinLoader from '../../shared/components/loader/SpinLoader';

type Props = {
  request: Request;
};

export default function CandidateSubmissionForm({ request }: Props) {
  const statusArray = new Array<SubmissionStatus>();
  statusArray[0] = SubmissionStatus.Wip;

  const defaultRequestSubmission = {
    requestId: request.requestId,
    sellerId: request.seller?.id,
    name: '',
    location: '',
    residency: '',
    security: '',
    availability: '',
    chargeRate: 0,
    fedGovExperience: '',
    attachments: '',
    status: SubmissionStatus.Wip,
    interviewTime: null,
  };
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { loading: rpLoading } = useGetSellersQuery();
  const [createMultiRequestSubmissions] = useCreateMultiRequestSubmissionsMutation();
  const { setFiles, handleMultiFileUpload } = useMultiFileUpload();
  const sellerId = useCurrentUserStore((store) => store.user?.sellerId);
  const { data: submissionsData } = useGetRequestSubmissionsBySellerAndRequestQuery({
    variables: {
      sellerId: sellerId!,
      requestId: request.requestId,
    },
    fetchPolicy: 'no-cache',
  });
  const existingSubmissions = {
    submissions:
      submissionsData?.requestSubmissionsBySellerAndRequest?.nodes?.map((submission) => {
        return {
          ...submission,
          requestId: submission.request?.requestId,
          sellerId: sellerId,
        };
      }) ?? [],
  };
  const numberOfExistingSubmissions = existingSubmissions.submissions.length;

  const cities = useCitiesState(request?.rfq?.country as string);

  const handleCreateRequestSubmissions = async (
    multiRequestSubmissions: CreateMultiRequestSubmissionInput,
  ): Promise<Array<RequestSubmission>> => {
    const response = await createMultiRequestSubmissions({
      variables: {
        rs: multiRequestSubmissions,
      },
    });

    return response.data?.createMultiRequestSubmissions as Array<RequestSubmission>;
  };

  return (
    <Formik
      validationSchema={createSubmissionValidator}
      initialValues={existingSubmissions}
      enableReinitialize
      validateOnChange
      onSubmit={async (values) => {
        setLoading(true);
        try {
          //Submit the requests
          const multiRequestSubmissions = await handleCreateRequestSubmissions({
            inputs: values.submissions.slice(
              numberOfExistingSubmissions,
            ) as unknown as Array<CreateRequestSubmissionInput>,
          });
          //For each submission response, upload the corresponding file to s3.
          let attachments: Array<AttachmentInfo> = [];
          multiRequestSubmissions.forEach((response) => {
            // addSubmissionToCache(response);
            const resAttach = response.attachments;
            if (resAttach) {
              attachments = attachments.concat(response.attachments as AttachmentInfo[]);
            }
          });
          await handleMultiFileUpload(attachments);
          message.success(`The Request Submissions for ${request.rfq?.rFQNumber} was successfully created!`);
          navigate(`./../`);
        } catch (ex) {
          if (getApolloErrorType(ex)) {
            message.error(`Error creating Request Submission, please contact support`);
          } else if (ex instanceof FileUploadError) {
            message.error(ex.message);
          } else {
            message.error(`An error occurred whilst creating the Request Submissions.`);
          }
        }
        setLoading(false);
      }}
    >
      {({ submitForm, values }) => {
        return (
          <Form>
            <FieldArray name="submissions">
              {(arrayHelpers) => {
                return (
                  <Fragment>
                    <Table
                      pagination={false}
                      scroll={{ x: 'max-content' }}
                      rowKey="id"
                      dataSource={arrayHelpers.form.values.submissions.map(
                        (submission: RequestSubmission, idx: number) => ({ ...submission, key: idx }),
                      )}
                      columns={[
                        {
                          title: 'Name/Proposal',
                          dataIndex: 'name',
                          fixed: 'left',
                          render: (_, _record, index) => (
                            <TextFormInput
                              name={`submissions[${index}].name`}
                              placeholder="Name/Proposal"
                              disabled={index <= numberOfExistingSubmissions - 1}
                            />
                          ),
                        },
                        {
                          title: 'Location',
                          dataIndex: 'location',
                          width: '10%',
                          render: (_, _record, index) => (
                            <>
                              {cities ? (
                                <SelectInput
                                  name={`submissions[${index}].location`}
                                  placeholder="Location"
                                  label=""
                                  selectMultiple
                                  availableItems={cities as ComboBoxItem[]}
                                  defaultValue={_record.location}
                                  disabled={index <= numberOfExistingSubmissions - 1}
                                />
                              ) : (
                                <SpinLoader />
                              )}
                            </>
                          ),
                        },
                        {
                          title: 'Residency',
                          dataIndex: 'residency',
                          width: '10%',
                          render: (_, _record, index) => (
                            <SelectInput
                              name={`submissions[${index}].residency`}
                              placeholder="Residency"
                              label=""
                              availableItems={ResidenciesToComboBoxItems}
                              disabled={index <= numberOfExistingSubmissions - 1}
                            />
                          ),
                        },
                        {
                          title: 'Security Clearance',
                          width: '10%',
                          render: (_, _record, index) => (
                            <SelectInput
                              name={`submissions[${index}].security`}
                              placeholder="Security Clearance"
                              label=""
                              availableItems={SecurityClearanceComboboxItems}
                              disabled={index <= numberOfExistingSubmissions - 1}
                            />
                          ),
                        },
                        {
                          title: 'Availability',
                          dataIndex: 'availability',
                          width: '10%',
                          render: (_, _record, index) => (
                            <SelectInput
                              name={`submissions[${index}].availability`}
                              placeholder="Availability"
                              label=""
                              availableItems={ResourceAvailabilityComboBoxItems}
                              disabled={index <= numberOfExistingSubmissions - 1}
                            />
                          ),
                        },
                        {
                          title: `Charge (${request.rfq?.currencyCode} ${
                            ChargeTypeOptionsToAbbreviatedText[request.rfq?.chargeType as ChargeType]
                          })`,
                          dataIndex: 'chargeRate',
                          width: '15%',
                          render: (_, _record, index) => (
                            <NumberFormInput
                              currency={true}
                              name={`submissions[${index}].chargeRate`}
                              placeholder="Charge Rate"
                              label=""
                              defaultValue={_record.chargeRate}
                              disabled={index <= numberOfExistingSubmissions - 1}
                              chargeType={request.rfq?.chargeType as ChargeType}
                            />
                          ),
                        },

                        {
                          title: 'Attachments',
                          dataIndex: 'attachments',
                          render: (_, _record, index) => (
                            <MultiFileUploader
                              name={`submissions[${index}].attachments`}
                              maxSize={20000000}
                              maxCount={10}
                              disabled={index <= numberOfExistingSubmissions - 1}
                              onFilesChanged={(rcFiles) => {
                                setFiles((oldFiles) => {
                                  //We add the row index to the file name so we can distinguish it.
                                  //1. Remove all existing files for this index
                                  const fileObj: GSFiles = lodash.cloneDeep(oldFiles);
                                  Object.entries(fileObj).map(([key, value]) => {
                                    if (value.index === index) {
                                      delete fileObj[key];
                                    }
                                  });
                                  //2. Now add the newly changes files
                                  rcFiles.forEach((file) => {
                                    const fileWithIndex = new GSFile(file, index);
                                    // fileWithIndex.index = index;
                                    fileObj[file.name] = fileWithIndex;
                                  });
                                  //return the updated file object.
                                  return fileObj;
                                });
                              }}
                            />
                          ),
                        },
                        {
                          title: '',
                          render: (_, _record, index) => (
                            <div className="align-top items-start flex flex-row gap-x-2 submission-Action">
                              {values.submissions.length > 1 && (
                                <Button
                                  disabled={index <= numberOfExistingSubmissions - 1}
                                  onClick={() => arrayHelpers.remove(index)}
                                  type="primary"
                                  danger
                                >
                                  Remove
                                </Button>
                              )}
                            </div>
                          ),
                        },
                      ]}
                    />
                    <tr className="ant-table-measure-row" key="edit">
                      <td colSpan={16}>
                        <Button
                          onClick={() => {
                            arrayHelpers.push(defaultRequestSubmission);
                          }}
                          loading={rpLoading}
                          type="primary"
                          disabled={values.submissions.length >= request.numberOfCandidates}
                        >
                          Add a New Submission
                        </Button>
                      </td>
                    </tr>
                  </Fragment>
                );
              }}
            </FieldArray>
            <div className="flex flex-row justify-end gap-2">
              <Link to="./..">
                <Button type="default">Cancel</Button>
              </Link>
              <Button loading={loading} type="primary" onClick={submitForm}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
