import { convertFromRaw, EditorState } from 'draft-js';
import { useField, useFormikContext } from 'formik';
import { useRef, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import FormError from './FormError';

interface RichTextInputFieldProps {
  name: string;
  label: string;
  initialValue?: string;
  readonly?: boolean;
}
export default function RichTextInputField(props: RichTextInputFieldProps) {
  const form = useFormikContext();
  const ref = useRef<Editor>(null);
  const [focus, setFocus] = useState<boolean>();
  const [, meta] = useField(props);
  return (
    <div className="flex flex-col">
      <label className="font-bold whitespace-nowrap">{props.label}</label>
      <Editor
        ref={ref}
        onFocus={() => {
          setFocus(true);
        }}
        onBlur={() => {
          setFocus(false);
        }}
        readOnly={props.readonly}
        toolbar={{ options: ['inline', 'list'] }}
        onContentStateChange={async (e) => {
          await form.setFieldValue(props.name, JSON.stringify(e));
          await form.setFieldTouched(props.name, true);
        }}
        editorClassName={`ant-input ${focus ? 'ant-input-focused' : ''} max-h-full w-full break-words`}
        placeholder="Enter information here"
        defaultEditorState={
          props.initialValue ? EditorState.createWithContent(convertFromRaw(JSON.parse(props.initialValue))) : undefined
        }
        stripPastedStyles={true}
      />
      <FormError>{meta.error && meta.touched ? meta.error : ''}</FormError>
    </div>
  );
}
