import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Switch } from 'antd';
import { useState } from 'react';
import {
  Request,
  Rfq,
  useGetAllArchivedRequestsQuery,
  useGetAllNonArchivedRequestsQuery,
  useGetAllRfqsQuery,
} from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import RequestTable from '../components/RequestTable';
import RfqSelectModal from '../components/RfqSelectModal';

export default function RequestPage() {
  const {
    refetch: archivedRefetch,
    data: nonArchivedRequestData,
    loading: nonArchivedRequestLoading,
    error: nonArchivedRequestError,
  } = useGetAllNonArchivedRequestsQuery({ fetchPolicy: 'no-cache' });

  const {
    refetch: nonArchivedRefetch,
    data: archivedRequestData,
    loading: archivedRequestLoading,
    error: archivedRequestError,
  } = useGetAllArchivedRequestsQuery({ fetchPolicy: 'no-cache' });

  const { data: rfqsData } = useGetAllRfqsQuery();

  const openRequests = nonArchivedRequestData?.allNonArchivedRequests?.nodes?.filter(
    (val: { isOpen: boolean }) => val.isOpen,
  );
  const closedResourceRequests = nonArchivedRequestData?.allNonArchivedRequests?.nodes?.filter(
    (val: { isOpen: boolean }) => !val.isOpen,
  );
  const openRfqs = rfqsData?.rfqs?.nodes?.filter((val) => val.status);
  const [isRfqModalOpen, setIsRfqModalOpen] = useState(false);
  const [showClosed, setShowClosed] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const onDelete = async () => {
    await nonArchivedRefetch();
    await archivedRefetch();
  };

  if (nonArchivedRequestLoading || archivedRequestLoading) {
    return <SpinLoader />;
  } else if (nonArchivedRequestError || archivedRequestError) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching Requests, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between ">
        <h2>Active Requests</h2>
        <Button icon={<PlusOutlined />} onClick={() => setIsRfqModalOpen(true)} type="primary">
          Add New
        </Button>
      </div>
      <RequestTable onSuccessfulDelete={onDelete} requests={(openRequests as unknown as Array<Request>) ?? []} />
      <Switch
        className="my-5"
        unCheckedChildren="Hide Closed"
        checkedChildren="Show Closed"
        onChange={() => setShowClosed(!showClosed)}
      />
      <Switch
        className="my-5 ml-4"
        unCheckedChildren="Hide Archived"
        checkedChildren="Show Archived"
        onChange={() => setShowArchived(!showArchived)}
      />
      {showClosed && (
        <>
          <h2>Closed Requests</h2>
          <RequestTable onSuccessfulDelete={onDelete} requests={(closedResourceRequests as Array<Request>) ?? []} />
        </>
      )}
      {showArchived && (
        <>
          <h2>Archived Requests</h2>
          <RequestTable
            onSuccessfulDelete={onDelete}
            requests={(archivedRequestData?.allArchivedRequests?.nodes as Array<Request>) ?? []}
          />
        </>
      )}
      <RfqSelectModal
        openRfqs={(openRfqs as unknown as Array<Rfq>) ?? []}
        isModalOpen={isRfqModalOpen}
        setIsModalOpen={setIsRfqModalOpen}
      />
    </div>
  );
}
