import { GetAllIndustriesDocument, GetAllIndustriesQuery, Industry } from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addIndustryToCache = (industry: Industry): void => {
  const query = apolloClient.cache.readQuery<GetAllIndustriesQuery>({
    query: GetAllIndustriesDocument,
  });

  const newBuyers = query?.industries?.nodes?.concat({ ...industry });

  apolloClient.cache.updateQuery<GetAllIndustriesQuery>({ query: GetAllIndustriesDocument }, () => ({
    industries: { nodes: newBuyers },
  }));
};

export const removeIndustryFromCache = (deletedId: string): void => {
  const query = apolloClient.cache.readQuery<GetAllIndustriesQuery>({
    query: GetAllIndustriesDocument,
  });
  const updatedIndustry = query?.industries?.nodes?.filter((industry) => {
    if (deletedId == industry.id) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetAllIndustriesQuery>({ query: GetAllIndustriesDocument }, () => ({
    industries: { nodes: updatedIndustry },
  }));
};
