import create from 'zustand';
import { CurrentUserRole } from '../models/user/UserRole';

export interface CurrentUserDetails {
  id: string;
  email: string;
  role: CurrentUserRole;
  sellerId: string;
  sellerName: string;
  firstName: string;
  lastName: string;
}

type CurrentUserOrUndefined = CurrentUserDetails | undefined;

interface CurrentUserStore {
  user?: CurrentUserOrUndefined;
  setUser: (user: CurrentUserOrUndefined) => void;
}

export const useCurrentUserStore = create<CurrentUserStore>()((set) => ({
  setUser(user) {
    set(() => ({ user: user }));
  },
}));
