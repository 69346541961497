import { CSSProperties } from 'react';
import { TransitionStatus } from 'react-transition-group';

export const defaultStyle: CSSProperties = {
  transition: `transform 300ms ease-in-out`,
  transformOrigin: 'bottom right',
  transform: 'scale(0)',
};

export const transitionStyles: { [key in TransitionStatus]?: CSSProperties } = {
  entering: { transform: 'scale(0)' },
  entered: { transform: 'scale(1)' },
  exiting: { transform: 'scale(1)' },
  exited: { transform: 'scale(0)' },
  unmounted: { transform: 'scale(0)' },
};
