import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { Industry, useDeleteIndustryByIdMutation } from '../../../graphql/generated';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { removeIndustryFromCache } from '../IndustryUtils';

type IndustriesTableProps = {
  industries: Array<Industry>;
};
export default function IndustriesTable({ industries }: IndustriesTableProps) {
  const getColumnSearchProps = UseGetColumnSearchProps<Industry>();
  const [deleteIndustry] = useDeleteIndustryByIdMutation();

  const confirmDeleteIndustry = (industryId: string) => {
    Modal.confirm({
      title: `Delete Industry`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Indsutry? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteIndustry({
          variables: {
            id: industryId,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the Industry, please try again in 5 minutes or contact support.',
            );
          },
        });
        removeIndustryFromCache(industryId);
        message.success(`Industry was successfully deleted.`);
      },
    });
  };
  const buyerColumns: ColumnsType<Industry> = useMemo(
    () => [
      {
        title: 'Industry Name',
        dataIndex: 'name',
        render: (name) => <span className="font-bold">{name}</span>,
        ...getColumnSearchProps('name'),
        filterSearch: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (buyerId) => (
          <div className="flex flex-row gap-x-2">
            <Button onClick={() => confirmDeleteIndustry(buyerId)} type="primary" danger>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      scroll={{ x: 'max-content' }}
      columns={buyerColumns}
      dataSource={industries.map((industry) => ({ ...industry, key: industry.id }))}
    />
  );
}
