import { PDFDownloadLink } from '@react-pdf/renderer';
import { Alert, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { RequestSubmission, useGetRequestSubmissionsByIdQuery } from '../../../graphql/generated';
import SubmissionDetailedDescription from '../../requests/components/SubmissionDetailedDescription';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import RequestSubmissionPDF from '../components/RequestSubmissionToPDF';

export default function ViewRequestSubmissionPage() {
  const { requestSubmissionId } = useParams();
  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRequestSubmissionsByIdQuery({
    variables: {
      requestSubmissionId: requestSubmissionId as string,
    },
    fetchPolicy: 'no-cache',
  });

  const requestSubmission = data?.requestSubmissionById as RequestSubmission;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching request submissions, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col items-end">
        <Button className="flex flex-col items-end" type="primary">
          <PDFDownloadLink
            document={<RequestSubmissionPDF requestSubmission={requestSubmission} />}
            fileName={'submission_' + requestSubmission.name + '_' + requestSubmission.requestSubmissionId + '.pdf'}
          >
            {({ loading }) => (loading ? 'Processing PDF' : 'Download As PDF')}
          </PDFDownloadLink>
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2>Submission Details</h2>
      </div>
      <SubmissionDetailedDescription requestSubmission={requestSubmission} />
    </>
  );
}
