import { Button, Popover } from 'antd';
import { Document, Packer, Paragraph, TextRun, UnderlineType } from 'docx';
import { useState } from 'react';
import { Request } from '../../../graphql/generated';

type DocxProps = {
  request: Request;
};

function generateDocument(text: string, fileName: string) {
  return new Document({
    background: {
      color: '#FAFAFA',
    },
    title: fileName,
    creator: 'GoConnect',
    description: 'The supporting information for the request',
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: 'GoConnect Request Supporting Information',
                bold: true,
                size: 32,
                underline: {
                  type: UnderlineType.THICK,
                },
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: text,
                break: 1,
              }),
            ],
          }),
        ],
      },
    ],
  });
}

function generateLegacyDocument(text: string, fileName: string) {
  return new Document({
    compatibility: {
      version: 15,
      doNotExpandShiftReturn: true,
    },
    background: {
      color: '#FAFAFA',
    },
    title: fileName,
    creator: 'GoConnect',
    description: 'The supporting information for the request',
    sections: [
      {
        children: [
          new Paragraph({
            children: [
              new TextRun({
                text: 'GoConnect Request Supporting Information',
                bold: true,
                size: 32,
                underline: {
                  type: UnderlineType.THICK,
                },
              }),
            ],
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: text,
                break: 1,
              }),
            ],
          }),
        ],
      },
    ],
  });
}

async function handleDownloadDocument(text: string, fileName: string) {
  const docxDocument = generateDocument(text, fileName);
  //Creating an anchor element on the DOM to initiate the download
  const element = document.createElement('a');
  const docxFile = Packer.toBlob(docxDocument);
  element.href = URL.createObjectURL(await docxFile);
  element.download = fileName;
  document.body.appendChild(element);
  //this may be needed to work with Firefox
  element.click();
}

async function handleDownloadLegacyDocument(text: string, fileName: string) {
  const docxDocument = generateLegacyDocument(text, fileName);
  //Creating an anchor element on the DOM to initiate the download
  const element = document.createElement('a');
  const docxFile = Packer.toBlob(docxDocument);
  element.href = URL.createObjectURL(await docxFile);
  element.download = fileName + '.doc';
  document.body.appendChild(element);
  //this may be needed to work with Firefox
  element.click();
}

export default function SupportingInformationToDocx(props: DocxProps) {
  const rawSupportingInformation = JSON.parse(props.request.rfq?.supportingInformation || '{}');
  const extractedText = rawSupportingInformation?.blocks?.map((b: { text: string }) => b.text).join('\n');
  const [popoverOpen, setPopoverOpen] = useState(false);
  const handleOpenChange = (newOpen: boolean) => {
    setPopoverOpen(newOpen);
  };

  return (
    <Popover
      content={
        <>
          <div className="pb-1 w-36">
            For <strong>recent</strong> word processors (2015 +):
          </div>
          <Button
            type="primary"
            className="w-36"
            onClick={() =>
              handleDownloadDocument(extractedText, 'Request_Supporting_Information_' + props.request.requestId)
            }
          >
            Download as .docx
          </Button>
          <br />
          <div className="py-1 w-36">
            For <strong>legacy</strong> word processors (pre-2015):
          </div>
          <Button
            type="primary"
            danger
            className="w-36"
            onClick={() =>
              handleDownloadLegacyDocument(extractedText, 'Request_Supporting_Information_' + props.request.requestId)
            }
          >
            Download as .doc
          </Button>
        </>
      }
      title="Compatibility Options"
      trigger="click"
      open={popoverOpen}
      onOpenChange={handleOpenChange}
    >
      <Button type="primary">Download as .docx</Button>
    </Popover>
  );
}
