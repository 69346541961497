import { UserPresence } from '@chatkitty/core';
import { Avatar } from 'antd';
import React, { ReactNode } from 'react';
import { getInitialsFromName } from '../../utils/ChatUtils';
import styles from './MessagesHeader.module.less';

type MessagesHeaderProps = {
  displayName: string;
  status?: UserPresence;
  actionButtons?: ReactNode;
};

export default function MessagesHeader({ displayName, status, actionButtons }: MessagesHeaderProps) {
  return (
    <div className={styles.messagesHeader}>
      <div className="relative">
        <Avatar size={'large'}>
          <span className="text-lg font-bold">{getInitialsFromName(displayName)}</span>
        </Avatar>
        {status && <div className={`${status?.online ? 'bg-green-600' : 'bg-gray-400'} ${styles.statusIndicator}`} />}
      </div>
      <div className="pl-2 flex flex-col justify-center">
        <div className="font-bold text-lg leading-3">{displayName}</div>
        {status && <div className="text-sm text-gray-400 leading-3 pt-1">{status?.online ? 'Active' : 'Away'}</div>}
        {actionButtons}
      </div>
    </div>
  );
}
