import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  ChargeType,
  Residencies,
  Request,
  RequestSubmission,
  SecurityClearance,
  SubmissionStatus,
  useDeleteRequestSubmissionByIdMutation,
} from '../../../graphql/generated';
import SubmissionStatusRenderer from '../../shared/components/submission-status/SubmissionStatusRenderer';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { ResidenciesToDisplayText } from '../../shared/models/rfq/Residencies';
import { ResourceAvailability, ResourceAvailabilityToDisplayText } from '../../shared/models/rfq/ResourceAvailability';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceOptionsToDisplayText } from '../../shared/models/rfq/RfqSecurityClearance';
import { CurrencyFormatter } from '../../shared/utils/IntlUtils';
import { removeRequestSubmissionFromCache } from '../requestSubmissionUtils';
import EditRequestSubmissionForm from './EditRequestSubmissionForm';
import SellerEditRequestSubmissionForm from './SellerEditRequestSubmissionForm';

type RFQManagementTableProps = {
  requestSubmissions: Array<RequestSubmission>;
  isSeller?: boolean;
  request?: Request;
  handleRefetch: () => void;
};

export default function RequestSubmissionTable({
  requestSubmissions,
  isSeller,
  request,
  handleRefetch,
}: RFQManagementTableProps) {
  const getColumnSearchProps = UseGetColumnSearchProps<RequestSubmission>();
  const { requestId } = useParams<{ requestId: string }>();

  const [confirmLoading] = useState(false);
  const [modalSubmissionId, setModalSubmissionId] = useState<string | null>(null);
  const [EditSubmissionModalOpen, setEditSubmissionModalOpen] = useState(false);
  const [selectedSubmissionId, setSelectedSubmissionId] = useState<string>();

  const [deleteRequestSubmission] = useDeleteRequestSubmissionByIdMutation();

  const showModal = (requestSubmissionId: string) => {
    setModalSubmissionId(requestSubmissionId);
  };

  const handleOk = () => {
    setModalSubmissionId(null);
    handleRefetch();
  };

  const handleCancel = () => {
    setModalSubmissionId(null);
  };

  const sellerHandleCancel = () => {
    setEditSubmissionModalOpen(false);
  };

  const sellerHandleOk = () => {
    setEditSubmissionModalOpen(false);
    handleRefetch();
  };

  const confirmDeleteRequestSubmission = (requestSubmissionId: string) => {
    Modal.confirm({
      title: `Delete Request Submission`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Request Submission? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        try {
          await deleteRequestSubmission({
            variables: {
              requestSubmissionId: requestSubmissionId,
            },
          });
          removeRequestSubmissionFromCache(requestSubmissionId);
          message.success(`Request Submission was successfully deleted.`);
          handleRefetch();
        } catch (ex) {
          message.error(
            'An error occurred whilst trying delete the Request Submission, please try again in 5 minutes or contact support.',
          );
        }
      },
    });
  };

  const requestSubmissionsColumns: ColumnsType<RequestSubmission> = useMemo(
    () => [
      {
        title: 'Name/Proposal',
        dataIndex: 'name',
        ...getColumnSearchProps('name'),
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        ...getColumnSearchProps('status'),
        render: (s: SubmissionStatus) => {
          return <SubmissionStatusRenderer status={s} />;
        },
        sorter: (a, b) => a.status[0].localeCompare(b.status[0]),
      },
      {
        title: 'Location',
        dataIndex: 'location',
        ...getColumnSearchProps('location'),
        render: (locations: Array<Location>) => {
          return <div>{locations.join(', ')}</div>;
        },
      },
      {
        title: 'Residency',
        dataIndex: 'residency',
        render: (c: Residencies) => {
          return <div>{ResidenciesToDisplayText[c]}</div>;
        },
        sorter: (a, b) => a.residency.localeCompare(b.residency),
      },
      {
        title: 'Security',
        dataIndex: 'security',
        ...getColumnSearchProps('security'),
        sorter: (a, b) => a.security.localeCompare(b.security),
        render: (s: SecurityClearance) => {
          return <div>{SecurityClearanceOptionsToDisplayText[s]}</div>;
        },
      },
      {
        title: 'Availability',
        dataIndex: 'availability',
        render: (a: ResourceAvailability) => {
          return <div>{ResourceAvailabilityToDisplayText[a]}</div>;
        },
      },
      {
        title: `Charge (${request?.rfq?.currencyCode} ${
          ChargeTypeOptionsToAbbreviatedText[request?.rfq?.chargeType as ChargeType]
        })`,
        dataIndex: 'chargeRate',
        render: (cr) => CurrencyFormatter.format(cr),
        sorter: (a, b) => a.chargeRate - b.chargeRate,
      },
      {
        title: 'Actions',
        dataIndex: 'requestSubmissionId',
        render: (requestSubmissionId: string) => (
          <div className="flex flex-row gap-x-2">
            {isSeller && (
              <div className="flex flex-row gap-x-2">
                <Link to={`request-submissions/${requestSubmissionId}`}>
                  <Button type="primary">View</Button>
                </Link>
                <Button
                  type="primary"
                  onClick={() => {
                    setSelectedSubmissionId(requestSubmissionId);
                    setEditSubmissionModalOpen(true);
                  }}
                >
                  Edit
                </Button>
                <Button danger type="primary" onClick={() => confirmDeleteRequestSubmission(requestSubmissionId)}>
                  Delete
                </Button>
              </div>
            )}
            {!isSeller && (
              <>
                <Link to={`request-submissions/${requestSubmissionId}`}>
                  <Button disabled={request?.noSubmission as boolean}>View</Button>
                </Link>
                <Button
                  disabled={request?.noSubmission as boolean}
                  type="primary"
                  onClick={() => showModal(requestSubmissionId)}
                >
                  Update Status
                </Button>
              </>
            )}
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <div>
      <Table
        pagination={false}
        scroll={{ x: 'max-content' }}
        columns={requestSubmissionsColumns}
        dataSource={requestSubmissions.map((requestSubmission) => ({
          ...requestSubmission,
          key: requestSubmission.requestSubmissionId,
        }))}
      />
      {isSeller && (
        <Link to={`/seller-request/${requestId}/create`}>
          <Button className="mt-4" type="primary" disabled={request?.noSubmission || request?.isOpen === false}>
            Create New Submissions {request?.noSubmission}
          </Button>
        </Link>
      )}

      <Modal
        title="Update Request Submission Status"
        open={modalSubmissionId != null}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={null}
      >
        <EditRequestSubmissionForm
          requestSubmissionId={modalSubmissionId}
          handleSubmit={handleOk}
          handleCancel={handleCancel}
        />
      </Modal>
      <Modal
        title="Edit Request Submission"
        open={EditSubmissionModalOpen}
        onOk={sellerHandleOk}
        confirmLoading={confirmLoading}
        onCancel={sellerHandleCancel}
        footer={null}
      >
        <SellerEditRequestSubmissionForm
          requestSubmissionId={selectedSubmissionId}
          handleSubmit={sellerHandleOk}
          handleCancel={sellerHandleCancel}
        />
      </Modal>
    </div>
  );
}
