import {
  GetAllRequestSubmissionsDocument,
  GetAllRequestSubmissionsQuery,
  RequestSubmission,
} from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addSubmissionToCache = (requestSubmission: RequestSubmission): void => {
  const query = apolloClient.cache.readQuery<GetAllRequestSubmissionsQuery>({
    query: GetAllRequestSubmissionsDocument,
  });
  const newRequestSubmissions = query?.requestSubmissions?.nodes?.concat({ ...requestSubmission });
  apolloClient.cache.updateQuery<GetAllRequestSubmissionsQuery>({ query: GetAllRequestSubmissionsDocument }, () => ({
    requestSubmissions: { nodes: newRequestSubmissions },
  }));
};

export const removeRequestSubmissionFromCache = (deletedSubmissionId: string): void => {
  const query = apolloClient.cache.readQuery<GetAllRequestSubmissionsQuery>({
    query: GetAllRequestSubmissionsDocument,
  });
  const updatedRequestSubmission = query?.requestSubmissions?.nodes?.filter((requestSubmission) => {
    if (deletedSubmissionId == requestSubmission.requestSubmissionId) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetAllRequestSubmissionsQuery>({ query: GetAllRequestSubmissionsDocument }, () => ({
    requestSubmissions: { nodes: updatedRequestSubmission },
  }));
};
