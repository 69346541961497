import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge, Button, Descriptions, message, Modal, Tabs, Upload, UploadFile } from 'antd';
import { getName } from 'country-list';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import { Rfq, useCloseRfqMutation, useDeleteRfqByNumberMutation } from '../../../graphql/generated';
import RichTextReader from '../../shared/components/form/RichTextReader';
import { ActivityTypeToDisplayText } from '../../shared/models/rfq/ActivityType';
import { ResidenciesToDisplayText } from '../../shared/models/rfq/Residencies';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceOptionsToDisplayText } from '../../shared/models/rfq/RfqSecurityClearance';
import { WorkingArrangementsToDisplayText } from '../../shared/models/rfq/RfqWorkingArrangements';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import { CurrencyFormatter } from '../../shared/utils/IntlUtils';
import { editRfqInCache, removeRfqFromCache } from '../rfqUtils';

type RFQDetailedDescriptionProps = {
  rfq: Rfq;
};

export default function RFQDetailedDescription({ rfq }: RFQDetailedDescriptionProps) {
  const [deleteRfq] = useDeleteRfqByNumberMutation();
  const [closeRfq] = useCloseRfqMutation();
  const navigate = useNavigate();
  const confirmDeleteRfq = (rfqNumber: string) => {
    Modal.confirm({
      title: `Delete Rfq: ${rfqNumber}`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to delete this RFQ? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteRfq({
          variables: {
            deleteRfqNumber: rfqNumber,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the RFQ, please try again in 5 minutes or contact support.',
            );
          },
        });
        removeRfqFromCache(rfqNumber);
        navigate(localRoutingConstants.rfqs.root);
        message.success(`RFQ: ${rfqNumber} was successfully deleted.`);
      },
    });
  };
  const confirmCloseRfq = (rfqNumber: string) => {
    Modal.confirm({
      title: `Close Rfq: `,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to close this RFQ?',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await closeRfq({
          variables: {
            closeRfqNumber: rfqNumber,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying to close the RFQ, please check if RFQ is already closed or please try again in 5 minutes or contact support.',
            );
          },
          onCompleted: () => {
            message.success(`RFQ: was successfully closed.`);
          },
        });
        editRfqInCache(rfq);
        navigate(localRoutingConstants.rfqs.root);
      },
    });
  };

  return (
    <>
      <div className="flex flex-row justify-end gap-2">
        <Link to={`edit`}>
          <Button type="primary">Edit</Button>
        </Link>
        <Button
          onClick={() => confirmCloseRfq(rfq.rFQNumber)}
          type="primary"
          disabled={rfq.status ? !rfq.status : true}
        >
          Close RFQ
        </Button>
        <Button onClick={() => confirmDeleteRfq(rfq.rFQNumber)} type="primary" danger>
          Delete
        </Button>
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab="RFQ Overview" key="1">
          <Descriptions title="Overview" className="overflow-x-auto" bordered>
            <Descriptions.Item span={3} label="RFQ Number">
              {rfq.rFQNumber}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Title">
              {rfq.title}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Activity Type">
              {ActivityTypeToDisplayText[rfq.activityType]}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Status">
              <Badge status={rfq.status ? 'success' : 'error'} text={rfq.status ? 'Open' : 'Closed'} />
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Industry">
              {rfq.industry?.name ?? 'Unassigned'}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Buyer">
              {rfq?.buyer?.name ?? 'Unassigned'}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Country">
              {getName(rfq?.country) ?? 'Unassigned'}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Location">
              {rfq.location.join(', ')}
            </Descriptions.Item>
            {rfq.extensionTerms && (
              <Descriptions.Item span={3} label="Extension Terms">
                {rfq.extensionTerms}
              </Descriptions.Item>
            )}
            <Descriptions.Item span={3} label="Residency">
              {rfq.residency.map((value, index) => (
                <p key={index}>{ResidenciesToDisplayText[value]}</p>
              ))}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="RFQ Published Date">
              {moment(rfq.rFQPublishedDate).format('MMMM Do YYYY')}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Request Closure Date">
              {moment(rfq.requestClosureDate).format('MMMM Do YYYY, h:mm:ss a')}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Estimated Start Date">
              {moment(rfq.estimatedStartDate).format('MMMM Do YYYY')}
            </Descriptions.Item>
            {rfq.contractDuration && (
              <Descriptions.Item span={3} label="Contract Duration">
                {moment(rfq.contractDuration).format('MMMM Do YYYY')}
              </Descriptions.Item>
            )}
            <Descriptions.Item span={2} label="Working Arrangement">
              {WorkingArrangementsToDisplayText[rfq.workingArrangement]}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Security Clearance">
              {SecurityClearanceOptionsToDisplayText[rfq.security]}
            </Descriptions.Item>
            <Descriptions.Item span={2} label="Max. Submissions">
              {rfq.maxSubmissions}
            </Descriptions.Item>
            <Descriptions.Item span={2} label={rfq.maxCharge ? 'Max. Charge' : 'Charge Type'}>
              {`${rfq.maxCharge ? CurrencyFormatter.format(rfq.maxCharge) : ''} ${rfq.currencyCode} ${
                ChargeTypeOptionsToAbbreviatedText[rfq.chargeType]
              }`}
            </Descriptions.Item>
            <Descriptions.Item span={3} label="Attachments">
              <Upload
                disabled={true}
                fileList={
                  rfq.attachments?.map((att) => {
                    return {
                      name: att?.fileName,
                      uid: att?.objectKey,
                      url: att?.url,
                    };
                  }) as Array<UploadFile>
                }
                listType="text"
              ></Upload>
            </Descriptions.Item>
          </Descriptions>
        </Tabs.TabPane>
        {rfq?.supportingInformation && (
          <Tabs.TabPane tab="Supporting Information" key="2">
            <RichTextReader content={rfq.supportingInformation} />
          </Tabs.TabPane>
        )}
      </Tabs>
    </>
  );
}
