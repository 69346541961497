import { Button, Modal, message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDisableUserMutation, useEnableUserMutation, useGetUserByIdQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import CreateOrEditUserForm from '../components/CreateOrEditUserForm';
import { useCurrentUserStore } from '../../shared/hooks/UseCurrentUserStore';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { updateUserEnabledInCache } from '../userUtils';

type Props = {};

export default function EditUserPage({}: Props) {
  const { userId } = useParams<{ userId: string }>();
  const navigate = useNavigate();
  const [enableUser, { loading: enableLoading }] = useEnableUserMutation();
  const [disableUser, { loading: disableLoading }] = useDisableUserMutation();
  const currentUser = useCurrentUserStore().user;
  const { data, loading } = useGetUserByIdQuery({
    variables: {
      getUserId: userId as string,
    },
    onError: () => {
      navigate(localRoutingConstants.users.root);
      message.error('An error occurred, you may only edit users who already have an account.');
    },
  });
  if (loading) {
    return <SpinLoader />;
  }

  const handleUserEnabled = async (userId: string, enable: boolean): Promise<void> => {
    const operation = enable ? enableUser : disableUser;
    await operation({
      variables: {
        userId: userId,
      },
      onError: () => {
        const errorMessage = enable ? 'enable' : 'disable';
        message.error(
          `An error occurred whilst trying to ${errorMessage} the user, please try again in 5 minutes or contact support.`,
        );
      },
      onCompleted: () => {
        const successMessage = enable ? 'enabled' : 'disabled';
        message.success(`User was successfully ${successMessage}.`);
        updateUserEnabledInCache(userId as string, enable);
        navigate(localRoutingConstants.users.root);
      },
    });
  };

  const confirmEnableOrDisableUser = (userId: string, enable: boolean) => {
    Modal.confirm({
      title: enable ? 'Enable User' : 'Disable User',
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content: enable
        ? 'Are you sure you wish to enable this user? Once enabled, the user will be able to log in and use the platform as usual.'
        : 'Are you sure you wish to disable this user? Once disabled, they will no longer be able to log in and use the platform.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await handleUserEnabled(userId, enable);
      },
    });
  };

  return (
    <>
      <div className="flex justify-end">
        <Button
          disabled={userId == currentUser?.id}
          loading={enableLoading || disableLoading || loading}
          className=""
          type="primary"
          danger
          onClick={() => {
            data?.userById.enabled
              ? confirmEnableOrDisableUser(userId as string, false)
              : confirmEnableOrDisableUser(userId as string, true);
          }}
        >
          {data?.userById.enabled ? 'Disable User' : 'Enable User'}
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2>Edit User</h2>
        <div className="py-2">Edit user details using the form below.</div>
        <CreateOrEditUserForm editUser={data?.userById} className="max-w-3xl" />
      </div>
    </>
  );
}
