import { Channel, Message as ChatMessage } from '@chatkitty/core';
import { Fragment, useContext, useState } from 'react';
import { Seller } from '../../../../graphql/generated';
import SellerSelectionModal from '../../../sellers/components/seller-selection-modal/SellerSelectionModal';
import SpinLoader from '../../../shared/components/loader/SpinLoader';
import { AdminChannelListContext } from '../../context/AdminChannelListContext';
import styles from './AdminChannelList.module.less';
import ChannelPreview from './ChannelPreview';
import CreateChannelPreview from './CreateChannelPreview';

type Props = {
  sellers: Array<Seller>;
  onSelect: (channel: Channel) => Promise<void> | void;
  onCreate: (seller: Seller) => Promise<void> | void;
};

export default function AdminChannelList({ onSelect: onSelected, sellers, onCreate }: Props) {
  const { channels, loading, fetchChannelList } = useContext(AdminChannelListContext);
  const [showSelector, setShowSelector] = useState<boolean>(false);

  return (
    <Fragment>
      <div className={styles.channelList}>
        <CreateChannelPreview onClick={() => setShowSelector(true)} />
        {loading && <SpinLoader className="h-10" />}
        {channels &&
          !loading &&
          channels.map((channel) => {
            return (
              <div
                onClick={() => {
                  onSelected && onSelected(channel);
                }}
                key={channel.id}
              >
                <ChannelPreview
                  channelName={
                    sellers.find((seller) => {
                      return seller.id === channel.name;
                    })?.name ?? 'Unknown'
                  }
                  lastMessageSent={{
                    body: (channel.lastReceivedMessage as ChatMessage)?.body,
                    senderName: channel.lastReceivedMessage?.user?.name,
                    time: channel.lastReceivedMessage?.createdTime
                      ? new Date(channel.lastReceivedMessage?.createdTime)
                      : null,
                  }}
                />
              </div>
            );
          })}
      </div>

      <SellerSelectionModal
        sellers={sellers}
        show={showSelector}
        onSelected={async (seller) => {
          onCreate && (await onCreate(seller));
          await fetchChannelList();
        }}
        close={() => {
          setShowSelector(false);
        }}
      />
    </Fragment>
  );
}
