import { Input } from 'antd';
import { useField } from 'formik';
import FormError from './FormError';

type Props = {
  disabled?: boolean;
  name: string;
  placeholder: string;
  label?: string;
  required?: boolean;
  defaultValue?: string;
};

export default function TextFormInput(props: Props) {
  const [field, meta] = useField(props);

  return (
    <div className="flex flex-col">
      {props.label && (
        <label className="font-bold whitespace-nowrap	">
          {props.label} {props.required && '*'}
        </label>
      )}
      <Input
        disabled={props.disabled}
        {...field}
        placeholder={props.placeholder}
        status={meta.error && meta.touched ? 'error' : ''}
        defaultValue={props.defaultValue}
      />
      <FormError>{meta.error && meta.touched ? meta.error : ''}</FormError>
    </div>
  );
}
