import { DownOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Layout, Menu, message, Row } from 'antd';
import { Auth } from 'aws-amplify';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCurrentUserStore } from '../../hooks/UseCurrentUserStore';
import apolloClient from '../../utils/ApolloClient';

export default function TopNavigation() {
  const user = useCurrentUserStore((store) => store.user);
  const name = useMemo(() => {
    if (user?.firstName && user?.lastName) {
      return {
        fullName: user?.firstName + ' ' + user?.lastName,
        initials: `${user?.firstName[0]}${user?.lastName[0]}`,
      };
    } else {
      return {
        initials: 'GS',
      };
    }
  }, [user]);
  const navigate = useNavigate();
  return (
    <Layout.Header className="p-0 bg-white z-10">
      <Row className="h-full mr-4" justify="end" align="middle">
        <Avatar className="font-bold mx-3" size="default">
          {name?.initials}
        </Avatar>
        <Dropdown
          trigger={['click']}
          overlay={
            <Menu>
              <Menu.Item onClick={signOut}>Log Out</Menu.Item>
            </Menu>
          }
        >
          <div className="flex justify-center items-center font-bold cursor-pointer gap-x-2 transition-colors duration-100 hover:text-gray-500">
            <span>{name?.fullName}</span>
            <DownOutlined size={20} />
          </div>
        </Dropdown>
      </Row>
    </Layout.Header>
  );
  async function signOut() {
    try {
      navigate('/');
      apolloClient.resetStore();
      await Auth.signOut();
      window.location.reload();
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      message.error('Error occurred during sign out: ', error.toString());
    }
  }
}
