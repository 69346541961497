import { Editor } from 'react-draft-wysiwyg';
import { convertFromRaw } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState } from 'draft-js';

interface RichTextInputFieldProps {
  content?: string;
  initialValue?: string;
  readonly?: boolean;
}
export default function RichTextInputField(props: RichTextInputFieldProps) {
  const contentState = convertFromRaw(JSON.parse(props.content as string));
  const editorState = EditorState.createWithContent(contentState);
  return (
    <div className="flex flex-col ">
      <Editor
        readOnly
        toolbarHidden
        editorClassName="input-field max-h-full w-full break-words"
        defaultEditorState={editorState}
      />
    </div>
  );
}
