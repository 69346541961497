import { Button, Modal } from 'antd';
import { Rfq } from '../../../graphql/generated';
import RFQManagementTable from '../../rfq-management/components/RFQManagementTable';

type Props = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  openRfqs: Array<Rfq>;
};

export default function RfqSelectModal({ openRfqs, isModalOpen, setIsModalOpen }: Props) {
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <Modal
      title="Select an RFQ for new Request"
      visible={isModalOpen}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
      ]}
      width={1140}
    >
      <RFQManagementTable rfqs={(openRfqs as unknown as Array<Rfq>) ?? []} createRequest />
    </Modal>
  );
}
