import { Alert, Button, message, Modal } from 'antd';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  CreateUserInput,
  UpdateUserInput,
  useCreateUserMutation,
  useGetSellersQuery,
  User,
  UserRole,
  useUpdateUserMutation,
} from '../../../graphql/generated';
import ComboBoxInput from '../../shared/components/form/ComboBoxInput';
import SelectInput from '../../shared/components/form/SelectInput';
import TextFormInput from '../../shared/components/form/TextFormInput';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { UserRoleOptionsComboboxItems } from '../../shared/models/user/UserRoleOptions';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import { getApolloErrorType } from '../../shared/utils/ApolloErrorUtils';
import { addUserToCache, editUserInCache } from '../userUtils';
import { createUserValidator, editUserValidator } from '../validators/UserValidator';

type CreateOrEditUserFormProps = {
  className: string;
  editUser?: User;
};

export default function CreateOrEditUserForm({ className, editUser }: CreateOrEditUserFormProps) {
  const navigate = useNavigate();
  const { loading: rpLoading, data: rpData, error: rpError } = useGetSellersQuery();
  const [createUser, { loading: createLoading }] = useCreateUserMutation();
  const [updateUser, { loading: updateLoading }] = useUpdateUserMutation();
  const [showCreateSeller, setShowCreateSeller] = useState<boolean>(false);
  const handleCreateUser = async (user: CreateUserInput): Promise<User> => {
    const response = await createUser({
      variables: {
        user: user,
      },
    });

    return response.data?.createUser as User;
  };
  const handleUpdateUser = async (user: UpdateUserInput): Promise<User> => {
    const response = await updateUser({
      variables: {
        UpdateUserInput: user,
      },
    });

    return response.data?.updateUser as User;
  };

  return (
    <Formik
      validationSchema={editUser ? editUserValidator : createUserValidator}
      initialValues={createUserValidator.cast(
        {
          ...editUser,
          sellerId: editUser?.seller?.id,
          role: editUser?.role.includes(UserRole.SellerAdmin) ? UserRole.SellerAdmin : UserRole.GsAdmin,
        } ?? {},
      )}
      onSubmit={async (values) => {
        //If initial values are not set, then it's a create form.
        try {
          let user: User;
          if (editUser) {
            const validUser = editUserValidator.cast(values, { stripUnknown: true });
            user = await handleUpdateUser(validUser as UpdateUserInput);
            editUserInCache(user);
            message.success(`Details for the user with the the email - ${values.email} - were successfully updated !`);
          } else {
            user = await handleCreateUser(values as CreateUserInput);
            addUserToCache(user);
            message.success(`A user with the the email - ${values.email} - was successfully invited !`);
          }
          navigate(localRoutingConstants.users.root);
        } catch (ex) {
          if (getApolloErrorType(ex)) {
            message.error(
              `The request could not be fulfilled because a user with the email - ${values.email} - already exists.`,
            );
          } else {
            message.error(`An error occurred whilst ${editUser ? 'updating' : 'creating'} the user.`);
          }
        }
      }}
    >
      {({ submitForm, values }) => {
        if (rpLoading) {
          return <SpinLoader />;
        } else if (rpError) {
          return (
            <Alert
              showIcon
              type="error"
              message="An unknown error has occurred, please wait two minutes and try again or contact support."
            />
          );
        }

        return (
          <Form className={`${className} w-full`}>
            <TextFormInput disabled={editUser != null} name="email" placeholder="Email" label="Email" />
            <TextFormInput name="firstName" placeholder="First Name" label="First Name" />
            <TextFormInput name="lastName" placeholder="Last Name" label="Last Name" />
            <SelectInput
              disabled={editUser != null}
              name="role"
              placeholder="Role"
              label="Role"
              availableItems={UserRoleOptionsComboboxItems}
            />
            <>
              {values.role != UserRole.GsAdmin && (
                <>
                  <ComboBoxInput
                    disabled={editUser != null}
                    name="sellerId"
                    placeholder="Select a seller"
                    label="Seller"
                    availableItems={rpData?.sellers?.nodes?.map((x) => ({ displayValue: x.name, value: x.id })) ?? []}
                  />
                </>
              )}
            </>
            <div className="flex flex-row justify-end gap-2">
              <Link to={localRoutingConstants.users.root}>
                <Button type="default">Cancel</Button>
              </Link>
              <Button loading={createLoading || updateLoading} type="primary" onClick={submitForm}>
                Submit
              </Button>
            </div>
            <Modal
              title="Create Seller"
              footer={null}
              open={showCreateSeller}
              onCancel={() => setShowCreateSeller(false)}
            ></Modal>
          </Form>
        );
      }}
    </Formik>
  );
}
