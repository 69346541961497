import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import GSLogo from '../../../assets/images/go_menu_logo_with_text.png';
import { ChargeType, Residencies, Request, SecurityClearance, WorkingArrangement } from '../../../graphql/generated';
import { DefaultPDFStyles } from '../../shared/components/PDF/DefaultPDFStyles';
import { ResidenciesToDisplayText } from '../../shared/models/rfq/Residencies';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceOptionsToDisplayText } from '../../shared/models/rfq/RfqSecurityClearance';
import { WorkingArrangementsToDisplayText } from '../../shared/models/rfq/RfqWorkingArrangements';
import { CurrencyFormatter } from '../../shared/utils/IntlUtils';

type Props = {
  request: Request;
};

export default function RequestPDF(props: Props) {
  const styles = DefaultPDFStyles;

  const dateTimeRetrieved = new Date().toLocaleString();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image source={GSLogo} style={styles.logo} />
        <View>
          <Text style={styles.title}>Request</Text>
          <Text style={styles.h1}>RFQ Number: {props.request.rfq?.rFQNumber}</Text>
          <Text style={styles.h1}>Seller: {props.request.seller?.name}</Text>
          <Text style={styles.h1}>Retrieved: {dateTimeRetrieved}</Text>
        </View>
        <Text> </Text>
        <Text style={styles.h2}>Request Info:</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Requested Seller</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.seller?.name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Number of Submissions Requested</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.numberOfCandidates}</Text>
            </View>
          </View>
        </View>

        <Text style={styles.h2}>RFQ Overview:</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>RFQ Number</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.seller?.name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>RFQ Title</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.rfq?.title}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Status</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.isOpen ? 'Open' : 'Closed'}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Buyer</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.rfq?.buyer?.name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Extension Terms</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.rfq?.extensionTerms}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Residency</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.request.rfq?.residency.map((r: Residencies) => ResidenciesToDisplayText[r] + ' ')}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>RFQ Published Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(props.request.rfq?.rFQPublishedDate).format('MMMM Do YYYY, h:mm:ss a')}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Request Closure Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(props.request.rfq?.requestClosureDate).format('MMMM Do YYYY, h:mm:ss a')}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Estimated Start Date</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {moment(props.request.rfq?.estimatedStartDate).format('MMMM Do YYYY, h:mm:ss a')}
              </Text>
            </View>
          </View>

          {props.request.rfq?.contractDuration && (
            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>Contract Duration</Text>
              </View>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>
                  {moment(props.request.rfq?.contractDuration).format('MMMM Do YYYY, h:mm:ss a')}
                </Text>
              </View>
            </View>
          )}

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Working Arrangement</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {
                  // Defaults to new N/A value when no is found. This also
                  // gives the option of N/A in the selections, so is probably fine
                  props.request.rfq
                    ? WorkingArrangementsToDisplayText[props.request.rfq?.workingArrangement as WorkingArrangement]
                    : 'NA'
                }
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Location</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.rfq?.location.join(', ')}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Security Clearance</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {
                  // Defaults to new N/A value when no is found. This also
                  // gives the option of N/A in the selections, so is probably fine
                  SecurityClearanceOptionsToDisplayText[
                    props.request.rfq?.security ? (props.request.rfq?.security as SecurityClearance) : 'NA'
                  ]
                }
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Max. Submissions</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.request.rfq?.maxSubmissions}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Charge</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.request.rfq?.maxCharge
                  ? CurrencyFormatter.format(props.request.rfq?.maxCharge) +
                    ChargeTypeOptionsToAbbreviatedText[props.request.rfq?.chargeType as ChargeType]
                  : 'N/A'}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
}
