import { UploadOutlined } from '@ant-design/icons';
import { message } from 'antd';
import type { UploadFile } from 'antd/es/upload/interface';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import { FieldArray, FieldArrayRenderProps, useField } from 'formik';
import { useEffect, useMemo, useState } from 'react';

type FileTypes =
  | '.png'
  | '.jpg'
  | '.gif'
  | '.pdf'
  | '.doc'
  | '.docx'
  | '.txt'
  | '.ppt'
  | '.pptx'
  | '.xls'
  | '.xlsx'
  | '.csv'
  | '.odt'
  | '.ods'
  | '.webp';

interface MultiFileUploaderProps {
  //  maxCount is the maximum number of files which can be accepted
  // maxSize is the maximum size of any one file, in bytes
  maxSize?: number;
  maxCount?: number;
  name: string;
  label?: string;
  initialFiles?: Array<UploadFile>;
  disabled?: boolean;
  required?: boolean;
  onFilesChanged?: (files: Array<RcFile>) => void;
  singleFileUpload?: boolean;
  progress?: {
    [fileName: string]: {
      percent: number;
      status?: 'done' | 'success' | 'uploading' | 'error';
    };
  }; //Allows progress for files to be overridden.
  fileTypes?: Array<FileTypes>;
}

export default function MultiFileUploader(props: MultiFileUploaderProps) {
  const [field] = useField<Array<string>>(props.name);
  const [files, setFiles] = useState<Array<RcFile>>([]);
  //Existing files must be kept seperate to ensure they are not reuploaded.
  const [uploadedFiles, setUploadedFiles] = useState<Array<UploadFile>>(props.initialFiles ?? []);
  useEffect(() => {
    props.onFilesChanged && props.onFilesChanged(files);
  }, [files]);

  const handleAdded = (file: RcFile, helper: FieldArrayRenderProps) => {
    const allFiles = uploadedFiles ? uploadedFiles.concat(files) : files;
    //File must be below max file size
    if (file.size && props.maxSize ? file.size > props.maxSize : null) {
      message.error('File is over size limit');
    }
    //All files in list must have unique names.
    else if (!allFiles.some((f) => f.name === file.name)) {
      helper.push(file.name);
      setFiles((files) => [...files, file]);
    } else {
      message.error('All uploaded files must have unique file names, please rename the file and try again.');
    }

    return false;
  };

  const handleRemoved = async (removed: UploadFile, helper: FieldArrayRenderProps) => {
    //If the removed file exists within the newly added filed
    const index = field.value.findIndex((f) => f === removed.name);
    if (index >= 0) {
      //Remove it from formik.
      helper.remove(index);
      //Remove it from uploaded files and or files.
      setFiles((files) => [...files].filter((f) => f.name != removed.name));
      setUploadedFiles((files) => [...files].filter((f) => f.name != removed.name));
    }
  };

  const fileTypes = useMemo(() => {
    return props.fileTypes
      ? props.fileTypes.join(', ')
      : '.png, .jpg, .gif, .pdf, .doc, .docx, .txt, .ppt, .pptx, .xls, .xlsx, .csv, .odt, .ods';
  }, [props.fileTypes]);

  return (
    <FieldArray {...props}>
      {(arrayHelpers) => {
        return (
          <div className="flex flex-col">
            {props.label && (
              <label className="font-bold whitespace-nowrap">
                {props.label} {props.required && '*'}
              </label>
            )}
            <Dragger
              accept={fileTypes}
              maxCount={props.maxCount}
              multiple={!props.singleFileUpload}
              disabled={props.disabled || (props.singleFileUpload && files.length > 0)}
              beforeUpload={(f) => handleAdded(f, arrayHelpers)}
              onRemove={async (f) => {
                await handleRemoved(f, arrayHelpers);
              }}
              listType="text"
              className="ant-upload-parent"
              fileList={uploadedFiles.concat(
                files.map((file) => {
                  return {
                    ...file,
                    name: file.name,
                    percent:
                      props.progress && props.progress[file.name] ? props.progress[file.name].percent : undefined,
                    status: props.progress && props.progress[file.name] ? props.progress[file.name].status : undefined,
                  };
                }),
              )}
            >
              <p>
                <UploadOutlined />
              </p>
              <p className="ant-upload-text text-xs">Upload attachments</p>
              <p className="ant-upload-hint text-xs">Click here or drag and drop</p>
            </Dragger>
          </div>
        );
      }}
    </FieldArray>
  );
}
