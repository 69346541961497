import { GetAllRfqsDocument, GetAllRfqsQuery, Rfq } from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addRfqToCache = (rfq: Rfq): void => {
  const query = apolloClient.cache.readQuery<GetAllRfqsQuery>({
    query: GetAllRfqsDocument,
  });
  const newRfqs = query?.rfqs?.nodes?.concat({ ...rfq });
  apolloClient.cache.updateQuery<GetAllRfqsQuery>({ query: GetAllRfqsDocument }, () => ({
    rfqs: { nodes: newRfqs },
  }));
};

export const editRfqInCache = (newRfq: Rfq): void => {
  const query = apolloClient.cache.readQuery<GetAllRfqsQuery>({
    query: GetAllRfqsDocument,
  });
  const newRfqs = query?.rfqs?.nodes?.map((rfq) => {
    if (newRfq.rFQNumber === rfq.rFQNumber) {
      return newRfq;
    }
    return rfq;
  });
  apolloClient.cache.updateQuery<GetAllRfqsQuery>({ query: GetAllRfqsDocument }, () => ({
    rfqs: { nodes: newRfqs },
  }));
};

export const removeRfqFromCache = (deletedRfqNumber: string): void => {
  const query = apolloClient.cache.readQuery<GetAllRfqsQuery>({
    query: GetAllRfqsDocument,
  });
  const updatedRfqs = query?.rfqs?.nodes?.filter((rfq) => {
    if (deletedRfqNumber == rfq.rFQNumber) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetAllRfqsQuery>({ query: GetAllRfqsDocument }, () => ({
    rfqs: { nodes: updatedRfqs },
  }));
};
