import { Breadcrumb } from 'antd';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import useOverrideBreadcrumbStore from '../../hooks/UseOverrideBreadcrumbStore';
import { authenticatedRoutes } from '../../routing/Routes';
import './Breadcrumbs.less';

export default function Breadcrumbs() {
  const breadcrumbs = useBreadcrumbs(Object.values(authenticatedRoutes));
  const crumbsForPath = useOverrideBreadcrumbStore((store) => store.crumbsForPath);
  const filteredBreadcrumbs = useMemo(() => {
    return breadcrumbs.map((bc) => {
      let supplementedBc = bc;
      Object.entries(crumbsForPath).forEach(([key, value]) => {
        if (bc.match.params[key]) {
          supplementedBc = { ...bc, breadcrumb: value };
        }
      });

      return supplementedBc;
    });
  }, [breadcrumbs, crumbsForPath]);

  return (
    <Breadcrumb style={{ margin: '16px 0' }}>
      {filteredBreadcrumbs.map((bc) => {
        return (
          <Breadcrumb.Item key={bc.key}>
            <Link to={bc.match.pathname} className="breadcrumb-link">
              {bc.breadcrumb}
            </Link>
          </Breadcrumb.Item>
        );
      })}
    </Breadcrumb>
  );
}
