import { CopyOutlined } from '@ant-design/icons';
import CreateRequestSubmissionPage from '../../request-submission/pages/CreateRequestSubmissionPage';
import ViewRequestSubmissionPage from '../../request-submission/pages/ViewRequestSubmissionPage';
import CreateRequestPage from '../../requests/pages/CreateRequestPage';
import SellerRequestPage from '../../requests/pages/SellerRequestPage';
import RequestPage from '../../requests/pages/RequestPage';
import ViewRequestPage from '../../requests/pages/ViewRequestPage';
import { CurrentUserRole } from '../models/user/UserRole';
import { localRoutingConstants } from './RoutingConstants';

//TODO: There is no need for routes to be different between sellers and admin for the same resource.
export const requestRoutes = {
  requests: {
    label: 'Requests',
    path: localRoutingConstants.requests.root,
    icon: <CopyOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <RequestPage />,
  },
  createRequest: {
    label: 'Create Request',
    path: localRoutingConstants.requests.create,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <CreateRequestPage />,
  },
  viewRequestSubmission: {
    label: 'View Request Submission',
    path: localRoutingConstants.requests.submissions.view,
    icon: <CopyOutlined />,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <ViewRequestSubmissionPage />,
  },
  viewRequests: {
    label: 'View Request',
    path: localRoutingConstants.requests.view,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <ViewRequestPage />,
  },
  viewSellerRequestSubmission: {
    label: 'View Request Submission',
    path: localRoutingConstants.sellerRequests.submissions.view,
    icon: <CopyOutlined />,
    allowedRoles: [CurrentUserRole.SellerAdmin],
    element: <ViewRequestSubmissionPage />,
  },
  sellerRequests: {
    label: 'Requests',
    path: localRoutingConstants.sellerRequests.root,
    icon: <CopyOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.SellerAdmin],
    element: <SellerRequestPage />,
  },
  createRequestSubmission: {
    label: 'Request Submissions',
    path: localRoutingConstants.sellerRequests.create,
    icon: <CopyOutlined />,
    allowedRoles: [CurrentUserRole.SellerAdmin],
    element: <CreateRequestSubmissionPage />,
  },
  viewSellerRequests: {
    label: 'View Request',
    path: localRoutingConstants.sellerRequests.view,
    allowedRoles: [CurrentUserRole.GSAdmin, CurrentUserRole.SellerAdmin],
    element: <ViewRequestPage />,
  },
};
