import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetSellersByIdQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import CreateOrEditSellerForm from '../components/CreateOrEditSellerForm';

export default function EditSellerPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, loading } = useGetSellersByIdQuery({
    variables: {
      id: id as string,
    },
    onError: () => {
      navigate(localRoutingConstants.sellers.root);
      message.error('An error occurred, you may only edit users who already have an account.');
    },
  });
  if (loading) {
    return <SpinLoader />;
  }
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2>Edit Seller</h2>
        <div className="py-2">Edit Seller details using the form below.</div>
        {data?.sellersById && (
          <>
            <CreateOrEditSellerForm editSeller={data?.sellersById} className="max-w-3xl" />
          </>
        )}
      </div>
    </>
  );
}
