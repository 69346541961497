import { Alert, Button, message } from 'antd';
import { Formik, Form } from 'formik';
import {
  RequestSubmission,
  SubmissionStatus,
  UpdateRequestSubmissionInput,
  useGetRequestSubmissionsByIdQuery,
  useUpdateRequestSubmissionMutation,
} from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import SelectInput from '../../shared/components/form/SelectInput';
import {
  RequestSubmissionStatusComboBoxItems,
  SubmissionStatusToDisplayText,
} from '../../shared/models/request-submissions/RequestSubmissionStatus';
import { editRequestSubmissionValidator } from '../validators/RequestSubmissionValidator';
import DateTimeFormInput from '../../shared/components/form/DateTimeFormInput';
import TextFormInput from '../../shared/components/form/TextFormInput';

type EditRequestSubmissionFormProps = {
  requestSubmissionId?: string | null;
  handleSubmit: () => void;
  handleCancel: () => void;
};

export default function EditRequestSubmissionForm({
  requestSubmissionId,
  handleSubmit,
  handleCancel,
}: EditRequestSubmissionFormProps) {
  // const { requestSubmissionId } = useParams();
  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRequestSubmissionsByIdQuery({
    variables: {
      requestSubmissionId: requestSubmissionId as string,
    },
    fetchPolicy: 'no-cache',
  });
  const [updateRequestSubmission] = useUpdateRequestSubmissionMutation();
  const handleUpdateRequestSubmission = async (
    submmission: UpdateRequestSubmissionInput,
  ): Promise<RequestSubmission> => {
    const response = await updateRequestSubmission({
      variables: {
        input: submmission,
      },
    });
    return response.data?.updateRequestSubmission as RequestSubmission;
  };
  const requestSubmission = data?.requestSubmissionById as RequestSubmission;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching request submissions, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <Formik
      initialValues={{
        status: requestSubmission.status,
        id: requestSubmission.requestSubmissionId,
        interviewTime: requestSubmission.interviewTime,
        interviewDetails: requestSubmission.interviewDetails,
      }}
      validationSchema={editRequestSubmissionValidator}
      onSubmit={async (values) => {
        try {
          const validSubmission = editRequestSubmissionValidator.cast(values, { stripUnknown: true });
          await handleUpdateRequestSubmission(validSubmission as unknown as UpdateRequestSubmissionInput);
          message.success(
            'Successfully updated request submission to status: ' +
              SubmissionStatusToDisplayText[values.status as SubmissionStatus],
          );
          handleSubmit();
        } catch (ex) {
          message.error('An error ocurred whilst editing the request submission');
        }
      }}
    >
      {({ submitForm, values }) => {
        return (
          <Form>
            <SelectInput
              name="status"
              placeholder="Status"
              label="Status"
              availableItems={RequestSubmissionStatusComboBoxItems}
            />
            <DateTimeFormInput
              name="interviewTime"
              placeholder="Interview Time"
              label="Interview Time"
              disabled={values.status != SubmissionStatus.Interview}
              showTime={true}
            />
            <TextFormInput
              name="interviewDetails"
              placeholder="Additional Interview Time Notes"
              label="Additional Interview Time Notes"
              disabled={values.status != SubmissionStatus.Interview}
            />

            <div className="flex flex-row justify-end gap-2">
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button loading={loading} type="primary" onClick={submitForm}>
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
