import { Modal } from 'antd';
import CreateBuyerForm from '../../buyer/components/CreateBuyerForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function RFQCreateBuyerModal(props: Props) {
  return (
    <Modal title="Add New Buyer" open={props.isOpen} onCancel={() => props.onClose()} footer={null}>
      <CreateBuyerForm isModal={true} handleClose={props.onClose} />
    </Modal>
  );
}
