import { Residencies } from '../../../../graphql/generated';

export const ResidenciesToDisplayText: { [key in Residencies]: string } = {
  [Residencies.Citizen]: 'Citizen',
  [Residencies.Pr]: 'PR',
  [Residencies.Visa]: 'Visa',
  [Residencies.Na]: 'N/A',
};

function getOrderedResidencies(): Residencies[] {
  const residencies: Residencies[] = [];

  //add all the residencies that aren't NA
  Object.values(Residencies).forEach((residency) => {
    if (residency != Residencies.Na) residencies.push(residency);
  });

  //ensure that NA is the final residency
  residencies.push(Residencies.Na);
  return residencies;
}

//store in const so we only work this out once
const orderedResidencies = getOrderedResidencies();

export const ResidenciesToComboBoxItems = orderedResidencies.map((val) => ({
  value: val,
  displayValue: ResidenciesToDisplayText[val],
}));
