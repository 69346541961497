import { ApolloProvider } from '@apollo/client';
import { ThemeProvider } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Auth } from 'aws-amplify';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './features/auth/authenticator/Authenticator.less';
import { authenticatorTheme } from './features/auth/authenticator/AuthenticatorTheme';
import GSAuthenticator from './features/auth/authenticator/GSAuthenticator';
import useChatStore from './features/chat/hooks/UseChatStore';
import Layout from './features/shared/components/layout/Layout';
import { useCurrentUserStore } from './features/shared/hooks/UseCurrentUserStore';
import { CurrentUserRole } from './features/shared/models/user/UserRole';
import { authenticatedRoutes } from './features/shared/routing/Routes';
import apolloClient from './features/shared/utils/ApolloClient';
import { GetCurrentUserDocument, GetCurrentUserQueryResult } from './graphql/generated';

function App() {
  const listenForNotifications = useChatStore((x) => x.listenForNotifications);
  const setUser = useCurrentUserStore((store) => store.setUser);
  const updateUser = async (user: any | undefined) => {
    const response = (await apolloClient.query({ query: GetCurrentUserDocument })) as GetCurrentUserQueryResult;
    setUser(
      user?.attributes && {
        id: user.attributes.sub,
        email: user.attributes.email,
        role: user.attributes['custom:user_role'] as CurrentUserRole,
        sellerName: response.data?.currentUser.seller?.name,
        sellerId: user.attributes['custom:resource_partner_id'],
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
      },
    );

    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    listenForNotifications(
      user.attributes['custom:user_role'] === CurrentUserRole.GSAdmin
        ? 'GSAdmin'
        : user.attributes['custom:resource_partner_id'],
      token,
    );
  };

  return (
    <ThemeProvider theme={authenticatorTheme}>
      <GSAuthenticator loginMechanisms={['email']} hideSignUp={true}>
        {({ user }) => {
          updateUser(user);
          return (
            <ApolloProvider client={apolloClient}>
              <BrowserRouter>
                <Routes>
                  <Route element={<Layout />}>
                    {Object.values(authenticatedRoutes)
                      .filter((route) =>
                        route.allowedRoles.some(
                          (x) => x === (user?.attributes && (user?.attributes['custom:user_role'] as CurrentUserRole)),
                        ),
                      )
                      .map((route) => {
                        return <Route key={route.path} path={route.path} element={route.element} />;
                      })}
                    {/* Fallback routes */}
                    {user?.attributes && user?.attributes['custom:user_role'] == CurrentUserRole.GSAdmin ? (
                      <Route path="*" element={<Navigate to="/request" replace />} />
                    ) : (
                      <Route path="*" element={<Navigate to="/seller-request" replace />} />
                    )}
                  </Route>
                </Routes>
              </BrowserRouter>
            </ApolloProvider>
          );
        }}
      </GSAuthenticator>
    </ThemeProvider>
  );
}

export default App;
