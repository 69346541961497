import { Badge } from 'antd';
import { SubmissionStatusToDisplayText } from '../../models/request-submissions/RequestSubmissionStatus';
import { SubmissionStatus } from '../../../../graphql/generated';

type Props = {
  status: string;
};

export default function SubmissionStatusRenderer(props: Props) {
  if (props.status == SubmissionStatus.Interview || props.status == SubmissionStatus.Successful) {
    return <Badge status="success" text={SubmissionStatusToDisplayText[props.status]} />;
  } else if (props.status == SubmissionStatus.Unsuccessful || props.status == SubmissionStatus.NotSubmitted) {
    return <Badge status="error" text={SubmissionStatusToDisplayText[props.status]} />;
  } else if (props.status == SubmissionStatus.Wip || props.status == SubmissionStatus.BuyerPending) {
    return <Badge status="processing" text={SubmissionStatusToDisplayText[props.status]} />;
  } else if (props.status == SubmissionStatus.Shortlisted || props.status == SubmissionStatus.Withdrawn) {
    return <Badge status="warning" text={SubmissionStatusToDisplayText[props.status]} />;
  }

  return null;
}
