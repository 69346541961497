import { RequestStatus, SubmissionStatus } from '../../../../graphql/generated';
import { SubmissionStatusToDisplayText } from '../request-submissions/RequestSubmissionStatus';

export const RequestStatusToDisplayText: { [key in RequestStatus]: string } = {
  [RequestStatus.Wip]: SubmissionStatusToDisplayText[SubmissionStatus.Wip],
  [RequestStatus.NotSubmitted]: SubmissionStatusToDisplayText[SubmissionStatus.NotSubmitted],
  [RequestStatus.Shortlisted]: SubmissionStatusToDisplayText[SubmissionStatus.Shortlisted],
  [RequestStatus.BuyerPending]: SubmissionStatusToDisplayText[SubmissionStatus.BuyerPending],
  [RequestStatus.Interview]: SubmissionStatusToDisplayText[SubmissionStatus.Interview],
  [RequestStatus.Successful]: SubmissionStatusToDisplayText[SubmissionStatus.Successful],
  [RequestStatus.Unsuccessful]: SubmissionStatusToDisplayText[SubmissionStatus.Unsuccessful],
  [RequestStatus.Withdrawn]: SubmissionStatusToDisplayText[SubmissionStatus.Withdrawn],
  [RequestStatus.OptOut]: 'Opt Out',
  [RequestStatus.Closed]: 'Closed',
  [RequestStatus.Open]: 'Open',
};

export function RequestStatusCompare(a: RequestStatus, b: RequestStatus): number {
  return RequestStatusToDisplayText[a].localeCompare(RequestStatusToDisplayText[b]);
}
