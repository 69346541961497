import { message } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Rfq, useGetRfqByNumberQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import RfqForm from '../components/RfqForm';

export default function EditRfqPage() {
  const { rfqNumber } = useParams<{ rfqNumber: string }>();
  const navigate = useNavigate();
  const { data, loading } = useGetRfqByNumberQuery({
    variables: {
      rfqNumber: rfqNumber as string,
    },
    fetchPolicy: 'no-cache',
    onError: () => {
      navigate(localRoutingConstants.rfqs.root);
      message.error('An error occurred, you may only edit existing RFQs.');
    },
  });

  if (loading) {
    return <SpinLoader />;
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <RfqForm editRfq={data?.rfqByNumber as Rfq} className="max-w-3xl" />
      </div>
    </>
  );
}
