import { ChargeType } from '../../../../graphql/generated';

export const ChargeTypeOptionsToDisplayText: { [key in ChargeType]: string } = {
  [ChargeType.Annually]: 'Annually',
  [ChargeType.Daily]: 'Daily',
  [ChargeType.Hourly]: 'Hourly',
  [ChargeType.Total]: 'Total',
};

export const ChargeTypeOptionsToAbbreviatedText: { [key in ChargeType]: string } = {
  [ChargeType.Annually]: ' / Year',
  [ChargeType.Daily]: ' / Day',
  [ChargeType.Hourly]: ' / Hr',
  [ChargeType.Total]: ' Total',
};

export const ChargeTypeComboboxItems = Object.values(ChargeType).map((val) => ({
  value: val,
  displayValue: ChargeTypeOptionsToDisplayText[val],
}));
