import { ApartmentOutlined } from '@ant-design/icons';
import CreateSellerPage from '../../sellers/pages/CreateSellerPage';
import EditSellerPage from '../../sellers/pages/EditSellerPage';
import SellerDetails from '../../sellers/pages/SellerDetails';
import SellersPage from '../../sellers/pages/SellersPage';
import { CurrentUserRole } from '../models/user/UserRole';
import { localRoutingConstants } from './RoutingConstants';

export const sellerRoutes = {
  sellers: {
    label: 'Sellers',
    path: localRoutingConstants.sellers.root,
    icon: <ApartmentOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <SellersPage />,
  },
  createSeller: {
    label: 'Create Seller',
    path: localRoutingConstants.sellers.create,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <CreateSellerPage />,
  },
  detailsSeller: {
    label: 'View Seller',
    path: localRoutingConstants.sellers.view,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <SellerDetails />,
  },
  editSeller: {
    label: 'Edit Seller',
    path: localRoutingConstants.sellers.edit,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <EditSellerPage />,
  },
};
