import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Buyer, useGetAllBuyersQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import BuyersTable from '../components/BuyersTable';

export default function BuyersPage() {
  const { data, loading, error } = useGetAllBuyersQuery();

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Buyers could not be loaded due to an unknown error, please try again in 5 minutes or contact support."
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Buyers Page</h2>
      </div>
      <div className="flex flex-row justify-between mb-4">
        <h3>Active Buyers</h3>
        <Link to="create">
          <Button icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <BuyersTable buyers={(data?.buyers?.nodes as unknown as Array<Buyer>) ?? []} />
    </div>
  );
}
