import { UserRole } from '../../../../graphql/generated';

export enum CurrentUserRole {
  GSAdmin = 'GSAdmin',
  SellerAdmin = 'SellerAdmin',
}

export const UserRoleToDisplayText: { [key in UserRole]: string } = {
  [UserRole.SellerAdmin]: 'Seller Admin',
  [UserRole.GsAdmin]: 'GS Admin',
};

export const UserRoleComboboxItems = Object.values(UserRole).map((val) => ({
  value: val,
  displayValue: UserRoleToDisplayText[val],
}));
