import * as Yup from 'yup';
import { UserRoleOptions } from '../../shared/models/user/UserRoleOptions';

export const createUserValidator = Yup.object({
  email: Yup.string().email().required('Email is a required field.').default(''),
  firstName: Yup.string().required('First name is a required field.').default(''),
  lastName: Yup.string().required('Last name is a required field.').default(''),
  role: Yup.string().required().oneOf(Object.values(UserRoleOptions)).default(''),
  isSellerAdmin: Yup.boolean()
    .when('role', {
      is: (role: string) => {
        return role !== UserRoleOptions.GSAdmin;
      },
      then: (schema) => {
        return schema.required();
      },
      otherwise: (schema) => {
        return schema.nullable();
      },
    })
    .default(true),
  sellerId: Yup.string()
    .when('role', {
      is: (role: string) => {
        return role !== UserRoleOptions.GSAdmin;
      },
      then: (schema) => {
        return schema.required('Seller is required for non admin users.');
      },
      otherwise: (schema) => {
        return schema.nullable();
      },
    })
    .default(''),
});

export const editUserValidator = createUserValidator.omit(['sellerId', 'role']);

Yup.object({
  email: Yup.string().email().required('Email is a required field.').default(''),
  firstName: Yup.string().required('First name is a required field.').default(''),
  lastName: Yup.string().required('Last name is a required field.').default(''),
  role: Yup.string().required().oneOf(Object.values(UserRoleOptions)).default(''),
  isSellerAdmin: Yup.boolean()
    .when('role', {
      is: (role: string) => {
        return role !== UserRoleOptions.GSAdmin;
      },
      then: (schema) => {
        return schema.required();
      },
      otherwise: (schema) => {
        return schema.nullable();
      },
    })
    .default(true),
  sellerId: Yup.string().required('Seller ID is a required field').default(''),
});
