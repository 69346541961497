import { Channel } from '@chatkitty/core';
import { Alert, message } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { Seller, useEnsureSellerChatIsInitialisedMutation, useGetSellersQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import useLayoutStore from '../../shared/hooks/UseLayoutStore';
import AdminChannelList from '../components/channel/AdminChannelList';
import Messenger from '../components/messages/Messenger';
import AdminChannelListProvider from '../context/AdminChannelListContext';
import { chatService } from '../context/ChatService';
import ChatSessionProvider from '../context/ChatSessionContext';
import useChatStore from '../hooks/UseChatStore';

export default function AdminChatPage() {
  const { setShowBreadcrumbs, setMarginWidth, setPaddingWidth, setDefaultLayout } = useLayoutStore();
  const { session, error, ackAllNotifications } = useChatStore();
  const [activeChannel, setActiveChannel] = useState<Channel>();
  const { data, loading, error: sellerError } = useGetSellersQuery();
  const [ensureInitialised] = useEnsureSellerChatIsInitialisedMutation();
  const messagesRef = useRef<HTMLDivElement>(null);
  const onCreateChat = async (seller: Seller) => {
    try {
      await ensureInitialised({ variables: { sellerId: seller.id } });
      const channel = await chatService.getChannelByName(seller.id);
      setActiveChannel(channel);
    } catch (ex) {
      message.error(
        'An error occurred whilst trying to create the new conversation, please wait two minutes and try again or contact support.',
      );
    }
  };

  useEffect(() => {
    ackAllNotifications();
    setShowBreadcrumbs(false);
    setMarginWidth(0);
    setPaddingWidth(0);
    return () => {
      setDefaultLayout();
    };
  }, []);

  if (error || sellerError) {
    return (
      <Alert
        className="m-3"
        showIcon
        type="error"
        message="An unknown error has occurred whilst attemtping to load chat, please wait two minutes and try again or contact support."
      />
    );
  }

  if (!session || loading) {
    return <SpinLoader className="h-screen" />;
  }

  return (
    <AdminChannelListProvider sellerIds={data?.sellers?.nodes?.map((x) => x.id) ?? []}>
      {({ updateChannelLastReceivedMessage }) => (
        <div className="flex flex-row h-screen -mt-16 pt-16 border-0 border-solid border-gray-100 border-l z-0">
          {/* Available chat options */}
          <div className="flex-shrink-0">
            <AdminChannelList
              onCreate={onCreateChat}
              onSelect={(ch) => setActiveChannel(ch)}
              sellers={data?.sellers?.nodes ?? []}
            />
          </div>

          {/* Active messages */}
          {activeChannel && (
            <ChatSessionProvider
              sellerName={data?.sellers?.nodes?.find((seller) => seller.id === activeChannel.name)?.name}
              channel={activeChannel}
              onMessagesLoaded={() => {
                messagesRef.current?.scrollTo({ top: messagesRef.current.scrollHeight, behavior: 'smooth' });
              }}
              onMessage={(msg) => {
                updateChannelLastReceivedMessage(activeChannel.id, {
                  body: msg.body,
                  senderName: msg.user.name,
                  time: new Date(),
                });
              }}
            >
              <Messenger
                recipientUserName={activeChannel.name}
                recipientDisplayName={
                  data?.sellers?.nodes?.find((seller) => seller.id === activeChannel.name)?.name ?? 'Unknown'
                }
                innerRef={messagesRef}
              />
            </ChatSessionProvider>
          )}
        </div>
      )}
    </AdminChannelListProvider>
  );
}
