import { Alert } from 'antd';
import { useParams } from 'react-router-dom';
import { Seller, useGetSellersByIdQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import useOverrideBreadcrumbStore from '../../shared/hooks/UseOverrideBreadcrumbStore';
import IndividualSellerForm from '../components/IndividualSellerForm';

export default function SellerDetails() {
  const setCrumbForPath = useOverrideBreadcrumbStore((x) => x.setCrumbForPath);
  const { id } = useParams();
  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetSellersByIdQuery({
    variables: {
      id: id as string,
    },
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      setCrumbForPath({
        requestId: res.sellersById?.name ?? id!,
      });
    },
  });
  const seller = data?.sellersById as Seller;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching RFQs, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <>
      <>
        <div className="flex flex-col items-center justify-center">
          <h2>Seller: {seller.name}</h2>
        </div>
        <IndividualSellerForm seller={seller} />
      </>
    </>
  );
}
