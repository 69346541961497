import axios from 'axios';

export const uploadFileToS3 = async (
  file: File,
  uploadUrl: string,
  key: string,
  onProgress?: (progressEvent: any) => void,
  onError?: () => void,
) => {
  try {
    const uploadFile = new File([file], key, {
      type: file.type,
      lastModified: file.lastModified,
    });

    await axios.put(uploadUrl, uploadFile, {
      onUploadProgress: (prog) => {
        onProgress && onProgress((prog.loaded / prog.total!) * 100);
      },
    });
  } catch (ex) {
    onError && onError();
  }
};
