import { DatePicker } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import { useField, useFormikContext } from 'formik';
import moment from 'moment';
import { ReactNode } from 'react';
import FormError from './FormError';

type Props = {
  disabled?: boolean;
  name: string;
  placeholder: string;
  label: string;
  appendedElement?: ReactNode;
  required?: boolean;
  showTime?: boolean;
};

const disabledDate: RangePickerProps['disabledDate'] = (current) => {
  return current < moment().startOf('day');
};

export default function DateTimeFormInput(props: Props) {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <div className="flex flex-col">
      <label className="font-bold whitespace-nowrap	">
        {props.label} {props.required && '*'}
      </label>
      <DatePicker
        allowClear
        disabled={props.disabled}
        defaultValue={field.value ? moment(field.value) : undefined}
        disabledDate={disabledDate}
        format={props.showTime ? 'DD/MM/YYYY, h:mm:ss a' : 'DD/MM/YYYY'}
        showTime={props.showTime}
        onChange={async (value) => {
          await setFieldValue(props.name, value?.toDate());
          await setFieldTouched(props.name, true);
        }}
      />
      {props.appendedElement}
      <FormError>{meta.error && meta.touched ? meta.error : ''}</FormError>
    </div>
  );
}
