import { useGetCitiesByCountryCodeQuery } from '../../../graphql/generated';
import { ComboBoxItem } from '../components/form/ComboBoxInput';
import { useMemo } from 'react';

export function useGetCitiesComboBoxItems(countryCode: string): [ComboBoxItem[], () => void] {
  const { data: cityData, refetch } = useGetCitiesByCountryCodeQuery({
    variables: {
      countryCode: countryCode,
    },
  });

  const comboBoxItems = useMemo(() => {
    return cityData?.citiesByCountryCode.map((c) => {
      return {
        value: c,
        displayValue: c,
      };
    }) as ComboBoxItem[];
  }, [cityData?.citiesByCountryCode]);

  return [comboBoxItems, refetch];
}
