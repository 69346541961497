import _ from 'lodash';
import { useState } from 'react';
import { UploadMetadata } from '../components/files/UploadMetadata';
import FileUploadError from '../exceptions/FileUploadError';
import { uploadFileToS3 } from '../utils/S3UploadUtils';

export type GSFiles = { [name: string]: GSFile };
export type AttachmentInfo = {
  fileName: string;
  url: string;
  objectKey: string;
};
export class GSFile extends File {
  uid: string;
  index?: number;

  constructor(file: File, index?: number) {
    super([file], file.name);
    this.index = index;
    this.uid = _.uniqueId();
  }
}

export default function useMultiFileUpload() {
  const [files, setFiles] = useState<GSFiles>({});
  const [uploadingInfo, setUploadingInfo] = useState<UploadMetadata>({ status: 'done', completion: {} });
  const handleFileProgress = (fp: any, file: AttachmentInfo) => {
    setUploadingInfo((prev) => ({
      status: 'uploading',
      completion: { ...prev.completion, [file.fileName]: { percent: fp, status: 'uploading' } },
    }));
  };
  const handleFileError = (file: AttachmentInfo) => {
    setUploadingInfo((prev) => ({
      status: 'error',
      completion: { ...prev.completion, [file.fileName]: { percent: 100, status: 'error' } },
    }));
  };

  const handleMultiFileUpload = async (attachments: Array<AttachmentInfo>) => {
    setUploadingInfo({ status: 'uploading', completion: {} });
    try {
      await Promise.all(
        attachments.map(async (file) => {
          await uploadFileToS3(
            files[file.fileName],
            file.url,
            file.objectKey,
            (fp) => handleFileProgress(fp, file),
            () => handleFileError(file),
          );
        }),
      );
      setUploadingInfo((prev) => ({ ...prev, status: 'uploading' }));
    } catch (ex) {
      console.error(ex);
      setUploadingInfo((prev) => ({ ...prev, status: 'error' }));
      throw new FileUploadError(
        'An error occurred whilst uploading files, the rfq was created but attachments may be missing.',
      );
    }
  };

  return {
    files,
    setFiles,
    uploadingInfo,
    handleMultiFileUpload,
  };
}
