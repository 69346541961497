export const localRoutingConstants = {
  dashboard: {
    root: '/dash',
  },
  users: {
    root: '/users',
    create: '/users/create',
    edit: '/users/:userId/edit',
  },
  rfqs: {
    root: '/rfqs',
    create: '/rfqs/create',
    view: '/rfqs/:rfqNumber',
    edit: '/rfqs/:rfqNumber/edit',
  },
  requests: {
    root: '/request',
    create: '/request/:rfqNumber/create',
    view: '/request/:requestId',
    submissions: {
      view: `/request/:requestId/request-submissions/:requestSubmissionId`,
      edit: `/request/:requestId/request-submissions/:requestSubmissionId/edit`,
    },
  },
  sellerRequests: {
    root: '/seller-request',
    create: '/seller-request/:requestId/create', //creating a submisison
    view: '/seller-request/:requestId',
    submissions: {
      view: `/seller-request/:requestId/request-submissions/:requestSubmissionId`,
    },
  },
  sellers: {
    root: '/sellers',
    create: '/sellers/create',
    view: '/sellers/:id',
    edit: '/sellers/:id/edit',
  },
  buyers: {
    root: '/buyers',
    create: '/buyers/create',
  },
  chat: {
    root: '/chat',
  },
  userGuide: {
    root: '/userGuide',
  },
  changelog: {
    root: '/changelog',
  },
  industries: {
    root: '/industries',
    create: '/industries/create',
  },
};
