import * as Yup from 'yup';
import { SecurityClearance, SubmissionStatus } from '../../../graphql/generated';
import { validatorConstants } from '../../shared/components/form/form-constants/ValidatorConstants';
import { ResourceAvailability } from '../../shared/models/rfq/ResourceAvailability';

export const createSubmissionValidator = Yup.object({
  submissions: Yup.array(
    Yup.object({
      requestId: Yup.string()
        .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
        .required('Request Id is required')
        .default(''),
      sellerId: Yup.string()
        .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
        .required('Seller is required')
        .default(''),
      name: Yup.string()
        .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
        .required('Name/Proposal is required')
        .default(''),
      location: Yup.array().of(Yup.string().required('Location is a required field').default('')),
      residency: Yup.string().required('Residency is required').nullable().default(''),
      security: Yup.string()
        .required('Security Clearance is a required field')
        .oneOf(Object.values(SecurityClearance))
        .default(''),
      availability: Yup.string()
        .required('Availability is a required field')
        .oneOf(Object.values(ResourceAvailability))
        .default(''),
      chargeRate: Yup.number()
        .positive('Charge Rate must be greater than 0')
        .typeError('Charge Rate must be a number')
        .required('Charge Rate is a required field.')
        .max(999999)
        .default(1),
      attachments: Yup.array(Yup.string()).default([]),
    }),
  )
    .default([])
    .min(1, 'You need to provide at least one request before submitting'),
});

export const editRequestSubmissionValidator = Yup.object({
  status: Yup.string().oneOf(Object.values(SubmissionStatus)).required('Status is required').default(''),
  id: Yup.string().required('Status is required').default(''),
  interviewTime: Yup.date().default(new Date()).nullable(),
  interviewDetails: Yup.string().nullable().default(''),
});

export const SellerEditRequestSubmissionValidator = Yup.object({
  requestSubmissionId: Yup.string().required().default(''),
  name: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .required('Name/Proposal is required')
    .default(''),
  location: Yup.array().of(Yup.string().required('Location is a required field').default('')),
  residency: Yup.string().required('Residency is required').nullable().default(''),
  security: Yup.string()
    .required('Security Clearance is a required field')
    .oneOf(Object.values(SecurityClearance))
    .default(''),
  availability: Yup.string()
    .required('Availability is a required field')
    .oneOf(Object.values(ResourceAvailability))
    .default(''),
  chargeRate: Yup.number()
    .positive('Charge Rate must be greater than 0')
    .typeError('Charge Rate must be a number')
    .required('Charge Rate is a required field.')
    .max(999999)
    .default(1),
  attachments: Yup.array(Yup.string()).default([]),
});
