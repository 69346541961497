import { Fragment } from 'react';
import useChatStore from '../../hooks/UseChatStore';
import styles from './ChatNavigationTab.module.less';
import { ExclamationCircleFilled } from '@ant-design/icons';

export default function ChatNavigationTab() {
  const label = 'Chat';
  const { notifications } = useChatStore();

  return (
    <Fragment>
      {label}
      <div className={styles.notification}>{notifications.length > 0 && <ExclamationCircleFilled size={100} />}</div>
    </Fragment>
  );
}
