import { Alert } from 'antd';
import {
  RequestSubmission,
  Request,
  useGetRequestSubmissionsBySellerAndRequestQuery,
} from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import RequestSubmissionTable from '../components/RequestSubmissionsTable';

type Props = {
  request: Request;
};

export default function AdminRequestSubmissionsPage({ request }: Props) {
  const { data, loading, error, refetch } = useGetRequestSubmissionsBySellerAndRequestQuery({
    variables: {
      sellerId: request.seller?.id || '',
      requestId: request.requestId,
    },
    fetchPolicy: 'no-cache',
  });
  const requestSubmissions = data?.requestSubmissionsBySellerAndRequest?.nodes?.filter(
    (val) => val.request?.requestId == request.requestId,
  );

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching Request Submissions, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <div>
      <RequestSubmissionTable
        requestSubmissions={(requestSubmissions as unknown as Array<RequestSubmission>) ?? []}
        request={request}
        handleRefetch={refetch}
      />
    </div>
  );
}
