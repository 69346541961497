import { Avatar } from 'antd';
import { useCurrentUserStore } from '../../../shared/hooks/UseCurrentUserStore';
import { LastMessageSummary } from '../../models/LastMessageSummary';
import { getInitialsFromName, isSentByUser } from '../../utils/ChatUtils';
import styles from './ChannelPreview.module.less';

interface ChannelPreviewProps {
  channelName: string;
  lastMessageSent?: LastMessageSummary;
}

export default function ChannelPreview(props: ChannelPreviewProps) {
  const { user } = useCurrentUserStore();

  return (
    <div className={styles.wrapper}>
      {/* Avatar */}
      <Avatar className="font-bold mx-3 hidden md:block flex-shrink-0" size="large">
        <span className="text-lg">{getInitialsFromName(props.channelName)}</span>
      </Avatar>

      {/* Preview col */}
      <div className="flex flex-col w-56">
        <div className="font-bold">{props.channelName}</div>
        <div>
          {props.lastMessageSent?.body ? (
            <div className="truncate">
              <span className="font-bold">
                {isSentByUser(user?.role, props.lastMessageSent.senderName) ? `You: ` : 'Them: '}
              </span>
              <span>{props.lastMessageSent.body}</span>
            </div>
          ) : (
            <div>Be the first to send a message !</div>
          )}
        </div>
      </div>
    </div>
  );
}
