import { Formik, Form } from 'formik';
import { CreateIndustryInput, Industry, useCreateIndustryMutation } from '../../../graphql/generated';
import { Link, useNavigate } from 'react-router-dom';
import { getApolloErrorType, ServerErrorCode } from '../../shared/utils/ApolloErrorUtils';
import { Button, message } from 'antd';
import TextFormInput from '../../shared/components/form/TextFormInput';
import { createIndustryValidator } from '../validators/IndustryValidator';
import { addIndustryToCache } from '../IndustryUtils';

type CreateIndustryFormProps = {
  className?: string;
  isModal?: boolean;
  handleClose?: () => void;
};

export default function CreateIndustryForm({ className, isModal, handleClose }: CreateIndustryFormProps) {
  const navigate = useNavigate();
  const [createIndustry, { loading: createLoading }] = useCreateIndustryMutation();

  const handleCreateIndustry = async (industry: CreateIndustryInput): Promise<Industry> => {
    const response = await createIndustry({
      variables: {
        industry: industry,
      },
    });

    return response.data?.createIndustry as Industry;
  };

  return (
    <Formik
      validationSchema={createIndustryValidator}
      initialValues={createIndustryValidator.cast({})}
      onSubmit={async (values) => {
        //If initial values are not set, then it's a create form.
        try {
          const industry = await handleCreateIndustry(values as CreateIndustryInput);
          addIndustryToCache(industry);
          handleClose && handleClose();
          if (!isModal) {
            navigate('./..');
          }
          message.success(`Industry was successfully created !`);
        } catch (ex) {
          const apolloError = getApolloErrorType(ex);
          if (apolloError) {
            //This is a server error of some kind
            if (apolloError === ServerErrorCode.RESOURCE_ALREADY_EXISTS) {
              message.error(`The request could not be fulfilled because Industry already exists.`);
            }
          } else {
            message.error(`An error occurred whilst creating the Industry.`);
          }
        }
      }}
    >
      {({ submitForm }) => {
        return (
          <Form className={`${className} w-full`}>
            <TextFormInput required name="name" placeholder="Name" label="Name" />
            <div className="flex flex-row justify-end gap-2">
              {!isModal && (
                <Link to={'./..'}>
                  <Button type="default">Cancel</Button>
                </Link>
              )}
              <Button loading={createLoading} type="primary" onClick={submitForm}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
