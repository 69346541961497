import { WorkingArrangement } from '../../../../graphql/generated';

export const WorkingArrangementsToDisplayText: { [key in WorkingArrangement]: string } = {
  [WorkingArrangement.Onsite]: 'Onsite',
  [WorkingArrangement.Remote]: 'Remote',
  [WorkingArrangement.Hybrid]: 'Hybrid',
};

export const WorkingArrangementsComboboxItems = Object.values(WorkingArrangement).map((val) => ({
  value: val,
  displayValue: WorkingArrangementsToDisplayText[val],
}));
