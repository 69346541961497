import * as Yup from 'yup';
import { validatorConstants } from '../../shared/components/form/form-constants/ValidatorConstants';

export const createIndustryValidator = Yup.object({
  name: Yup.string()
    .min(
      validatorConstants.textField.minLength,
      validatorConstants.textField.minLength > 1
        ? 'Name must be at least ' + validatorConstants.textField.minLength + ' characters in length'
        : 'Name must be at least ' + validatorConstants.textField.minLength + ' character in length',
    )
    .max(
      validatorConstants.textField.medium,
      validatorConstants.textField.medium > 1
        ? 'Name must be less than ' + validatorConstants.textField.medium + ' characters in length'
        : 'Name must be less than ' + validatorConstants.textField.medium + ' character in length',
    )
    .required()
    .default(''),
});
