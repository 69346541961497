import { Descriptions, Upload, UploadFile } from 'antd';
import moment from 'moment';
import { useEffect } from 'react';
import { ChargeType, Residencies, RequestSubmission, SubmissionStatus } from '../../../graphql/generated';
import SubmissionStatusRenderer from '../../shared/components/submission-status/SubmissionStatusRenderer';
import useOverrideBreadcrumbStore from '../../shared/hooks/UseOverrideBreadcrumbStore';
import { ResidenciesToDisplayText } from '../../shared/models/rfq/Residencies';
import { ResourceAvailability, ResourceAvailabilityToDisplayText } from '../../shared/models/rfq/ResourceAvailability';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { CurrencyFormatter } from '../../shared/utils/IntlUtils';

type SubmissionDetailedDescriptionProps = {
  requestSubmission: RequestSubmission;
};
export default function SubmissionDetailedDescription({ requestSubmission }: SubmissionDetailedDescriptionProps) {
  const setBreadcrumbs = useOverrideBreadcrumbStore((x) => x.setCrumbForPath);
  useEffect(() => {
    setBreadcrumbs({
      requestId: requestSubmission.request?.rfq?.title ?? requestSubmission.request!.requestId!,
      requestSubmissionId: requestSubmission.name ?? requestSubmission.requestSubmissionId,
    });
  });

  return (
    <>
      <Descriptions className="overflow-x-auto" title="Request Info" bordered>
        <Descriptions.Item span={2} label="Name/Proposal">
          {requestSubmission.name}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Status">
          <SubmissionStatusRenderer status={requestSubmission.status} />
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Location">
          {requestSubmission.location.join(', ')}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Residency">
          {ResidenciesToDisplayText[requestSubmission.residency as Residencies]}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Security">
          {requestSubmission.security}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Availaiblity">
          {ResourceAvailabilityToDisplayText[requestSubmission.availability as ResourceAvailability]}
        </Descriptions.Item>
        <Descriptions.Item
          span={2}
          label={`Charge ${
            requestSubmission.request?.rfq?.chargeType
              ? ChargeTypeOptionsToAbbreviatedText[requestSubmission.request?.rfq?.chargeType as ChargeType]
              : 'Rate'
          } (${requestSubmission.request?.rfq?.currencyCode})`}
        >
          {CurrencyFormatter.format(requestSubmission.chargeRate)}
        </Descriptions.Item>
        {requestSubmission.status == SubmissionStatus.Interview && requestSubmission.interviewTime && (
          <Descriptions.Item span={2} label="Interview Time">
            {moment(requestSubmission.interviewTime).format('MMMM Do YYYY, h:mm:ss a')}
          </Descriptions.Item>
        )}
        {requestSubmission.interviewDetails && (
          <Descriptions.Item span={2} label="Interview Additional Information">
            {requestSubmission.interviewDetails}
          </Descriptions.Item>
        )}
        <Descriptions.Item span={3} label="Attachments">
          <Upload
            disabled={true}
            fileList={
              requestSubmission.attachments?.map((att) => {
                return {
                  name: att?.fileName,
                  uid: att?.objectKey,
                  url: att?.url,
                };
              }) as Array<UploadFile>
            }
            listType="text"
          ></Upload>
        </Descriptions.Item>
      </Descriptions>
    </>
  );
}
