import { PaperClipOutlined } from '@ant-design/icons';
import CreateRfqPage from '../../rfq-management/pages/CreateRfqPage';
import EditRfqPage from '../../rfq-management/pages/EditRfqPage';
import RFQManagementPage from '../../rfq-management/pages/RFQManagementPage';
import ViewRfqPage from '../../rfq-management/pages/ViewRfqPage';
import { CurrentUserRole } from '../models/user/UserRole';
import { localRoutingConstants } from './RoutingConstants';
export const rfqRoutes = {
  rfqs: {
    label: 'RFQs',
    path: localRoutingConstants.rfqs.root,
    icon: <PaperClipOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <RFQManagementPage />,
  },
  createRfqs: {
    label: 'Create RFQ',
    path: localRoutingConstants.rfqs.create,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <CreateRfqPage />,
  },
  viewRfq: {
    label: 'View RFQ',
    path: localRoutingConstants.rfqs.view,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <ViewRfqPage />,
  },
  editRfq: {
    label: 'Edit User',
    path: localRoutingConstants.rfqs.edit,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <EditRfqPage />,
  },
};
