import { Alert, Button, message } from 'antd';
import { getData as countryData } from 'country-list';
import { Form, Formik } from 'formik';
import { Fragment, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  ActivityType,
  Attachment,
  Residencies,
  CreateRfqInput,
  OverrideRequestRfqOutput,
  Request,
  Rfq,
  UpdateRfqInput,
  useCreateRfqMutation,
  useDeleteRfqOverrideByRequestIdMutation,
  useGetAllBuyersQuery,
  useGetAllIndustriesQuery,
  useGetCitiesByCountryCodeQuery,
  useSetRfqOverrideByRequestIdMutation,
  useUpdateRfqMutation,
} from '../../../graphql/generated';
import ConfirmPopover from '../../shared/components/dialogues/ConfirmPopover';
import MultiFileUploader from '../../shared/components/files/MultiFileUploader';
import ComboBoxInput, { ComboBoxItem } from '../../shared/components/form/ComboBoxInput';
import DateTimeFormInput from '../../shared/components/form/DateTimeFormInput';
import NumberFormInput from '../../shared/components/form/NumberFormInput';
import RichTextInputField from '../../shared/components/form/RichTextInputField';
import SelectInput from '../../shared/components/form/SelectInput';
import TextFormInput from '../../shared/components/form/TextFormInput';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import FileUploadError from '../../shared/exceptions/FileUploadError';
import useMultiFileUpload, { AttachmentInfo, GSFile, GSFiles } from '../../shared/hooks/UseMultiFileUpload';
import { ActivityTypeToComboBoxItems } from '../../shared/models/rfq/ActivityType';
import { ResidenciesToComboBoxItems } from '../../shared/models/rfq/Residencies';
import { ChargeTypeComboboxItems } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceComboboxItems } from '../../shared/models/rfq/RfqSecurityClearance';
import { WorkingArrangementsComboboxItems } from '../../shared/models/rfq/RfqWorkingArrangements';
import { getApolloErrorType } from '../../shared/utils/ApolloErrorUtils';
import { addRfqToCache, editRfqInCache } from '../rfqUtils';
import { createRfqValidator, editRfqValidator } from '../validators/RfqValidator';
import RFQCreateBuyerModal from './RFQCreateBuyerModal';
import RFQCreateIndustryModal from './RFQCreateIndustryModal';
import { data as currencyData } from 'currency-codes';

type CreateRfqFormProps = {
  className?: string;
  editRfq?: Rfq;
  overrideRequest?: Request;
};

//TODO: Need to protect against oversized file uploads and unsupported file types.
export default function RfqForm({ className, editRfq, overrideRequest }: CreateRfqFormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const { loading: buyerLoading, data: buyerData, error: buyerError } = useGetAllBuyersQuery();
  const { loading: industryLoading, data: industryData, error: industryError } = useGetAllIndustriesQuery();
  const [createRfq] = useCreateRfqMutation();
  const [updateRfq] = useUpdateRfqMutation();
  const [overrideRequestRfq] = useSetRfqOverrideByRequestIdMutation();
  const [deleteOverrideRequestRfq] = useDeleteRfqOverrideByRequestIdMutation();
  const { setFiles, uploadingInfo: uploading, handleMultiFileUpload } = useMultiFileUpload();
  const [buyerModalOpen, setBuyerModalOpen] = useState<boolean>(false);
  const [industryModalOpen, setIndustryModalOpen] = useState<boolean>(false);
  const [confirmPopoverOpen, setConfirmPopoverOpen] = useState<boolean>(false);
  const countries: ComboBoxItem[] = countryData().map((country) => {
    return {
      value: country.code,
      displayValue: country.name,
    };
  });
  const currencies: ComboBoxItem[] = currencyData.map((currency) => {
    return {
      value: currency.code,
      displayValue: currency.code,
    };
  });

  const { data: cityData, refetch: refetchCitiesByCountryCode } = useGetCitiesByCountryCodeQuery({
    variables: {
      countryCode: 'AU',
    },
  });

  // Call this function with a new countryCode value to refetch the query
  const handleCitiesRefetch = (newCountryCode: string) => {
    refetchCitiesByCountryCode({
      countryCode: newCountryCode,
    });
  };

  function getCitiesComboBoxItems(): ComboBoxItem[] {
    return cityData?.citiesByCountryCode.map((c) => {
      return {
        value: c,
        displayValue: c,
      };
    }) as ComboBoxItem[];
  }

  const memoizedComboBoxItems = useMemo(getCitiesComboBoxItems, [cityData?.citiesByCountryCode]);

  const handleCreateRfq = async (rfq: CreateRfqInput): Promise<Rfq> => {
    const response = await createRfq({
      variables: {
        rfq: rfq,
      },
    });

    //If selected, add any files to s3.
    const attachments = response.data?.createRfq.attachments;
    attachments && (await handleMultiFileUpload(attachments as AttachmentInfo[]));

    //return response
    return response.data?.createRfq as Rfq;
  };

  const handleUpdateRfq = async (rfq: UpdateRfqInput): Promise<Rfq> => {
    const response = await updateRfq({
      variables: {
        input: rfq,
      },
    });

    const attachments = response.data?.updateRfq.attachments;
    if (attachments) {
      await handleMultiFileUpload(attachments as AttachmentInfo[]);
    }

    return response.data?.updateRfq as Rfq;
  };

  const handleOverrideRequestRfq = async (rfq: CreateRfqInput): Promise<OverrideRequestRfqOutput> => {
    const response = await overrideRequestRfq({
      variables: {
        requestId: overrideRequest?.requestId || '',
        rfqInput: rfq,
      },
    });

    const attachments = response.data?.setRfqOverrideByRequestId.attachments;
    if (attachments) {
      await handleMultiFileUpload(attachments as AttachmentInfo[]);
    }

    navigate('./..');
    return response as OverrideRequestRfqOutput;
  };

  const handleDeleteOverrideRequestRfq = async (inputRequestId: string): Promise<Request> => {
    const response = await deleteOverrideRequestRfq({
      variables: {
        requestId: inputRequestId,
      },
    });

    if (response.errors) {
      message.error('An error ocurred during removal of the RFQ override');
    } else {
      message.success('Successfully removed the RFQ override!');
    }

    navigate('./..');
    return response.data?.deleteRfqOverrideByRequestId as Request;
  };

  //Otherwise show form.
  return (
    <Formik
      validationSchema={editRfq ? editRfqValidator : createRfqValidator}
      initialValues={
        overrideRequest
          ? createRfqValidator.cast(
              {
                ...editRfq,
                attachments: overrideRequest.overrideAttachments?.map((x) => x?.fileName),
              } ?? {},
            )
          : createRfqValidator.cast(
              {
                ...editRfq,
                attachments: editRfq ? editRfq.attachments?.map((x) => x?.fileName) : [],
              } ?? {},
            )
      }
      onSubmit={async (values) => {
        setLoading(true);
        //If initial values are not set, then it's a create form.
        try {
          let rfq: Rfq;
          if (editRfq && !overrideRequest) {
            const validRfq = editRfqValidator.cast(values, { stripUnknown: true });
            rfq = await handleUpdateRfq(validRfq as UpdateRfqInput);
            editRfqInCache(rfq);
            message.success(`The RFQ with number ${rfq.rFQNumber} was successfully updated !`);
            navigate('./..');
          } else if (editRfq && overrideRequest) {
            const validRfq = editRfqValidator.cast(values, { stripUnknown: true });
            await handleOverrideRequestRfq(validRfq as CreateRfqInput);
            message.success(`Successfully overrode the values for request on ${overrideRequest.rfq?.rFQNumber}!`);
          } else {
            rfq = await handleCreateRfq(values as CreateRfqInput);
            addRfqToCache(rfq);
            message.success(`The RFQ with number ${rfq.rFQNumber} was successfully created !`);
            navigate(`./../${rfq.rFQNumber}`);
          }
        } catch (ex) {
          if (getApolloErrorType(ex)) {
            message.error(`The request could not be fulfilled because RFQ already exists.`);
          } else if (ex instanceof FileUploadError) {
            message.error(ex.message);
          } else {
            if (editRfq && !overrideRequest) {
              message.error(`An error occurred whilst editing the RFQ.`);
            } else if (editRfq && overrideRequest) {
              message.error(`An error occurred whilst overriding the request values.`);
            } else {
              message.error(`An error occurred whilst creating the RFQ.`);
            }
          }
        }
        setLoading(false);
      }}
    >
      {({ submitForm, values }) => {
        if (buyerLoading || industryLoading) {
          return <SpinLoader />;
        } else if (buyerError || industryError) {
          return (
            <Alert
              showIcon
              type="error"
              message="An unknown error has occurred, please wait two minutes and try again or contact support."
            />
          );
        }

        return (
          <Fragment>
            {editRfq ? (
              <h2>
                {overrideRequest ? 'Override Request RFQ: ' : 'Edit RFQ: '} {editRfq.rFQNumber} - {editRfq.title}
              </h2>
            ) : (
              <>
                <h2>New RFQ</h2>
                <div className="py-2">Filling out the form below will result in the creation of an RFQ.</div>
              </>
            )}
            {overrideRequest?.rfqOverride && (
              <ConfirmPopover
                open={confirmPopoverOpen}
                content={
                  <>
                    <div>
                      This cannot be undone, and will replace the overridden RFQ data with the original parent RFQ data!
                    </div>
                    <div className="mt-2">
                      <Button
                        type="primary"
                        danger
                        className="mr-2"
                        onClick={() => handleDeleteOverrideRequestRfq(overrideRequest.requestId)}
                      >
                        Confirm
                      </Button>
                      <Button onClick={() => setConfirmPopoverOpen(false)}>Cancel</Button>
                    </div>
                  </>
                }
              >
                <Button className="w-full h-auto text-lg" type="primary" onClick={() => setConfirmPopoverOpen(true)}>
                  Remove Override
                </Button>
              </ConfirmPopover>
            )}
            <RFQCreateBuyerModal isOpen={buyerModalOpen} onClose={() => setBuyerModalOpen(false)} />
            <RFQCreateIndustryModal isOpen={industryModalOpen} onClose={() => setIndustryModalOpen(false)} />
            <Form className={`${className} w-full`}>
              {!editRfq && (
                <>
                  <TextFormInput required name="rFQNumber" placeholder="RFQ Number" label="RFQ Number" />
                  <TextFormInput required name="title" placeholder="Title" label="Title" />
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-3">
                      <ComboBoxInput
                        name="industryId"
                        required
                        placeholder="Industry"
                        label="Industry"
                        availableItems={
                          industryData?.industries?.nodes?.map((x) => ({ displayValue: x.name, value: x.id })) ?? []
                        }
                      />
                    </div>
                    {/* This styling is due to the label field of the input being an odd size */}
                    <Button
                      type="primary"
                      className="col-span-1 h-[30px] mt-[23px]"
                      onClick={() => setIndustryModalOpen(true)}
                    >
                      Add New Industry
                    </Button>
                  </div>
                  <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-3">
                      <ComboBoxInput
                        name="buyerId"
                        required
                        placeholder="Buyer"
                        label="Buyer"
                        availableItems={
                          buyerData?.buyers?.nodes?.map((x) => ({ displayValue: x.name, value: x.id })) ?? []
                        }
                      />
                    </div>
                    {/* This styling is due to the label field of the input being an odd size */}
                    <Button
                      type="primary"
                      className="col-span-1 h-[30px] mt-[23px]"
                      onClick={() => setBuyerModalOpen(true)}
                    >
                      Add New Buyer
                    </Button>
                  </div>
                  <SelectInput
                    required
                    name="activityType"
                    placeholder="Activity Type"
                    label="Activity Type"
                    availableItems={ActivityTypeToComboBoxItems}
                  />
                  <ComboBoxInput
                    required
                    name="country"
                    placeholder="Country"
                    label="Country"
                    availableItems={countries}
                    onChange={(value) => handleCitiesRefetch(value)}
                  />
                  {cityData ? (
                    <SelectInput
                      required
                      name="location"
                      placeholder="Select City"
                      label="City"
                      selectMultiple
                      availableItems={memoizedComboBoxItems}
                    />
                  ) : (
                    <SpinLoader />
                  )}
                  <DateTimeFormInput
                    required
                    showTime={false}
                    name="rFQPublishedDate"
                    placeholder="Published Date"
                    label="RFQ Published Date"
                  />
                </>
              )}
              <DateTimeFormInput
                required
                showTime={true}
                name="requestClosureDate"
                placeholder="Request Closure Date"
                label="Request Closure Date"
              />
              <DateTimeFormInput
                required
                showTime={false}
                name="estimatedStartDate"
                placeholder="Est. Start Date"
                label="Estimated Start Date"
              />
              <DateTimeFormInput
                disabled={values.activityType == ActivityType.Services || values.activityType == ActivityType.Permanent}
                showTime={false}
                name="contractDuration"
                placeholder="Contract Duration"
                label="Contract Duration"
              />
              <>
                <TextFormInput
                  name="extensionTerms"
                  placeholder="Extension Terms"
                  label="Extension Terms"
                  disabled={
                    values.activityType == ActivityType.Services || values.activityType == ActivityType.Permanent
                  }
                />
                <SelectInput
                  required
                  name="residency"
                  placeholder="Residency"
                  label="Residency"
                  selectMultiple
                  availableItems={ResidenciesToComboBoxItems}
                  defaultValue={editRfq?.residency as Array<Residencies>}
                />
                <SelectInput
                  required
                  name="workingArrangement"
                  placeholder="Working Arrangement"
                  label="Working Arrangement"
                  availableItems={WorkingArrangementsComboboxItems}
                />
                <SelectInput
                  required
                  name="security"
                  placeholder="Security Clearance"
                  label="Security Clearance"
                  availableItems={SecurityClearanceComboboxItems}
                />
                <NumberFormInput
                  required
                  name="maxSubmissions"
                  placeholder="Max. Submissions"
                  label="Max. Number of Submissions"
                />
                <div className="grid grid-cols-4 gap-4">
                  <div className="col-span-3">
                    <NumberFormInput
                      currency
                      name="maxCharge"
                      placeholder="Max. Charge"
                      label="Max. Charge"
                      defaultValue={editRfq?.maxCharge ? editRfq.maxCharge : undefined}
                    />
                  </div>
                  <div className="col-span-1">
                    <ComboBoxInput
                      required
                      name="currencyCode"
                      placeholder="Currency"
                      label="Currency"
                      availableItems={currencies}
                    />
                  </div>
                </div>
                <SelectInput
                  required
                  name="chargeType"
                  placeholder="Charge Type"
                  label="Charge Type"
                  availableItems={ChargeTypeComboboxItems}
                />
                <RichTextInputField
                  label="Supporting Information"
                  name="supportingInformation"
                  initialValue={editRfq?.supportingInformation}
                />
              </>
              <MultiFileUploader
                name={'attachments'}
                label={
                  overrideRequest
                    ? 'Supporting Documents (These will be different from the parent RFQ)'
                    : 'Supporting Documents'
                }
                maxSize={20000000}
                maxCount={10}
                initialFiles={
                  overrideRequest
                    ? overrideRequest.overrideAttachments?.map((att) => {
                        const attach = att as Attachment;
                        return { name: attach.fileName, uid: attach.objectKey, status: 'done' };
                      })
                    : editRfq?.attachments?.map((att) => {
                        const attach = att as Attachment;
                        return { name: attach.fileName, uid: attach.objectKey, status: 'done' };
                      })
                }
                onFilesChanged={(files) => {
                  const fileObj = {} as GSFiles;
                  files.forEach((file) => {
                    fileObj[file.name] = new GSFile(file);
                  });
                  setFiles(fileObj);
                }}
                progress={uploading.completion}
              />
              <div className="flex flex-row justify-end gap-2">
                {!overrideRequest && (
                  <Link to="./..">
                    <Button className="mt-4" type="default">
                      Cancel
                    </Button>
                  </Link>
                )}
                <Button className="mt-4" loading={loading} type="primary" onClick={submitForm}>
                  Submit
                </Button>
              </div>
            </Form>
          </Fragment>
        );
      }}
    </Formik>
  );
}
