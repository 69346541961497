import { MessageOutlined, MinusOutlined } from '@ant-design/icons';
import { Channel } from '@chatkitty/core';
import { Button } from 'antd';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { useEnsureSellerChatIsInitialisedMutation } from '../../../../graphql/generated';
import { useCurrentUserStore } from '../../../shared/hooks/UseCurrentUserStore';
import { chatService } from '../../context/ChatService';
import ChatSessionProvider from '../../context/ChatSessionContext';
import useChatStore from '../../hooks/UseChatStore';
import Messenger from '../messages/Messenger';
import styles from './SellerFloatingChatMenu.module.less';
import { defaultStyle, transitionStyles } from './SellerFloatingChatMenuTransitions';

export default function SellerFloatingChatMenu() {
  const { user } = useCurrentUserStore();
  const ref = useRef<HTMLDivElement | null>(null);
  const [ensureInitialised, { loading, error }] = useEnsureSellerChatIsInitialisedMutation({
    variables: { sellerId: user?.sellerId },
  });
  const { session, notifications, ackAllNotifications } = useChatStore();
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [channel, setChannel] = useState<Channel>();

  const init = async () => {
    try {
      await ensureInitialised();
    } catch (ex) {}
  };
  useEffect(() => {
    init();
  }, []);

  const fetchChannel = async () => {
    const channel = await chatService.getChannelByName(user?.sellerId);
    setChannel(channel);
  };

  useEffect(() => {
    if (session && !loading) {
      fetchChannel();
    }
  }, [session, loading]);

  return (
    <Fragment>
      {channel && session && !error && !loading && (
        <div className={styles.floatingChatMenu}>
          <div className="mb-3">
            <Transition in={showMenu} timeout={300} unmountOnExit>
              {(state) => {
                return (
                  <div
                    className={styles.chatPopup}
                    style={{
                      ...defaultStyle,
                      ...transitionStyles[state],
                    }}
                  >
                    <ChatSessionProvider
                      sellerName={user?.sellerName}
                      channel={channel}
                      onMessagesLoaded={() => {
                        ref.current?.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
                      }}
                    >
                      <Messenger
                        innerRef={ref}
                        className="h-96"
                        recipientDisplayName={'Go Sourcing'}
                        recipientUserName="GSAdmin"
                        actionButtons={
                          <Button
                            type="primary"
                            className="w-14 ml-[66.66%] mb-6 absolute rounded-tr-md"
                            onClick={() => setShowMenu(false)}
                          >
                            <MinusOutlined />
                          </Button>
                        }
                      />
                    </ChatSessionProvider>
                  </div>
                );
              }}
            </Transition>
          </div>{' '}
          <Button
            onClick={() => {
              setShowMenu(!showMenu);
              ackAllNotifications();
            }}
            className="relative flex flex-row justify-center items-center w-12 h-12 hover:bg-primary-50 mt-3;"
            type="primary"
            shape="circle"
          >
            <MessageOutlined className="text-2xl mt-1" />
            {notifications.length > 0 && <div className={styles.notification}>!</div>}
          </Button>
        </div>
      )}
    </Fragment>
  );
}
