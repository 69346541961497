import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export enum ActivityType {
  LabourHire = 'LABOUR_HIRE',
  Permanent = 'PERMANENT',
  Services = 'SERVICES'
}

export type ActivityTypeOperationFilterInput = {
  eq?: InputMaybe<ActivityType>;
  in?: InputMaybe<Array<ActivityType>>;
  neq?: InputMaybe<ActivityType>;
  nin?: InputMaybe<Array<ActivityType>>;
};

/** A connection to a list of items. */
export type AllArchivedRequestsConnection = {
  __typename?: 'AllArchivedRequestsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AllArchivedRequestsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AllArchivedRequestsEdge = {
  __typename?: 'AllArchivedRequestsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Request;
};

/** A connection to a list of items. */
export type AllNonArchivedRequestsConnection = {
  __typename?: 'AllNonArchivedRequestsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AllNonArchivedRequestsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AllNonArchivedRequestsEdge = {
  __typename?: 'AllNonArchivedRequestsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Request;
};

/** A connection to a list of items. */
export type AllRequestsConnection = {
  __typename?: 'AllRequestsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<AllRequestsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type AllRequestsEdge = {
  __typename?: 'AllRequestsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Request;
};

export enum ApplyPolicy {
  AfterResolver = 'AFTER_RESOLVER',
  BeforeResolver = 'BEFORE_RESOLVER'
}

/** A connection to a list of items. */
export type ArchivedRequestsBySellerIdConnection = {
  __typename?: 'ArchivedRequestsBySellerIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ArchivedRequestsBySellerIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ArchivedRequestsBySellerIdEdge = {
  __typename?: 'ArchivedRequestsBySellerIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Request;
};

/** A connection to a list of items. */
export type ArchivedRfqsConnection = {
  __typename?: 'ArchivedRfqsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<ArchivedRfqsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Rfq>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type ArchivedRfqsEdge = {
  __typename?: 'ArchivedRfqsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Rfq;
};

export type Attachment = {
  __typename?: 'Attachment';
  fileName: Scalars['String'];
  objectKey: Scalars['String'];
  url?: Maybe<Scalars['String']>;
};

export type BooleanOperationFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  neq?: InputMaybe<Scalars['Boolean']>;
};

export type Buyer = {
  __typename?: 'Buyer';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type BuyerFilterInput = {
  and?: InputMaybe<Array<BuyerFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<BuyerFilterInput>>;
};

/** A connection to a list of items. */
export type BuyersConnection = {
  __typename?: 'BuyersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<BuyersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Buyer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type BuyersEdge = {
  __typename?: 'BuyersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Buyer;
};

export enum ChargeType {
  Annually = 'ANNUALLY',
  Daily = 'DAILY',
  Hourly = 'HOURLY',
  Total = 'TOTAL'
}

export type ChargeTypeOperationFilterInput = {
  eq?: InputMaybe<ChargeType>;
  in?: InputMaybe<Array<ChargeType>>;
  neq?: InputMaybe<ChargeType>;
  nin?: InputMaybe<Array<ChargeType>>;
};

export type ComparableDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<Scalars['DateTime']>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<Scalars['Float']>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type ComparableInt32OperationFilterInput = {
  eq?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  neq?: InputMaybe<Scalars['Int']>;
  ngt?: InputMaybe<Scalars['Int']>;
  ngte?: InputMaybe<Scalars['Int']>;
  nin?: InputMaybe<Array<Scalars['Int']>>;
  nlt?: InputMaybe<Scalars['Int']>;
  nlte?: InputMaybe<Scalars['Int']>;
};

export type ComparableNullableOfDateTimeOperationFilterInput = {
  eq?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  neq?: InputMaybe<Scalars['DateTime']>;
  ngt?: InputMaybe<Scalars['DateTime']>;
  ngte?: InputMaybe<Scalars['DateTime']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['DateTime']>>>;
  nlt?: InputMaybe<Scalars['DateTime']>;
  nlte?: InputMaybe<Scalars['DateTime']>;
};

export type ComparableNullableOfDoubleOperationFilterInput = {
  eq?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  neq?: InputMaybe<Scalars['Float']>;
  ngt?: InputMaybe<Scalars['Float']>;
  ngte?: InputMaybe<Scalars['Float']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  nlt?: InputMaybe<Scalars['Float']>;
  nlte?: InputMaybe<Scalars['Float']>;
};

export type CreateBuyerInput = {
  name: Scalars['String'];
};

export type CreateIndustryInput = {
  name: Scalars['String'];
};

export type CreateMultiRequestInput = {
  inputs: Array<CreateRequestInput>;
};

export type CreateMultiRequestSubmissionInput = {
  inputs: Array<CreateRequestSubmissionInput>;
};

export type CreateRequestInput = {
  isDeleted?: InputMaybe<Scalars['Boolean']>;
  isOpen?: InputMaybe<Scalars['Boolean']>;
  numberOfCandidates: Scalars['Int'];
  rFQNumber: Scalars['String'];
  sellerId: Scalars['String'];
};

export type CreateRequestSubmissionInput = {
  attachments: Array<Scalars['String']>;
  availability: Scalars['String'];
  chargeRate: Scalars['Float'];
  interviewDetails?: InputMaybe<Scalars['String']>;
  interviewTime?: InputMaybe<Scalars['DateTime']>;
  location: Array<Scalars['String']>;
  name: Scalars['String'];
  requestId: Scalars['String'];
  residency: Scalars['String'];
  security: SecurityClearance;
  sellerId: Scalars['String'];
  status: SubmissionStatus;
};

export type CreateRequestSubmissionOutput = {
  __typename?: 'CreateRequestSubmissionOutput';
  attachments: Array<Attachment>;
  availability: Scalars['String'];
  chargeRate: Scalars['Float'];
  interviewDetails?: Maybe<Scalars['String']>;
  interviewTime?: Maybe<Scalars['DateTime']>;
  location: Array<Scalars['String']>;
  name: Scalars['String'];
  requestId: Scalars['String'];
  requestSubmissionId: Scalars['ID'];
  residency: Scalars['String'];
  security: SecurityClearance;
  sellerId: Scalars['String'];
  status: SubmissionStatus;
};

export type CreateRfqInput = {
  activityType: ActivityType;
  attachments: Array<Scalars['String']>;
  buyerId: Scalars['String'];
  chargeType: ChargeType;
  contractDuration?: InputMaybe<Scalars['DateTime']>;
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  estimatedStartDate: Scalars['DateTime'];
  extensionTerms: Scalars['String'];
  industryId: Scalars['String'];
  location: Array<Scalars['String']>;
  maxCharge?: InputMaybe<Scalars['Float']>;
  maxSubmissions: Scalars['Int'];
  rFQNumber: Scalars['String'];
  rFQPublishedDate: Scalars['DateTime'];
  requestClosureDate: Scalars['DateTime'];
  residency: Array<Residencies>;
  security: SecurityClearance;
  status?: InputMaybe<Scalars['Boolean']>;
  supportingInformation: Scalars['String'];
  title: Scalars['String'];
  workingArrangement: WorkingArrangement;
};

export type CreateRfqOutput = {
  __typename?: 'CreateRfqOutput';
  activityType: ActivityType;
  attachments: Array<Attachment>;
  buyerId: Scalars['String'];
  chargeType: ChargeType;
  contractDuration?: Maybe<Scalars['DateTime']>;
  country: Scalars['String'];
  createOverride: Rfq;
  currencyCode: Scalars['String'];
  estimatedStartDate: Scalars['DateTime'];
  extensionTerms: Scalars['String'];
  industryId: Scalars['String'];
  location: Array<Scalars['String']>;
  maxCharge?: Maybe<Scalars['Float']>;
  maxSubmissions: Scalars['Int'];
  rFQNumber: Scalars['ID'];
  rFQPublishedDate: Scalars['DateTime'];
  requestClosureDate: Scalars['DateTime'];
  residency: Array<Residencies>;
  security: SecurityClearance;
  status?: Maybe<Scalars['Boolean']>;
  supportingInformation: Scalars['String'];
  title: Scalars['String'];
  workingArrangement: WorkingArrangement;
};


export type CreateRfqOutputCreateOverrideArgs = {
  existingRfq: RfqInput;
  overrideRfq: UpdateRfqInput;
};

export type CreateSellerInput = {
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateSellerOutput = {
  __typename?: 'CreateSellerOutput';
  id: Scalars['ID'];
  logo?: Maybe<Attachment>;
  name: Scalars['String'];
};

export type CreateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRoleInput;
  sellerId?: InputMaybe<Scalars['String']>;
};

export type DeactivationPeriodFilterInput = {
  and?: InputMaybe<Array<DeactivationPeriodFilterInput>>;
  endDate?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  or?: InputMaybe<Array<DeactivationPeriodFilterInput>>;
  startDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
};

/** A connection to a list of items. */
export type IndustriesConnection = {
  __typename?: 'IndustriesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<IndustriesEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Industry>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type IndustriesEdge = {
  __typename?: 'IndustriesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Industry;
};

export type Industry = {
  __typename?: 'Industry';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type IndustryFilterInput = {
  and?: InputMaybe<Array<IndustryFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<IndustryFilterInput>>;
};

export type ListFilterInputTypeOfDeactivationPeriodFilterInput = {
  all?: InputMaybe<DeactivationPeriodFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<DeactivationPeriodFilterInput>;
  some?: InputMaybe<DeactivationPeriodFilterInput>;
};

export type ListResidenciesOperationFilterInput = {
  all?: InputMaybe<ResidenciesOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<ResidenciesOperationFilterInput>;
  some?: InputMaybe<ResidenciesOperationFilterInput>;
};

export type ListStringOperationFilterInput = {
  all?: InputMaybe<StringOperationFilterInput>;
  any?: InputMaybe<Scalars['Boolean']>;
  none?: InputMaybe<StringOperationFilterInput>;
  some?: InputMaybe<StringOperationFilterInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  closeRequest: Request;
  closeRfq: Rfq;
  createBuyer: Buyer;
  createIndustry: Industry;
  createMultiRequestSubmissions: Array<CreateRequestSubmissionOutput>;
  createMultiRequests: Array<Request>;
  createRequest: Request;
  createRfq: CreateRfqOutput;
  createSeller: CreateSellerOutput;
  createUser: User;
  deleteBuyerById: Scalars['Boolean'];
  deleteIndustryById: Scalars['Boolean'];
  deleteRequestById: Scalars['Boolean'];
  deleteRequestSubmissionById: Scalars['Boolean'];
  deleteRfqByNumber: Scalars['Boolean'];
  deleteRfqOverrideByRequestId: Request;
  deleteSellerById: Scalars['Boolean'];
  deleteUserById: Scalars['Boolean'];
  disableUserById: Scalars['Boolean'];
  enableUserById: Scalars['Boolean'];
  ensureRequestChatIsInitialised: Scalars['Boolean'];
  ensureSellerChatIsInitialised: Scalars['Boolean'];
  sellerUpdateRequestSubmission: UpdateRequestSubmissionOutput;
  setNoSubmissionByRequestId: Scalars['Boolean'];
  setRfqOverrideByRequestId: OverrideRequestRfqOutput;
  updateRequestSubmission: RequestSubmission;
  updateRfq: UpdateRfqOutput;
  updateSeller: UpdateSellerOutput;
  updateUser: User;
};


export type MutationCloseRequestArgs = {
  requestId: Scalars['String'];
};


export type MutationCloseRfqArgs = {
  rfqNumber: Scalars['String'];
};


export type MutationCreateBuyerArgs = {
  input: CreateBuyerInput;
};


export type MutationCreateIndustryArgs = {
  input: CreateIndustryInput;
};


export type MutationCreateMultiRequestSubmissionsArgs = {
  input: CreateMultiRequestSubmissionInput;
};


export type MutationCreateMultiRequestsArgs = {
  input: CreateMultiRequestInput;
};


export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};


export type MutationCreateRfqArgs = {
  input: CreateRfqInput;
};


export type MutationCreateSellerArgs = {
  input: CreateSellerInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationDeleteBuyerByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteIndustryByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRequestByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteRequestSubmissionByIdArgs = {
  requestSubmissionId: Scalars['String'];
};


export type MutationDeleteRfqByNumberArgs = {
  rfqNumber: Scalars['String'];
};


export type MutationDeleteRfqOverrideByRequestIdArgs = {
  requestId: Scalars['String'];
};


export type MutationDeleteSellerByIdArgs = {
  id: Scalars['String'];
};


export type MutationDeleteUserByIdArgs = {
  id: Scalars['String'];
};


export type MutationDisableUserByIdArgs = {
  userId: Scalars['String'];
};


export type MutationEnableUserByIdArgs = {
  userId: Scalars['String'];
};


export type MutationEnsureRequestChatIsInitialisedArgs = {
  requestId: Scalars['String'];
};


export type MutationEnsureSellerChatIsInitialisedArgs = {
  sellerId?: InputMaybe<Scalars['String']>;
};


export type MutationSellerUpdateRequestSubmissionArgs = {
  input: SellerUpdateRequestSubmissionInput;
};


export type MutationSetNoSubmissionByRequestIdArgs = {
  noSubmissionValue: Scalars['Boolean'];
  requestId: Scalars['String'];
};


export type MutationSetRfqOverrideByRequestIdArgs = {
  requestId: Scalars['String'];
  rfqInput: UpdateRfqInput;
};


export type MutationUpdateRequestSubmissionArgs = {
  input: UpdateRequestSubmissionInput;
};


export type MutationUpdateRfqArgs = {
  input: UpdateRfqInput;
};


export type MutationUpdateSellerArgs = {
  input: UpdateSellerInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** A connection to a list of items. */
export type NonArchivedRequestsBySellerIdConnection = {
  __typename?: 'NonArchivedRequestsBySellerIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NonArchivedRequestsBySellerIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NonArchivedRequestsBySellerIdEdge = {
  __typename?: 'NonArchivedRequestsBySellerIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Request;
};

/** A connection to a list of items. */
export type NonArchivedRfqsConnection = {
  __typename?: 'NonArchivedRfqsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<NonArchivedRfqsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Rfq>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type NonArchivedRfqsEdge = {
  __typename?: 'NonArchivedRfqsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Rfq;
};

export type NullableOfRequestStatusOperationFilterInput = {
  eq?: InputMaybe<RequestStatus>;
  in?: InputMaybe<Array<InputMaybe<RequestStatus>>>;
  neq?: InputMaybe<RequestStatus>;
  nin?: InputMaybe<Array<InputMaybe<RequestStatus>>>;
};

export type OverrideRequestRfqOutput = {
  __typename?: 'OverrideRequestRfqOutput';
  attachments: Array<Attachment>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isOpen: Scalars['Boolean'];
  noSubmission: Scalars['Boolean'];
  numberOfCandidates: Scalars['Int'];
  rFQNumber: Scalars['String'];
  requestId: Scalars['ID'];
  rfqOverride?: Maybe<Rfq>;
  sellerId: Scalars['String'];
  status?: Maybe<RequestStatus>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Indicates whether more edges exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more edges exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  allArchivedRequests?: Maybe<AllArchivedRequestsConnection>;
  allNonArchivedRequests?: Maybe<AllNonArchivedRequestsConnection>;
  allRequests?: Maybe<AllRequestsConnection>;
  archivedRequestsBySellerId?: Maybe<ArchivedRequestsBySellerIdConnection>;
  archivedRfqs?: Maybe<ArchivedRfqsConnection>;
  buyerById?: Maybe<Buyer>;
  buyerByName?: Maybe<Buyer>;
  buyers?: Maybe<BuyersConnection>;
  citiesByCountryCode: Array<Scalars['String']>;
  currentSeller: Seller;
  currentUser: User;
  industries?: Maybe<IndustriesConnection>;
  industriesById?: Maybe<Industry>;
  industryByName?: Maybe<Industry>;
  nonArchivedRequestsBySellerId?: Maybe<NonArchivedRequestsBySellerIdConnection>;
  nonArchivedRfqs?: Maybe<NonArchivedRfqsConnection>;
  requestById?: Maybe<Request>;
  requestSubmissionById: RequestSubmission;
  requestSubmissions?: Maybe<RequestSubmissionsConnection>;
  requestSubmissionsBySeller?: Maybe<RequestSubmissionsBySellerConnection>;
  requestSubmissionsBySellerAndRequest?: Maybe<RequestSubmissionsBySellerAndRequestConnection>;
  requestsBySellerId?: Maybe<RequestsBySellerIdConnection>;
  rfqByNumber: Rfq;
  rfqs?: Maybe<RfqsConnection>;
  sellers?: Maybe<SellersConnection>;
  sellersById?: Maybe<Seller>;
  sellersByName?: Maybe<Seller>;
  userById: User;
  users?: Maybe<UsersConnection>;
  usersBySellerId: Array<User>;
};


export type QueryAllArchivedRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestFilterInput>;
};


export type QueryAllNonArchivedRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestFilterInput>;
};


export type QueryAllRequestsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestFilterInput>;
};


export type QueryArchivedRequestsBySellerIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sellerId: Scalars['String'];
  where?: InputMaybe<RequestFilterInput>;
};


export type QueryArchivedRfqsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RfqFilterInput>;
};


export type QueryBuyerByIdArgs = {
  id: Scalars['String'];
};


export type QueryBuyerByNameArgs = {
  name: Scalars['String'];
};


export type QueryBuyersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<BuyerFilterInput>;
};


export type QueryCitiesByCountryCodeArgs = {
  countryCode: Scalars['String'];
};


export type QueryIndustriesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<IndustryFilterInput>;
};


export type QueryIndustriesByIdArgs = {
  id: Scalars['String'];
};


export type QueryIndustryByNameArgs = {
  name: Scalars['String'];
};


export type QueryNonArchivedRequestsBySellerIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sellerId: Scalars['String'];
  where?: InputMaybe<RequestFilterInput>;
};


export type QueryNonArchivedRfqsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RfqFilterInput>;
};


export type QueryRequestByIdArgs = {
  id: Scalars['String'];
};


export type QueryRequestSubmissionByIdArgs = {
  requestSubmissionId: Scalars['String'];
};


export type QueryRequestSubmissionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RequestSubmissionFilterInput>;
};


export type QueryRequestSubmissionsBySellerArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sellerId: Scalars['String'];
  where?: InputMaybe<RequestSubmissionFilterInput>;
};


export type QueryRequestSubmissionsBySellerAndRequestArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  requestId: Scalars['String'];
  sellerId?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<RequestSubmissionFilterInput>;
};


export type QueryRequestsBySellerIdArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sellerId: Scalars['String'];
  where?: InputMaybe<RequestFilterInput>;
};


export type QueryRfqByNumberArgs = {
  rfqNumber: Scalars['String'];
};


export type QueryRfqsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<RfqFilterInput>;
};


export type QuerySellersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<SellerFilterInput>;
};


export type QuerySellersByIdArgs = {
  id: Scalars['String'];
};


export type QuerySellersByNameArgs = {
  name: Scalars['String'];
};


export type QueryUserByIdArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserFilterInput>;
};


export type QueryUsersBySellerIdArgs = {
  sellerId: Scalars['String'];
};

export type Rfq = {
  __typename?: 'RFQ';
  activityType: ActivityType;
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  buyer?: Maybe<Buyer>;
  chargeType: ChargeType;
  contractDuration?: Maybe<Scalars['DateTime']>;
  country: Scalars['String'];
  createOverride: Rfq;
  currencyCode: Scalars['String'];
  estimatedStartDate: Scalars['DateTime'];
  extensionTerms: Scalars['String'];
  industry?: Maybe<Industry>;
  industryId: Scalars['String'];
  location: Array<Scalars['String']>;
  maxCharge?: Maybe<Scalars['Float']>;
  maxSubmissions: Scalars['Int'];
  rFQNumber: Scalars['ID'];
  rFQPublishedDate: Scalars['DateTime'];
  requestClosureDate: Scalars['DateTime'];
  residency: Array<Residencies>;
  security: SecurityClearance;
  status?: Maybe<Scalars['Boolean']>;
  supportingInformation: Scalars['String'];
  title: Scalars['String'];
  workingArrangement: WorkingArrangement;
};


export type RfqCreateOverrideArgs = {
  existingRfq: RfqInput;
  overrideRfq: UpdateRfqInput;
};

export type RfqFilterInput = {
  activityType?: InputMaybe<ActivityTypeOperationFilterInput>;
  and?: InputMaybe<Array<RfqFilterInput>>;
  buyerId?: InputMaybe<StringOperationFilterInput>;
  chargeType?: InputMaybe<ChargeTypeOperationFilterInput>;
  contractDuration?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  country?: InputMaybe<StringOperationFilterInput>;
  currencyCode?: InputMaybe<StringOperationFilterInput>;
  estimatedStartDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  extensionTerms?: InputMaybe<StringOperationFilterInput>;
  industryId?: InputMaybe<StringOperationFilterInput>;
  location?: InputMaybe<ListStringOperationFilterInput>;
  maxCharge?: InputMaybe<ComparableNullableOfDoubleOperationFilterInput>;
  maxSubmissions?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<RfqFilterInput>>;
  rFQNumber?: InputMaybe<StringOperationFilterInput>;
  rFQPublishedDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  requestClosureDate?: InputMaybe<ComparableDateTimeOperationFilterInput>;
  residency?: InputMaybe<ListResidenciesOperationFilterInput>;
  security?: InputMaybe<SecurityClearanceOperationFilterInput>;
  status?: InputMaybe<BooleanOperationFilterInput>;
  supportingInformation?: InputMaybe<StringOperationFilterInput>;
  title?: InputMaybe<StringOperationFilterInput>;
  workingArrangement?: InputMaybe<WorkingArrangementOperationFilterInput>;
};

export type RfqInput = {
  activityType: ActivityType;
  buyerId: Scalars['String'];
  chargeType: ChargeType;
  contractDuration?: InputMaybe<Scalars['DateTime']>;
  country: Scalars['String'];
  currencyCode: Scalars['String'];
  estimatedStartDate: Scalars['DateTime'];
  extensionTerms: Scalars['String'];
  industryId: Scalars['String'];
  location: Array<Scalars['String']>;
  maxCharge?: InputMaybe<Scalars['Float']>;
  maxSubmissions: Scalars['Int'];
  rFQNumber: Scalars['String'];
  rFQPublishedDate: Scalars['DateTime'];
  requestClosureDate: Scalars['DateTime'];
  residency: Array<Residencies>;
  security: SecurityClearance;
  status?: InputMaybe<Scalars['Boolean']>;
  supportingInformation: Scalars['String'];
  title: Scalars['String'];
  workingArrangement: WorkingArrangement;
};

export type Request = {
  __typename?: 'Request';
  isDeleted?: Maybe<Scalars['Boolean']>;
  isOpen: Scalars['Boolean'];
  noSubmission: Scalars['Boolean'];
  numberOfCandidates: Scalars['Int'];
  numberSupplied: Scalars['Int'];
  overrideAttachments?: Maybe<Array<Maybe<Attachment>>>;
  requestId: Scalars['ID'];
  rfq?: Maybe<Rfq>;
  rfqOverride?: Maybe<Rfq>;
  seller?: Maybe<Seller>;
  status?: Maybe<RequestStatus>;
};

export type RequestFilterInput = {
  and?: InputMaybe<Array<RequestFilterInput>>;
  isDeleted?: InputMaybe<BooleanOperationFilterInput>;
  isOpen?: InputMaybe<BooleanOperationFilterInput>;
  noSubmission?: InputMaybe<BooleanOperationFilterInput>;
  numberOfCandidates?: InputMaybe<ComparableInt32OperationFilterInput>;
  or?: InputMaybe<Array<RequestFilterInput>>;
  rFQNumber?: InputMaybe<StringOperationFilterInput>;
  requestId?: InputMaybe<StringOperationFilterInput>;
  rfqOverride?: InputMaybe<RfqFilterInput>;
  sellerId?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<NullableOfRequestStatusOperationFilterInput>;
};

export enum RequestStatus {
  BuyerPending = 'BUYER_PENDING',
  Closed = 'CLOSED',
  Interview = 'INTERVIEW',
  NotSubmitted = 'NOT_SUBMITTED',
  Open = 'OPEN',
  OptOut = 'OPT_OUT',
  Shortlisted = 'SHORTLISTED',
  Successful = 'SUCCESSFUL',
  Unsuccessful = 'UNSUCCESSFUL',
  Wip = 'WIP',
  Withdrawn = 'WITHDRAWN'
}

export type RequestSubmission = {
  __typename?: 'RequestSubmission';
  attachments?: Maybe<Array<Maybe<Attachment>>>;
  availability: Scalars['String'];
  chargeRate: Scalars['Float'];
  interviewDetails?: Maybe<Scalars['String']>;
  interviewTime?: Maybe<Scalars['DateTime']>;
  location: Array<Scalars['String']>;
  name: Scalars['String'];
  request?: Maybe<Request>;
  requestSubmissionId: Scalars['ID'];
  residency: Scalars['String'];
  security: SecurityClearance;
  sellerId: Scalars['String'];
  status: SubmissionStatus;
};

export type RequestSubmissionFilterInput = {
  and?: InputMaybe<Array<RequestSubmissionFilterInput>>;
  availability?: InputMaybe<StringOperationFilterInput>;
  chargeRate?: InputMaybe<ComparableDoubleOperationFilterInput>;
  interviewDetails?: InputMaybe<StringOperationFilterInput>;
  interviewTime?: InputMaybe<ComparableNullableOfDateTimeOperationFilterInput>;
  location?: InputMaybe<ListStringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<RequestSubmissionFilterInput>>;
  requestId?: InputMaybe<StringOperationFilterInput>;
  requestSubmissionId?: InputMaybe<StringOperationFilterInput>;
  residency?: InputMaybe<StringOperationFilterInput>;
  security?: InputMaybe<SecurityClearanceOperationFilterInput>;
  sellerId?: InputMaybe<StringOperationFilterInput>;
  status?: InputMaybe<SubmissionStatusOperationFilterInput>;
};

/** A connection to a list of items. */
export type RequestSubmissionsBySellerAndRequestConnection = {
  __typename?: 'RequestSubmissionsBySellerAndRequestConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RequestSubmissionsBySellerAndRequestEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RequestSubmission>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RequestSubmissionsBySellerAndRequestEdge = {
  __typename?: 'RequestSubmissionsBySellerAndRequestEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RequestSubmission;
};

/** A connection to a list of items. */
export type RequestSubmissionsBySellerConnection = {
  __typename?: 'RequestSubmissionsBySellerConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RequestSubmissionsBySellerEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RequestSubmission>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RequestSubmissionsBySellerEdge = {
  __typename?: 'RequestSubmissionsBySellerEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RequestSubmission;
};

/** A connection to a list of items. */
export type RequestSubmissionsConnection = {
  __typename?: 'RequestSubmissionsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RequestSubmissionsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<RequestSubmission>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RequestSubmissionsEdge = {
  __typename?: 'RequestSubmissionsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: RequestSubmission;
};

/** A connection to a list of items. */
export type RequestsBySellerIdConnection = {
  __typename?: 'RequestsBySellerIdConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RequestsBySellerIdEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RequestsBySellerIdEdge = {
  __typename?: 'RequestsBySellerIdEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Request;
};

export enum Residencies {
  Citizen = 'CITIZEN',
  Na = 'NA',
  Pr = 'PR',
  Visa = 'VISA'
}

export type ResidenciesOperationFilterInput = {
  eq?: InputMaybe<Residencies>;
  in?: InputMaybe<Array<Residencies>>;
  neq?: InputMaybe<Residencies>;
  nin?: InputMaybe<Array<Residencies>>;
};

/** A connection to a list of items. */
export type RfqsConnection = {
  __typename?: 'RfqsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<RfqsEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Rfq>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type RfqsEdge = {
  __typename?: 'RfqsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Rfq;
};

export enum SecurityClearance {
  Baseline = 'BASELINE',
  Na = 'NA',
  Nv1 = 'NV1',
  Nv2 = 'NV2'
}

export type SecurityClearanceOperationFilterInput = {
  eq?: InputMaybe<SecurityClearance>;
  in?: InputMaybe<Array<SecurityClearance>>;
  neq?: InputMaybe<SecurityClearance>;
  nin?: InputMaybe<Array<SecurityClearance>>;
};

export type Seller = {
  __typename?: 'Seller';
  id: Scalars['ID'];
  logo?: Maybe<Attachment>;
  name: Scalars['String'];
  userList?: Maybe<Array<Maybe<User>>>;
};

export type SellerFilterInput = {
  and?: InputMaybe<Array<SellerFilterInput>>;
  id?: InputMaybe<StringOperationFilterInput>;
  name?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<SellerFilterInput>>;
};

export type SellerUpdateRequestSubmissionInput = {
  attachments: Array<Scalars['String']>;
  availability: Scalars['String'];
  chargeRate: Scalars['Float'];
  location: Array<Scalars['String']>;
  name: Scalars['String'];
  requestSubmissionId: Scalars['String'];
  residency: Scalars['String'];
  security: SecurityClearance;
};

/** A connection to a list of items. */
export type SellersConnection = {
  __typename?: 'SellersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<SellersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<Seller>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type SellersEdge = {
  __typename?: 'SellersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: Seller;
};

export type StringOperationFilterInput = {
  and?: InputMaybe<Array<StringOperationFilterInput>>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  ncontains?: InputMaybe<Scalars['String']>;
  nendsWith?: InputMaybe<Scalars['String']>;
  neq?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  nstartsWith?: InputMaybe<Scalars['String']>;
  or?: InputMaybe<Array<StringOperationFilterInput>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum SubmissionStatus {
  BuyerPending = 'BUYER_PENDING',
  Interview = 'INTERVIEW',
  NotSubmitted = 'NOT_SUBMITTED',
  Shortlisted = 'SHORTLISTED',
  Successful = 'SUCCESSFUL',
  Unsuccessful = 'UNSUCCESSFUL',
  Wip = 'WIP',
  Withdrawn = 'WITHDRAWN'
}

export type SubmissionStatusOperationFilterInput = {
  eq?: InputMaybe<SubmissionStatus>;
  in?: InputMaybe<Array<SubmissionStatus>>;
  neq?: InputMaybe<SubmissionStatus>;
  nin?: InputMaybe<Array<SubmissionStatus>>;
};

export type UpdateRequestSubmissionInput = {
  id: Scalars['String'];
  interviewDetails?: InputMaybe<Scalars['String']>;
  interviewTime?: InputMaybe<Scalars['DateTime']>;
  status: SubmissionStatus;
};

export type UpdateRequestSubmissionOutput = {
  __typename?: 'UpdateRequestSubmissionOutput';
  attachments: Array<Attachment>;
  availability: Scalars['String'];
  chargeRate: Scalars['Float'];
  interviewDetails?: Maybe<Scalars['String']>;
  interviewTime?: Maybe<Scalars['DateTime']>;
  location: Array<Scalars['String']>;
  name: Scalars['String'];
  requestId: Scalars['String'];
  requestSubmissionId: Scalars['ID'];
  residency: Scalars['String'];
  security: SecurityClearance;
  sellerId: Scalars['String'];
  status: SubmissionStatus;
};

export type UpdateRfqInput = {
  attachments: Array<Scalars['String']>;
  chargeType: ChargeType;
  contractDuration?: InputMaybe<Scalars['DateTime']>;
  currencyCode: Scalars['String'];
  estimatedStartDate: Scalars['DateTime'];
  extensionTerms: Scalars['String'];
  location: Array<Scalars['String']>;
  maxCharge?: InputMaybe<Scalars['Float']>;
  maxSubmissions: Scalars['Int'];
  rFQNumber: Scalars['String'];
  requestClosureDate: Scalars['DateTime'];
  residency: Array<Residencies>;
  security: SecurityClearance;
  supportingInformation: Scalars['String'];
  workingArrangement: WorkingArrangement;
};

export type UpdateRfqOutput = {
  __typename?: 'UpdateRfqOutput';
  activityType: ActivityType;
  attachments: Array<Attachment>;
  buyerId: Scalars['String'];
  chargeType: ChargeType;
  contractDuration?: Maybe<Scalars['DateTime']>;
  country: Scalars['String'];
  createOverride: Rfq;
  currencyCode: Scalars['String'];
  estimatedStartDate: Scalars['DateTime'];
  extensionTerms: Scalars['String'];
  industryId: Scalars['String'];
  location: Array<Scalars['String']>;
  maxCharge?: Maybe<Scalars['Float']>;
  maxSubmissions: Scalars['Int'];
  rFQNumber: Scalars['String'];
  rFQPublishedDate: Scalars['DateTime'];
  requestClosureDate: Scalars['DateTime'];
  residency: Array<Residencies>;
  security: SecurityClearance;
  status?: Maybe<Scalars['Boolean']>;
  supportingInformation: Scalars['String'];
  title: Scalars['String'];
  workingArrangement: WorkingArrangement;
};


export type UpdateRfqOutputCreateOverrideArgs = {
  existingRfq: RfqInput;
  overrideRfq: UpdateRfqInput;
};

export type UpdateSellerInput = {
  id: Scalars['String'];
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type UpdateSellerOutput = {
  __typename?: 'UpdateSellerOutput';
  id: Scalars['ID'];
  logo?: Maybe<Attachment>;
  name: Scalars['String'];
};

export type UpdateUserInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  email: Scalars['String'];
  enabled: Scalars['Boolean'];
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  role: UserRole;
  seller?: Maybe<Seller>;
  userStatus?: Maybe<UserStatus>;
};

export type UserFilterInput = {
  and?: InputMaybe<Array<UserFilterInput>>;
  deactivationPeriods?: InputMaybe<ListFilterInputTypeOfDeactivationPeriodFilterInput>;
  email?: InputMaybe<StringOperationFilterInput>;
  enabled?: InputMaybe<BooleanOperationFilterInput>;
  firstName?: InputMaybe<StringOperationFilterInput>;
  fullName?: InputMaybe<StringOperationFilterInput>;
  id?: InputMaybe<StringOperationFilterInput>;
  lastName?: InputMaybe<StringOperationFilterInput>;
  or?: InputMaybe<Array<UserFilterInput>>;
  role?: InputMaybe<UserRoleOperationFilterInput>;
  sellerId?: InputMaybe<StringOperationFilterInput>;
  userStatus?: InputMaybe<UserStatusOperationFilterInput>;
};

export enum UserRole {
  GsAdmin = 'GS_ADMIN',
  SellerAdmin = 'SELLER_ADMIN'
}

export enum UserRoleInput {
  GsAdmin = 'GS_ADMIN',
  SellerAdmin = 'SELLER_ADMIN'
}

export type UserRoleOperationFilterInput = {
  eq?: InputMaybe<UserRole>;
  in?: InputMaybe<Array<UserRole>>;
  neq?: InputMaybe<UserRole>;
  nin?: InputMaybe<Array<UserRole>>;
};

export enum UserStatus {
  Archived = 'ARCHIVED',
  Compromised = 'COMPROMISED',
  Confirmed = 'CONFIRMED',
  ForceChangePassword = 'FORCE_CHANGE_PASSWORD',
  ResetRequired = 'RESET_REQUIRED',
  Unconfirmed = 'UNCONFIRMED',
  Unknown = 'UNKNOWN'
}

export type UserStatusOperationFilterInput = {
  eq?: InputMaybe<UserStatus>;
  in?: InputMaybe<Array<UserStatus>>;
  neq?: InputMaybe<UserStatus>;
  nin?: InputMaybe<Array<UserStatus>>;
};

/** A connection to a list of items. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges. */
  edges?: Maybe<Array<UsersEdge>>;
  /** A flattened list of the nodes. */
  nodes?: Maybe<Array<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node: User;
};

export enum WorkingArrangement {
  Hybrid = 'HYBRID',
  Onsite = 'ONSITE',
  Remote = 'REMOTE'
}

export type WorkingArrangementOperationFilterInput = {
  eq?: InputMaybe<WorkingArrangement>;
  in?: InputMaybe<Array<WorkingArrangement>>;
  neq?: InputMaybe<WorkingArrangement>;
  nin?: InputMaybe<Array<WorkingArrangement>>;
};

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = { __typename?: 'Query', currentUser: { __typename?: 'User', id: string, email: string, userStatus?: UserStatus | null, firstName: string, lastName: string, seller?: { __typename?: 'Seller', id: string, name: string } | null } };

export type CreateBuyerMutationVariables = Exact<{
  createBuyerInput: CreateBuyerInput;
}>;


export type CreateBuyerMutation = { __typename?: 'Mutation', createBuyer: { __typename?: 'Buyer', id: string, name: string } };

export type DeleteBuyerByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteBuyerByIdMutation = { __typename?: 'Mutation', deleteBuyerById: boolean };

export type GetAllBuyersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllBuyersQuery = { __typename?: 'Query', buyers?: { __typename?: 'BuyersConnection', nodes?: Array<{ __typename?: 'Buyer', id: string, name: string }> | null } | null };

export type GetBuyerByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetBuyerByIdQuery = { __typename?: 'Query', buyerById?: { __typename?: 'Buyer', id: string, name: string } | null };

export type GetBuyerbyNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetBuyerbyNameQuery = { __typename?: 'Query', buyerByName?: { __typename?: 'Buyer', id: string, name: string } | null };

export type EnsureRequestChatIsInitialisedMutationVariables = Exact<{
  requestId: Scalars['String'];
}>;


export type EnsureRequestChatIsInitialisedMutation = { __typename?: 'Mutation', ensureRequestChatIsInitialised: boolean };

export type EnsureSellerChatIsInitialisedMutationVariables = Exact<{
  sellerId?: InputMaybe<Scalars['String']>;
}>;


export type EnsureSellerChatIsInitialisedMutation = { __typename?: 'Mutation', ensureSellerChatIsInitialised: boolean };

export type CreateIndustryMutationVariables = Exact<{
  industry: CreateIndustryInput;
}>;


export type CreateIndustryMutation = { __typename?: 'Mutation', createIndustry: { __typename?: 'Industry', id: string, name: string } };

export type DeleteIndustryByIdMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteIndustryByIdMutation = { __typename?: 'Mutation', deleteIndustryById: boolean };

export type GetAllIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllIndustriesQuery = { __typename?: 'Query', industries?: { __typename?: 'IndustriesConnection', nodes?: Array<{ __typename?: 'Industry', id: string, name: string }> | null } | null };

export type GetCitiesByCountryCodeQueryVariables = Exact<{
  countryCode: Scalars['String'];
}>;


export type GetCitiesByCountryCodeQuery = { __typename?: 'Query', citiesByCountryCode: Array<string> };

export type CreateMultiRequestSubmissionsMutationVariables = Exact<{
  rs: CreateMultiRequestSubmissionInput;
}>;


export type CreateMultiRequestSubmissionsMutation = { __typename?: 'Mutation', createMultiRequestSubmissions: Array<{ __typename?: 'CreateRequestSubmissionOutput', requestSubmissionId: string, sellerId: string, requestId: string, name: string, location: Array<string>, security: SecurityClearance, residency: string, availability: string, chargeRate: number, status: SubmissionStatus, interviewTime?: any | null, attachments: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string }> }> };

export type DeleteRequestSubmissionByIdMutationVariables = Exact<{
  requestSubmissionId: Scalars['String'];
}>;


export type DeleteRequestSubmissionByIdMutation = { __typename?: 'Mutation', deleteRequestSubmissionById: boolean };

export type SellerUpdateRequestSubmissionMutationVariables = Exact<{
  input: SellerUpdateRequestSubmissionInput;
}>;


export type SellerUpdateRequestSubmissionMutation = { __typename?: 'Mutation', sellerUpdateRequestSubmission: { __typename?: 'UpdateRequestSubmissionOutput', requestSubmissionId: string, name: string, location: Array<string>, residency: string, security: SecurityClearance, availability: string, chargeRate: number, attachments: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string }> } };

export type UpdateRequestSubmissionMutationVariables = Exact<{
  input: UpdateRequestSubmissionInput;
}>;


export type UpdateRequestSubmissionMutation = { __typename?: 'Mutation', updateRequestSubmission: { __typename?: 'RequestSubmission', status: SubmissionStatus, interviewTime?: any | null } };

export type GetAllRequestSubmissionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRequestSubmissionsQuery = { __typename?: 'Query', requestSubmissions?: { __typename?: 'RequestSubmissionsConnection', nodes?: Array<{ __typename?: 'RequestSubmission', requestSubmissionId: string, sellerId: string, name: string, location: Array<string>, residency: string, security: SecurityClearance, availability: string, chargeRate: number, status: SubmissionStatus, interviewTime?: any | null, interviewDetails?: string | null, request?: { __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, rFQPublishedDate: any, requestClosureDate: any, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null } | null }> | null } | null };

export type GetRequestSubmissionsByIdQueryVariables = Exact<{
  requestSubmissionId: Scalars['String'];
}>;


export type GetRequestSubmissionsByIdQuery = { __typename?: 'Query', requestSubmissionById: { __typename?: 'RequestSubmission', requestSubmissionId: string, sellerId: string, name: string, location: Array<string>, security: SecurityClearance, residency: string, availability: string, chargeRate: number, status: SubmissionStatus, interviewTime?: any | null, interviewDetails?: string | null, request?: { __typename?: 'Request', requestId: string, seller?: { __typename?: 'Seller', id: string, name: string } | null, rfq?: { __typename?: 'RFQ', title: string, rFQNumber: string, chargeType: ChargeType, currencyCode: string, country: string } | null } | null, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null } };

export type GetRequestSubmissionsBySellerAndRequestQueryVariables = Exact<{
  sellerId: Scalars['String'];
  requestId: Scalars['String'];
}>;


export type GetRequestSubmissionsBySellerAndRequestQuery = { __typename?: 'Query', requestSubmissionsBySellerAndRequest?: { __typename?: 'RequestSubmissionsBySellerAndRequestConnection', nodes?: Array<{ __typename?: 'RequestSubmission', requestSubmissionId: string, name: string, location: Array<string>, security: SecurityClearance, residency: string, availability: string, chargeRate: number, status: SubmissionStatus, interviewTime?: any | null, interviewDetails?: string | null, request?: { __typename?: 'Request', requestId: string, isOpen: boolean } | null }> | null } | null };

export type GetRequestSubmissionsBySellerIdQueryVariables = Exact<{
  sellerId: Scalars['String'];
}>;


export type GetRequestSubmissionsBySellerIdQuery = { __typename?: 'Query', requestSubmissionsBySeller?: { __typename?: 'RequestSubmissionsBySellerConnection', nodes?: Array<{ __typename?: 'RequestSubmission', requestSubmissionId: string, name: string, location: Array<string>, security: SecurityClearance, residency: string, availability: string, chargeRate: number, status: SubmissionStatus, interviewTime?: any | null, interviewDetails?: string | null, request?: { __typename?: 'Request', requestId: string, rfq?: { __typename?: 'RFQ', title: string } | null } | null }> | null } | null };

export type CloseRequestMutationVariables = Exact<{
  closeRequestId: Scalars['String'];
}>;


export type CloseRequestMutation = { __typename?: 'Mutation', closeRequest: { __typename?: 'Request', requestId: string } };

export type CreateMultiRequestMutationVariables = Exact<{
  requests: CreateMultiRequestInput;
}>;


export type CreateMultiRequestMutation = { __typename?: 'Mutation', createMultiRequests: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null }> };

export type CreateRequestMutationVariables = Exact<{
  request: CreateRequestInput;
}>;


export type CreateRequestMutation = { __typename?: 'Mutation', createRequest: { __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null } };

export type DeleteRequestMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteRequestMutation = { __typename?: 'Mutation', deleteRequestById: boolean };

export type DeleteRfqOverrideByRequestIdMutationVariables = Exact<{
  requestId: Scalars['String'];
}>;


export type DeleteRfqOverrideByRequestIdMutation = { __typename?: 'Mutation', deleteRfqOverrideByRequestId: { __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null } };

export type SetNoSubmissionByRequestIdMutationVariables = Exact<{
  requestId: Scalars['String'];
  noSubmissionValue: Scalars['Boolean'];
}>;


export type SetNoSubmissionByRequestIdMutation = { __typename?: 'Mutation', setNoSubmissionByRequestId: boolean };

export type SetRfqOverrideByRequestIdMutationVariables = Exact<{
  requestId: Scalars['String'];
  rfqInput: UpdateRfqInput;
}>;


export type SetRfqOverrideByRequestIdMutation = { __typename?: 'Mutation', setRfqOverrideByRequestId: { __typename?: 'OverrideRequestRfqOutput', requestId: string, attachments: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string }> } };

export type GetAllArchivedRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllArchivedRequestsQuery = { __typename?: 'Query', allArchivedRequests?: { __typename?: 'AllArchivedRequestsConnection', nodes?: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null, rfqOverride?: { __typename?: 'RFQ', rFQNumber: string } | null, overrideAttachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null }> | null } | null };

export type GetAllNonArchivedRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllNonArchivedRequestsQuery = { __typename?: 'Query', allNonArchivedRequests?: { __typename?: 'AllNonArchivedRequestsConnection', nodes?: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null, rfqOverride?: { __typename?: 'RFQ', rFQNumber: string } | null, overrideAttachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null }> | null } | null };

export type GetAllRequestsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRequestsQuery = { __typename?: 'Query', allRequests?: { __typename?: 'AllRequestsConnection', nodes?: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, seller?: { __typename?: 'Seller', id: string, name: string } | null, rfqOverride?: { __typename?: 'RFQ', rFQNumber: string } | null }> | null } | null };

export type GetArchivedRequestsBySellerIdQueryVariables = Exact<{
  sellerId: Scalars['String'];
}>;


export type GetArchivedRequestsBySellerIdQuery = { __typename?: 'Query', archivedRequestsBySellerId?: { __typename?: 'ArchivedRequestsBySellerIdConnection', nodes?: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, seller?: { __typename?: 'Seller', id: string } | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, rFQPublishedDate: any, requestClosureDate: any, contractDuration?: any | null, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, rfqOverride?: { __typename?: 'RFQ', rFQNumber: string } | null }> | null } | null };

export type GetNonArchivedRequestsBySellerIdQueryVariables = Exact<{
  sellerId: Scalars['String'];
}>;


export type GetNonArchivedRequestsBySellerIdQuery = { __typename?: 'Query', nonArchivedRequestsBySellerId?: { __typename?: 'NonArchivedRequestsBySellerIdConnection', nodes?: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, seller?: { __typename?: 'Seller', id: string } | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, rFQPublishedDate: any, requestClosureDate: any, contractDuration?: any | null, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null, rfqOverride?: { __typename?: 'RFQ', rFQNumber: string } | null }> | null } | null };

export type GetRequestByIdQueryVariables = Exact<{
  requestId: Scalars['String'];
}>;


export type GetRequestByIdQuery = { __typename?: 'Query', requestById?: { __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, seller?: { __typename?: 'Seller', id: string, name: string } | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null, industry?: { __typename?: 'Industry', id: string, name: string } | null } | null, overrideAttachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null } | null };

export type GetRequestsBySellerIdQueryVariables = Exact<{
  sellerId: Scalars['String'];
}>;


export type GetRequestsBySellerIdQuery = { __typename?: 'Query', requestsBySellerId?: { __typename?: 'RequestsBySellerIdConnection', nodes?: Array<{ __typename?: 'Request', requestId: string, numberOfCandidates: number, isOpen: boolean, isDeleted?: boolean | null, noSubmission: boolean, numberSupplied: number, status?: RequestStatus | null, seller?: { __typename?: 'Seller', id: string } | null, rfq?: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, rFQPublishedDate: any, requestClosureDate: any, contractDuration?: any | null, country: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, buyer?: { __typename?: 'Buyer', id: string, name: string } | null } | null }> | null } | null };

export type CloseRfqMutationVariables = Exact<{
  closeRfqNumber: Scalars['String'];
}>;


export type CloseRfqMutation = { __typename?: 'Mutation', closeRfq: { __typename?: 'RFQ', rFQNumber: string } };

export type CreateRfqMutationVariables = Exact<{
  rfq: CreateRfqInput;
}>;


export type CreateRfqMutation = { __typename?: 'Mutation', createRfq: { __typename?: 'CreateRfqOutput', rFQNumber: string, title: string, status?: boolean | null, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, industryId: string, chargeType: ChargeType, attachments: Array<{ __typename?: 'Attachment', objectKey: string, fileName: string, url?: string | null }> } };

export type DeleteRfqByNumberMutationVariables = Exact<{
  deleteRfqNumber: Scalars['String'];
}>;


export type DeleteRfqByNumberMutation = { __typename?: 'Mutation', deleteRfqByNumber: boolean };

export type UpdateRfqMutationVariables = Exact<{
  input: UpdateRfqInput;
}>;


export type UpdateRfqMutation = { __typename?: 'Mutation', updateRfq: { __typename?: 'UpdateRfqOutput', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, industryId: string, chargeType: ChargeType, attachments: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string }> } };

export type GetArchivedRfqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetArchivedRfqsQuery = { __typename?: 'Query', archivedRfqs?: { __typename?: 'ArchivedRfqsConnection', nodes?: Array<{ __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, industryId: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null, industry?: { __typename?: 'Industry', id: string, name: string } | null }> | null } | null };

export type GetNonArchivedRfqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonArchivedRfqsQuery = { __typename?: 'Query', nonArchivedRfqs?: { __typename?: 'NonArchivedRfqsConnection', nodes?: Array<{ __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, industryId: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null, industry?: { __typename?: 'Industry', id: string, name: string } | null }> | null } | null };

export type GetRfqByNumberQueryVariables = Exact<{
  rfqNumber: Scalars['String'];
}>;


export type GetRfqByNumberQuery = { __typename?: 'Query', rfqByNumber: { __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, industryId: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null, industry?: { __typename?: 'Industry', id: string, name: string } | null } };

export type GetAllRfqsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllRfqsQuery = { __typename?: 'Query', rfqs?: { __typename?: 'RfqsConnection', nodes?: Array<{ __typename?: 'RFQ', rFQNumber: string, title: string, status?: boolean | null, residency: Array<Residencies>, rFQPublishedDate: any, requestClosureDate: any, estimatedStartDate: any, contractDuration?: any | null, extensionTerms: string, workingArrangement: WorkingArrangement, location: Array<string>, security: SecurityClearance, maxSubmissions: number, maxCharge?: number | null, supportingInformation: string, country: string, industryId: string, chargeType: ChargeType, activityType: ActivityType, currencyCode: string, attachments?: Array<{ __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null> | null, buyer?: { __typename?: 'Buyer', id: string, name: string } | null, industry?: { __typename?: 'Industry', id: string, name: string } | null }> | null } | null };

export type CreateSellerMutationVariables = Exact<{
  createSellerInput: CreateSellerInput;
}>;


export type CreateSellerMutation = { __typename?: 'Mutation', createSeller: { __typename?: 'CreateSellerOutput', id: string, name: string, logo?: { __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null } };

export type DeleteSellerMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteSellerMutation = { __typename?: 'Mutation', deleteSellerById: boolean };

export type UpdateSellerMutationVariables = Exact<{
  UpdateSellerInput: UpdateSellerInput;
}>;


export type UpdateSellerMutation = { __typename?: 'Mutation', updateSeller: { __typename?: 'UpdateSellerOutput', id: string, name: string, logo?: { __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null } };

export type GetCurrentSellerQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentSellerQuery = { __typename?: 'Query', currentSeller: { __typename?: 'Seller', id: string, name: string, logo?: { __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null } };

export type GetSellersByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type GetSellersByNameQuery = { __typename?: 'Query', sellersByName?: { __typename?: 'Seller', id: string, name: string } | null };

export type GetSellersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSellersQuery = { __typename?: 'Query', sellers?: { __typename?: 'SellersConnection', nodes?: Array<{ __typename?: 'Seller', id: string, name: string, userList?: Array<{ __typename?: 'User', id: string, userStatus?: UserStatus | null, email: string, firstName: string, lastName: string, fullName: string, role: UserRole, enabled: boolean } | null> | null }> | null } | null };

export type GetSellersByIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSellersByIdQuery = { __typename?: 'Query', sellersById?: { __typename?: 'Seller', id: string, name: string, logo?: { __typename?: 'Attachment', url?: string | null, objectKey: string, fileName: string } | null } | null };

export type CreateUserMutationVariables = Exact<{
  user: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, fullName: string, role: UserRole, enabled: boolean, seller?: { __typename?: 'Seller', name: string, id: string } | null } };

export type DeleteUserMutationVariables = Exact<{
  deleteUserId: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUserById: boolean };

export type DisableUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type DisableUserMutation = { __typename?: 'Mutation', disableUserById: boolean };

export type EnableUserMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type EnableUserMutation = { __typename?: 'Mutation', enableUserById: boolean };

export type UpdateUserMutationVariables = Exact<{
  UpdateUserInput: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, fullName: string, role: UserRole, enabled: boolean, seller?: { __typename?: 'Seller', id: string, name: string } | null } };

export type GetUserByIdQueryVariables = Exact<{
  getUserId: Scalars['String'];
}>;


export type GetUserByIdQuery = { __typename?: 'Query', userById: { __typename?: 'User', id: string, email: string, firstName: string, lastName: string, fullName: string, role: UserRole, enabled: boolean, seller?: { __typename?: 'Seller', id: string, name: string } | null } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', users?: { __typename?: 'UsersConnection', nodes?: Array<{ __typename?: 'User', id: string, email: string, firstName: string, lastName: string, fullName: string, role: UserRole, enabled: boolean, userStatus?: UserStatus | null, seller?: { __typename?: 'Seller', name: string, id: string } | null }> | null } | null };


export const GetCurrentUserDocument = gql`
    query GetCurrentUser {
  currentUser {
    id
    email
    userStatus
    firstName
    lastName
    seller {
      id
      name
    }
  }
}
    `;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const CreateBuyerDocument = gql`
    mutation CreateBuyer($createBuyerInput: CreateBuyerInput!) {
  createBuyer(input: $createBuyerInput) {
    id
    name
  }
}
    `;
export type CreateBuyerMutationFn = Apollo.MutationFunction<CreateBuyerMutation, CreateBuyerMutationVariables>;

/**
 * __useCreateBuyerMutation__
 *
 * To run a mutation, you first call `useCreateBuyerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBuyerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBuyerMutation, { data, loading, error }] = useCreateBuyerMutation({
 *   variables: {
 *      createBuyerInput: // value for 'createBuyerInput'
 *   },
 * });
 */
export function useCreateBuyerMutation(baseOptions?: Apollo.MutationHookOptions<CreateBuyerMutation, CreateBuyerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBuyerMutation, CreateBuyerMutationVariables>(CreateBuyerDocument, options);
      }
export type CreateBuyerMutationHookResult = ReturnType<typeof useCreateBuyerMutation>;
export type CreateBuyerMutationResult = Apollo.MutationResult<CreateBuyerMutation>;
export type CreateBuyerMutationOptions = Apollo.BaseMutationOptions<CreateBuyerMutation, CreateBuyerMutationVariables>;
export const DeleteBuyerByIdDocument = gql`
    mutation DeleteBuyerById($id: String!) {
  deleteBuyerById(id: $id)
}
    `;
export type DeleteBuyerByIdMutationFn = Apollo.MutationFunction<DeleteBuyerByIdMutation, DeleteBuyerByIdMutationVariables>;

/**
 * __useDeleteBuyerByIdMutation__
 *
 * To run a mutation, you first call `useDeleteBuyerByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBuyerByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBuyerByIdMutation, { data, loading, error }] = useDeleteBuyerByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteBuyerByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteBuyerByIdMutation, DeleteBuyerByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteBuyerByIdMutation, DeleteBuyerByIdMutationVariables>(DeleteBuyerByIdDocument, options);
      }
export type DeleteBuyerByIdMutationHookResult = ReturnType<typeof useDeleteBuyerByIdMutation>;
export type DeleteBuyerByIdMutationResult = Apollo.MutationResult<DeleteBuyerByIdMutation>;
export type DeleteBuyerByIdMutationOptions = Apollo.BaseMutationOptions<DeleteBuyerByIdMutation, DeleteBuyerByIdMutationVariables>;
export const GetAllBuyersDocument = gql`
    query GetAllBuyers {
  buyers {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllBuyersQuery__
 *
 * To run a query within a React component, call `useGetAllBuyersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllBuyersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllBuyersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllBuyersQuery(baseOptions?: Apollo.QueryHookOptions<GetAllBuyersQuery, GetAllBuyersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllBuyersQuery, GetAllBuyersQueryVariables>(GetAllBuyersDocument, options);
      }
export function useGetAllBuyersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllBuyersQuery, GetAllBuyersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllBuyersQuery, GetAllBuyersQueryVariables>(GetAllBuyersDocument, options);
        }
export type GetAllBuyersQueryHookResult = ReturnType<typeof useGetAllBuyersQuery>;
export type GetAllBuyersLazyQueryHookResult = ReturnType<typeof useGetAllBuyersLazyQuery>;
export type GetAllBuyersQueryResult = Apollo.QueryResult<GetAllBuyersQuery, GetAllBuyersQueryVariables>;
export const GetBuyerByIdDocument = gql`
    query GetBuyerById($id: String!) {
  buyerById(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetBuyerByIdQuery__
 *
 * To run a query within a React component, call `useGetBuyerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBuyerByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBuyerByIdQuery, GetBuyerByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuyerByIdQuery, GetBuyerByIdQueryVariables>(GetBuyerByIdDocument, options);
      }
export function useGetBuyerByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuyerByIdQuery, GetBuyerByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuyerByIdQuery, GetBuyerByIdQueryVariables>(GetBuyerByIdDocument, options);
        }
export type GetBuyerByIdQueryHookResult = ReturnType<typeof useGetBuyerByIdQuery>;
export type GetBuyerByIdLazyQueryHookResult = ReturnType<typeof useGetBuyerByIdLazyQuery>;
export type GetBuyerByIdQueryResult = Apollo.QueryResult<GetBuyerByIdQuery, GetBuyerByIdQueryVariables>;
export const GetBuyerbyNameDocument = gql`
    query GetBuyerbyName($name: String!) {
  buyerByName(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useGetBuyerbyNameQuery__
 *
 * To run a query within a React component, call `useGetBuyerbyNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBuyerbyNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBuyerbyNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetBuyerbyNameQuery(baseOptions: Apollo.QueryHookOptions<GetBuyerbyNameQuery, GetBuyerbyNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBuyerbyNameQuery, GetBuyerbyNameQueryVariables>(GetBuyerbyNameDocument, options);
      }
export function useGetBuyerbyNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBuyerbyNameQuery, GetBuyerbyNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBuyerbyNameQuery, GetBuyerbyNameQueryVariables>(GetBuyerbyNameDocument, options);
        }
export type GetBuyerbyNameQueryHookResult = ReturnType<typeof useGetBuyerbyNameQuery>;
export type GetBuyerbyNameLazyQueryHookResult = ReturnType<typeof useGetBuyerbyNameLazyQuery>;
export type GetBuyerbyNameQueryResult = Apollo.QueryResult<GetBuyerbyNameQuery, GetBuyerbyNameQueryVariables>;
export const EnsureRequestChatIsInitialisedDocument = gql`
    mutation EnsureRequestChatIsInitialised($requestId: String!) {
  ensureRequestChatIsInitialised(requestId: $requestId)
}
    `;
export type EnsureRequestChatIsInitialisedMutationFn = Apollo.MutationFunction<EnsureRequestChatIsInitialisedMutation, EnsureRequestChatIsInitialisedMutationVariables>;

/**
 * __useEnsureRequestChatIsInitialisedMutation__
 *
 * To run a mutation, you first call `useEnsureRequestChatIsInitialisedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnsureRequestChatIsInitialisedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ensureRequestChatIsInitialisedMutation, { data, loading, error }] = useEnsureRequestChatIsInitialisedMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useEnsureRequestChatIsInitialisedMutation(baseOptions?: Apollo.MutationHookOptions<EnsureRequestChatIsInitialisedMutation, EnsureRequestChatIsInitialisedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnsureRequestChatIsInitialisedMutation, EnsureRequestChatIsInitialisedMutationVariables>(EnsureRequestChatIsInitialisedDocument, options);
      }
export type EnsureRequestChatIsInitialisedMutationHookResult = ReturnType<typeof useEnsureRequestChatIsInitialisedMutation>;
export type EnsureRequestChatIsInitialisedMutationResult = Apollo.MutationResult<EnsureRequestChatIsInitialisedMutation>;
export type EnsureRequestChatIsInitialisedMutationOptions = Apollo.BaseMutationOptions<EnsureRequestChatIsInitialisedMutation, EnsureRequestChatIsInitialisedMutationVariables>;
export const EnsureSellerChatIsInitialisedDocument = gql`
    mutation EnsureSellerChatIsInitialised($sellerId: String) {
  ensureSellerChatIsInitialised(sellerId: $sellerId)
}
    `;
export type EnsureSellerChatIsInitialisedMutationFn = Apollo.MutationFunction<EnsureSellerChatIsInitialisedMutation, EnsureSellerChatIsInitialisedMutationVariables>;

/**
 * __useEnsureSellerChatIsInitialisedMutation__
 *
 * To run a mutation, you first call `useEnsureSellerChatIsInitialisedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnsureSellerChatIsInitialisedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ensureSellerChatIsInitialisedMutation, { data, loading, error }] = useEnsureSellerChatIsInitialisedMutation({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useEnsureSellerChatIsInitialisedMutation(baseOptions?: Apollo.MutationHookOptions<EnsureSellerChatIsInitialisedMutation, EnsureSellerChatIsInitialisedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnsureSellerChatIsInitialisedMutation, EnsureSellerChatIsInitialisedMutationVariables>(EnsureSellerChatIsInitialisedDocument, options);
      }
export type EnsureSellerChatIsInitialisedMutationHookResult = ReturnType<typeof useEnsureSellerChatIsInitialisedMutation>;
export type EnsureSellerChatIsInitialisedMutationResult = Apollo.MutationResult<EnsureSellerChatIsInitialisedMutation>;
export type EnsureSellerChatIsInitialisedMutationOptions = Apollo.BaseMutationOptions<EnsureSellerChatIsInitialisedMutation, EnsureSellerChatIsInitialisedMutationVariables>;
export const CreateIndustryDocument = gql`
    mutation createIndustry($industry: CreateIndustryInput!) {
  createIndustry(input: $industry) {
    id
    name
  }
}
    `;
export type CreateIndustryMutationFn = Apollo.MutationFunction<CreateIndustryMutation, CreateIndustryMutationVariables>;

/**
 * __useCreateIndustryMutation__
 *
 * To run a mutation, you first call `useCreateIndustryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIndustryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIndustryMutation, { data, loading, error }] = useCreateIndustryMutation({
 *   variables: {
 *      industry: // value for 'industry'
 *   },
 * });
 */
export function useCreateIndustryMutation(baseOptions?: Apollo.MutationHookOptions<CreateIndustryMutation, CreateIndustryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIndustryMutation, CreateIndustryMutationVariables>(CreateIndustryDocument, options);
      }
export type CreateIndustryMutationHookResult = ReturnType<typeof useCreateIndustryMutation>;
export type CreateIndustryMutationResult = Apollo.MutationResult<CreateIndustryMutation>;
export type CreateIndustryMutationOptions = Apollo.BaseMutationOptions<CreateIndustryMutation, CreateIndustryMutationVariables>;
export const DeleteIndustryByIdDocument = gql`
    mutation DeleteIndustryById($id: String!) {
  deleteIndustryById(id: $id)
}
    `;
export type DeleteIndustryByIdMutationFn = Apollo.MutationFunction<DeleteIndustryByIdMutation, DeleteIndustryByIdMutationVariables>;

/**
 * __useDeleteIndustryByIdMutation__
 *
 * To run a mutation, you first call `useDeleteIndustryByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIndustryByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIndustryByIdMutation, { data, loading, error }] = useDeleteIndustryByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIndustryByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIndustryByIdMutation, DeleteIndustryByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIndustryByIdMutation, DeleteIndustryByIdMutationVariables>(DeleteIndustryByIdDocument, options);
      }
export type DeleteIndustryByIdMutationHookResult = ReturnType<typeof useDeleteIndustryByIdMutation>;
export type DeleteIndustryByIdMutationResult = Apollo.MutationResult<DeleteIndustryByIdMutation>;
export type DeleteIndustryByIdMutationOptions = Apollo.BaseMutationOptions<DeleteIndustryByIdMutation, DeleteIndustryByIdMutationVariables>;
export const GetAllIndustriesDocument = gql`
    query GetAllIndustries {
  industries {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetAllIndustriesQuery__
 *
 * To run a query within a React component, call `useGetAllIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllIndustriesQuery, GetAllIndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllIndustriesQuery, GetAllIndustriesQueryVariables>(GetAllIndustriesDocument, options);
      }
export function useGetAllIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllIndustriesQuery, GetAllIndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllIndustriesQuery, GetAllIndustriesQueryVariables>(GetAllIndustriesDocument, options);
        }
export type GetAllIndustriesQueryHookResult = ReturnType<typeof useGetAllIndustriesQuery>;
export type GetAllIndustriesLazyQueryHookResult = ReturnType<typeof useGetAllIndustriesLazyQuery>;
export type GetAllIndustriesQueryResult = Apollo.QueryResult<GetAllIndustriesQuery, GetAllIndustriesQueryVariables>;
export const GetCitiesByCountryCodeDocument = gql`
    query getCitiesByCountryCode($countryCode: String!) {
  citiesByCountryCode(countryCode: $countryCode)
}
    `;

/**
 * __useGetCitiesByCountryCodeQuery__
 *
 * To run a query within a React component, call `useGetCitiesByCountryCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesByCountryCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesByCountryCodeQuery({
 *   variables: {
 *      countryCode: // value for 'countryCode'
 *   },
 * });
 */
export function useGetCitiesByCountryCodeQuery(baseOptions: Apollo.QueryHookOptions<GetCitiesByCountryCodeQuery, GetCitiesByCountryCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesByCountryCodeQuery, GetCitiesByCountryCodeQueryVariables>(GetCitiesByCountryCodeDocument, options);
      }
export function useGetCitiesByCountryCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesByCountryCodeQuery, GetCitiesByCountryCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesByCountryCodeQuery, GetCitiesByCountryCodeQueryVariables>(GetCitiesByCountryCodeDocument, options);
        }
export type GetCitiesByCountryCodeQueryHookResult = ReturnType<typeof useGetCitiesByCountryCodeQuery>;
export type GetCitiesByCountryCodeLazyQueryHookResult = ReturnType<typeof useGetCitiesByCountryCodeLazyQuery>;
export type GetCitiesByCountryCodeQueryResult = Apollo.QueryResult<GetCitiesByCountryCodeQuery, GetCitiesByCountryCodeQueryVariables>;
export const CreateMultiRequestSubmissionsDocument = gql`
    mutation CreateMultiRequestSubmissions($rs: CreateMultiRequestSubmissionInput!) {
  createMultiRequestSubmissions(input: $rs) {
    requestSubmissionId
    sellerId
    requestId
    name
    location
    security
    residency
    availability
    chargeRate
    attachments {
      url
      objectKey
      fileName
    }
    status
    interviewTime
  }
}
    `;
export type CreateMultiRequestSubmissionsMutationFn = Apollo.MutationFunction<CreateMultiRequestSubmissionsMutation, CreateMultiRequestSubmissionsMutationVariables>;

/**
 * __useCreateMultiRequestSubmissionsMutation__
 *
 * To run a mutation, you first call `useCreateMultiRequestSubmissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultiRequestSubmissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultiRequestSubmissionsMutation, { data, loading, error }] = useCreateMultiRequestSubmissionsMutation({
 *   variables: {
 *      rs: // value for 'rs'
 *   },
 * });
 */
export function useCreateMultiRequestSubmissionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultiRequestSubmissionsMutation, CreateMultiRequestSubmissionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultiRequestSubmissionsMutation, CreateMultiRequestSubmissionsMutationVariables>(CreateMultiRequestSubmissionsDocument, options);
      }
export type CreateMultiRequestSubmissionsMutationHookResult = ReturnType<typeof useCreateMultiRequestSubmissionsMutation>;
export type CreateMultiRequestSubmissionsMutationResult = Apollo.MutationResult<CreateMultiRequestSubmissionsMutation>;
export type CreateMultiRequestSubmissionsMutationOptions = Apollo.BaseMutationOptions<CreateMultiRequestSubmissionsMutation, CreateMultiRequestSubmissionsMutationVariables>;
export const DeleteRequestSubmissionByIdDocument = gql`
    mutation DeleteRequestSubmissionById($requestSubmissionId: String!) {
  deleteRequestSubmissionById(requestSubmissionId: $requestSubmissionId)
}
    `;
export type DeleteRequestSubmissionByIdMutationFn = Apollo.MutationFunction<DeleteRequestSubmissionByIdMutation, DeleteRequestSubmissionByIdMutationVariables>;

/**
 * __useDeleteRequestSubmissionByIdMutation__
 *
 * To run a mutation, you first call `useDeleteRequestSubmissionByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestSubmissionByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestSubmissionByIdMutation, { data, loading, error }] = useDeleteRequestSubmissionByIdMutation({
 *   variables: {
 *      requestSubmissionId: // value for 'requestSubmissionId'
 *   },
 * });
 */
export function useDeleteRequestSubmissionByIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequestSubmissionByIdMutation, DeleteRequestSubmissionByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequestSubmissionByIdMutation, DeleteRequestSubmissionByIdMutationVariables>(DeleteRequestSubmissionByIdDocument, options);
      }
export type DeleteRequestSubmissionByIdMutationHookResult = ReturnType<typeof useDeleteRequestSubmissionByIdMutation>;
export type DeleteRequestSubmissionByIdMutationResult = Apollo.MutationResult<DeleteRequestSubmissionByIdMutation>;
export type DeleteRequestSubmissionByIdMutationOptions = Apollo.BaseMutationOptions<DeleteRequestSubmissionByIdMutation, DeleteRequestSubmissionByIdMutationVariables>;
export const SellerUpdateRequestSubmissionDocument = gql`
    mutation sellerUpdateRequestSubmission($input: SellerUpdateRequestSubmissionInput!) {
  sellerUpdateRequestSubmission(input: $input) {
    requestSubmissionId
    name
    location
    residency
    security
    availability
    chargeRate
    attachments {
      url
      objectKey
      fileName
    }
  }
}
    `;
export type SellerUpdateRequestSubmissionMutationFn = Apollo.MutationFunction<SellerUpdateRequestSubmissionMutation, SellerUpdateRequestSubmissionMutationVariables>;

/**
 * __useSellerUpdateRequestSubmissionMutation__
 *
 * To run a mutation, you first call `useSellerUpdateRequestSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSellerUpdateRequestSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sellerUpdateRequestSubmissionMutation, { data, loading, error }] = useSellerUpdateRequestSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSellerUpdateRequestSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<SellerUpdateRequestSubmissionMutation, SellerUpdateRequestSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SellerUpdateRequestSubmissionMutation, SellerUpdateRequestSubmissionMutationVariables>(SellerUpdateRequestSubmissionDocument, options);
      }
export type SellerUpdateRequestSubmissionMutationHookResult = ReturnType<typeof useSellerUpdateRequestSubmissionMutation>;
export type SellerUpdateRequestSubmissionMutationResult = Apollo.MutationResult<SellerUpdateRequestSubmissionMutation>;
export type SellerUpdateRequestSubmissionMutationOptions = Apollo.BaseMutationOptions<SellerUpdateRequestSubmissionMutation, SellerUpdateRequestSubmissionMutationVariables>;
export const UpdateRequestSubmissionDocument = gql`
    mutation updateRequestSubmission($input: UpdateRequestSubmissionInput!) {
  updateRequestSubmission(input: $input) {
    status
    interviewTime
  }
}
    `;
export type UpdateRequestSubmissionMutationFn = Apollo.MutationFunction<UpdateRequestSubmissionMutation, UpdateRequestSubmissionMutationVariables>;

/**
 * __useUpdateRequestSubmissionMutation__
 *
 * To run a mutation, you first call `useUpdateRequestSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRequestSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRequestSubmissionMutation, { data, loading, error }] = useUpdateRequestSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRequestSubmissionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRequestSubmissionMutation, UpdateRequestSubmissionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRequestSubmissionMutation, UpdateRequestSubmissionMutationVariables>(UpdateRequestSubmissionDocument, options);
      }
export type UpdateRequestSubmissionMutationHookResult = ReturnType<typeof useUpdateRequestSubmissionMutation>;
export type UpdateRequestSubmissionMutationResult = Apollo.MutationResult<UpdateRequestSubmissionMutation>;
export type UpdateRequestSubmissionMutationOptions = Apollo.BaseMutationOptions<UpdateRequestSubmissionMutation, UpdateRequestSubmissionMutationVariables>;
export const GetAllRequestSubmissionsDocument = gql`
    query getAllRequestSubmissions {
  requestSubmissions {
    nodes {
      requestSubmissionId
      sellerId
      request {
        requestId
        rfq {
          rFQNumber
          title
          status
          buyer {
            id
            name
          }
          rFQPublishedDate
          requestClosureDate
        }
        numberOfCandidates
        isOpen
        isDeleted
        seller {
          id
          name
        }
      }
      name
      location
      residency
      security
      availability
      chargeRate
      status
      interviewTime
      interviewDetails
    }
  }
}
    `;

/**
 * __useGetAllRequestSubmissionsQuery__
 *
 * To run a query within a React component, call `useGetAllRequestSubmissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRequestSubmissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRequestSubmissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRequestSubmissionsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRequestSubmissionsQuery, GetAllRequestSubmissionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRequestSubmissionsQuery, GetAllRequestSubmissionsQueryVariables>(GetAllRequestSubmissionsDocument, options);
      }
export function useGetAllRequestSubmissionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRequestSubmissionsQuery, GetAllRequestSubmissionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRequestSubmissionsQuery, GetAllRequestSubmissionsQueryVariables>(GetAllRequestSubmissionsDocument, options);
        }
export type GetAllRequestSubmissionsQueryHookResult = ReturnType<typeof useGetAllRequestSubmissionsQuery>;
export type GetAllRequestSubmissionsLazyQueryHookResult = ReturnType<typeof useGetAllRequestSubmissionsLazyQuery>;
export type GetAllRequestSubmissionsQueryResult = Apollo.QueryResult<GetAllRequestSubmissionsQuery, GetAllRequestSubmissionsQueryVariables>;
export const GetRequestSubmissionsByIdDocument = gql`
    query GetRequestSubmissionsById($requestSubmissionId: String!) {
  requestSubmissionById(requestSubmissionId: $requestSubmissionId) {
    requestSubmissionId
    sellerId
    request {
      requestId
      seller {
        id
        name
      }
      rfq {
        title
        rFQNumber
        chargeType
        currencyCode
        country
      }
    }
    name
    location
    security
    residency
    availability
    chargeRate
    attachments {
      url
      objectKey
      fileName
    }
    status
    interviewTime
    interviewDetails
  }
}
    `;

/**
 * __useGetRequestSubmissionsByIdQuery__
 *
 * To run a query within a React component, call `useGetRequestSubmissionsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestSubmissionsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestSubmissionsByIdQuery({
 *   variables: {
 *      requestSubmissionId: // value for 'requestSubmissionId'
 *   },
 * });
 */
export function useGetRequestSubmissionsByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRequestSubmissionsByIdQuery, GetRequestSubmissionsByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestSubmissionsByIdQuery, GetRequestSubmissionsByIdQueryVariables>(GetRequestSubmissionsByIdDocument, options);
      }
export function useGetRequestSubmissionsByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestSubmissionsByIdQuery, GetRequestSubmissionsByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestSubmissionsByIdQuery, GetRequestSubmissionsByIdQueryVariables>(GetRequestSubmissionsByIdDocument, options);
        }
export type GetRequestSubmissionsByIdQueryHookResult = ReturnType<typeof useGetRequestSubmissionsByIdQuery>;
export type GetRequestSubmissionsByIdLazyQueryHookResult = ReturnType<typeof useGetRequestSubmissionsByIdLazyQuery>;
export type GetRequestSubmissionsByIdQueryResult = Apollo.QueryResult<GetRequestSubmissionsByIdQuery, GetRequestSubmissionsByIdQueryVariables>;
export const GetRequestSubmissionsBySellerAndRequestDocument = gql`
    query GetRequestSubmissionsBySellerAndRequest($sellerId: String!, $requestId: String!) {
  requestSubmissionsBySellerAndRequest(sellerId: $sellerId, requestId: $requestId) {
    nodes {
      requestSubmissionId
      request {
        requestId
        isOpen
      }
      name
      location
      security
      residency
      availability
      chargeRate
      status
      interviewTime
      interviewDetails
    }
  }
}
    `;

/**
 * __useGetRequestSubmissionsBySellerAndRequestQuery__
 *
 * To run a query within a React component, call `useGetRequestSubmissionsBySellerAndRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestSubmissionsBySellerAndRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestSubmissionsBySellerAndRequestQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetRequestSubmissionsBySellerAndRequestQuery(baseOptions: Apollo.QueryHookOptions<GetRequestSubmissionsBySellerAndRequestQuery, GetRequestSubmissionsBySellerAndRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestSubmissionsBySellerAndRequestQuery, GetRequestSubmissionsBySellerAndRequestQueryVariables>(GetRequestSubmissionsBySellerAndRequestDocument, options);
      }
export function useGetRequestSubmissionsBySellerAndRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestSubmissionsBySellerAndRequestQuery, GetRequestSubmissionsBySellerAndRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestSubmissionsBySellerAndRequestQuery, GetRequestSubmissionsBySellerAndRequestQueryVariables>(GetRequestSubmissionsBySellerAndRequestDocument, options);
        }
export type GetRequestSubmissionsBySellerAndRequestQueryHookResult = ReturnType<typeof useGetRequestSubmissionsBySellerAndRequestQuery>;
export type GetRequestSubmissionsBySellerAndRequestLazyQueryHookResult = ReturnType<typeof useGetRequestSubmissionsBySellerAndRequestLazyQuery>;
export type GetRequestSubmissionsBySellerAndRequestQueryResult = Apollo.QueryResult<GetRequestSubmissionsBySellerAndRequestQuery, GetRequestSubmissionsBySellerAndRequestQueryVariables>;
export const GetRequestSubmissionsBySellerIdDocument = gql`
    query GetRequestSubmissionsBySellerId($sellerId: String!) {
  requestSubmissionsBySeller(sellerId: $sellerId) {
    nodes {
      requestSubmissionId
      request {
        requestId
        rfq {
          title
        }
      }
      name
      location
      security
      residency
      availability
      chargeRate
      status
      interviewTime
      interviewDetails
    }
  }
}
    `;

/**
 * __useGetRequestSubmissionsBySellerIdQuery__
 *
 * To run a query within a React component, call `useGetRequestSubmissionsBySellerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestSubmissionsBySellerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestSubmissionsBySellerIdQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetRequestSubmissionsBySellerIdQuery(baseOptions: Apollo.QueryHookOptions<GetRequestSubmissionsBySellerIdQuery, GetRequestSubmissionsBySellerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestSubmissionsBySellerIdQuery, GetRequestSubmissionsBySellerIdQueryVariables>(GetRequestSubmissionsBySellerIdDocument, options);
      }
export function useGetRequestSubmissionsBySellerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestSubmissionsBySellerIdQuery, GetRequestSubmissionsBySellerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestSubmissionsBySellerIdQuery, GetRequestSubmissionsBySellerIdQueryVariables>(GetRequestSubmissionsBySellerIdDocument, options);
        }
export type GetRequestSubmissionsBySellerIdQueryHookResult = ReturnType<typeof useGetRequestSubmissionsBySellerIdQuery>;
export type GetRequestSubmissionsBySellerIdLazyQueryHookResult = ReturnType<typeof useGetRequestSubmissionsBySellerIdLazyQuery>;
export type GetRequestSubmissionsBySellerIdQueryResult = Apollo.QueryResult<GetRequestSubmissionsBySellerIdQuery, GetRequestSubmissionsBySellerIdQueryVariables>;
export const CloseRequestDocument = gql`
    mutation closeRequest($closeRequestId: String!) {
  closeRequest(requestId: $closeRequestId) {
    requestId
  }
}
    `;
export type CloseRequestMutationFn = Apollo.MutationFunction<CloseRequestMutation, CloseRequestMutationVariables>;

/**
 * __useCloseRequestMutation__
 *
 * To run a mutation, you first call `useCloseRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeRequestMutation, { data, loading, error }] = useCloseRequestMutation({
 *   variables: {
 *      closeRequestId: // value for 'closeRequestId'
 *   },
 * });
 */
export function useCloseRequestMutation(baseOptions?: Apollo.MutationHookOptions<CloseRequestMutation, CloseRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseRequestMutation, CloseRequestMutationVariables>(CloseRequestDocument, options);
      }
export type CloseRequestMutationHookResult = ReturnType<typeof useCloseRequestMutation>;
export type CloseRequestMutationResult = Apollo.MutationResult<CloseRequestMutation>;
export type CloseRequestMutationOptions = Apollo.BaseMutationOptions<CloseRequestMutation, CloseRequestMutationVariables>;
export const CreateMultiRequestDocument = gql`
    mutation CreateMultiRequest($requests: CreateMultiRequestInput!) {
  createMultiRequests(input: $requests) {
    requestId
    rfq {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      chargeType
    }
    seller {
      id
      name
    }
    numberOfCandidates
    isOpen
    isDeleted
  }
}
    `;
export type CreateMultiRequestMutationFn = Apollo.MutationFunction<CreateMultiRequestMutation, CreateMultiRequestMutationVariables>;

/**
 * __useCreateMultiRequestMutation__
 *
 * To run a mutation, you first call `useCreateMultiRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultiRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultiRequestMutation, { data, loading, error }] = useCreateMultiRequestMutation({
 *   variables: {
 *      requests: // value for 'requests'
 *   },
 * });
 */
export function useCreateMultiRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultiRequestMutation, CreateMultiRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultiRequestMutation, CreateMultiRequestMutationVariables>(CreateMultiRequestDocument, options);
      }
export type CreateMultiRequestMutationHookResult = ReturnType<typeof useCreateMultiRequestMutation>;
export type CreateMultiRequestMutationResult = Apollo.MutationResult<CreateMultiRequestMutation>;
export type CreateMultiRequestMutationOptions = Apollo.BaseMutationOptions<CreateMultiRequestMutation, CreateMultiRequestMutationVariables>;
export const CreateRequestDocument = gql`
    mutation CreateRequest($request: CreateRequestInput!) {
  createRequest(input: $request) {
    requestId
    rfq {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      chargeType
    }
    seller {
      id
      name
    }
    numberOfCandidates
    isOpen
    isDeleted
  }
}
    `;
export type CreateRequestMutationFn = Apollo.MutationFunction<CreateRequestMutation, CreateRequestMutationVariables>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      request: // value for 'request'
 *   },
 * });
 */
export function useCreateRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(CreateRequestDocument, options);
      }
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = Apollo.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const DeleteRequestDocument = gql`
    mutation DeleteRequest($id: String!) {
  deleteRequestById(id: $id)
}
    `;
export type DeleteRequestMutationFn = Apollo.MutationFunction<DeleteRequestMutation, DeleteRequestMutationVariables>;

/**
 * __useDeleteRequestMutation__
 *
 * To run a mutation, you first call `useDeleteRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRequestMutation, { data, loading, error }] = useDeleteRequestMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRequestMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRequestMutation, DeleteRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRequestMutation, DeleteRequestMutationVariables>(DeleteRequestDocument, options);
      }
export type DeleteRequestMutationHookResult = ReturnType<typeof useDeleteRequestMutation>;
export type DeleteRequestMutationResult = Apollo.MutationResult<DeleteRequestMutation>;
export type DeleteRequestMutationOptions = Apollo.BaseMutationOptions<DeleteRequestMutation, DeleteRequestMutationVariables>;
export const DeleteRfqOverrideByRequestIdDocument = gql`
    mutation deleteRfqOverrideByRequestId($requestId: String!) {
  deleteRfqOverrideByRequestId(requestId: $requestId) {
    requestId
    rfq {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      chargeType
    }
    seller {
      id
      name
    }
    numberOfCandidates
    isOpen
    isDeleted
    noSubmission
    numberSupplied
    status
  }
}
    `;
export type DeleteRfqOverrideByRequestIdMutationFn = Apollo.MutationFunction<DeleteRfqOverrideByRequestIdMutation, DeleteRfqOverrideByRequestIdMutationVariables>;

/**
 * __useDeleteRfqOverrideByRequestIdMutation__
 *
 * To run a mutation, you first call `useDeleteRfqOverrideByRequestIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqOverrideByRequestIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqOverrideByRequestIdMutation, { data, loading, error }] = useDeleteRfqOverrideByRequestIdMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useDeleteRfqOverrideByRequestIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRfqOverrideByRequestIdMutation, DeleteRfqOverrideByRequestIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRfqOverrideByRequestIdMutation, DeleteRfqOverrideByRequestIdMutationVariables>(DeleteRfqOverrideByRequestIdDocument, options);
      }
export type DeleteRfqOverrideByRequestIdMutationHookResult = ReturnType<typeof useDeleteRfqOverrideByRequestIdMutation>;
export type DeleteRfqOverrideByRequestIdMutationResult = Apollo.MutationResult<DeleteRfqOverrideByRequestIdMutation>;
export type DeleteRfqOverrideByRequestIdMutationOptions = Apollo.BaseMutationOptions<DeleteRfqOverrideByRequestIdMutation, DeleteRfqOverrideByRequestIdMutationVariables>;
export const SetNoSubmissionByRequestIdDocument = gql`
    mutation setNoSubmissionByRequestId($requestId: String!, $noSubmissionValue: Boolean!) {
  setNoSubmissionByRequestId(
    requestId: $requestId
    noSubmissionValue: $noSubmissionValue
  )
}
    `;
export type SetNoSubmissionByRequestIdMutationFn = Apollo.MutationFunction<SetNoSubmissionByRequestIdMutation, SetNoSubmissionByRequestIdMutationVariables>;

/**
 * __useSetNoSubmissionByRequestIdMutation__
 *
 * To run a mutation, you first call `useSetNoSubmissionByRequestIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetNoSubmissionByRequestIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setNoSubmissionByRequestIdMutation, { data, loading, error }] = useSetNoSubmissionByRequestIdMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      noSubmissionValue: // value for 'noSubmissionValue'
 *   },
 * });
 */
export function useSetNoSubmissionByRequestIdMutation(baseOptions?: Apollo.MutationHookOptions<SetNoSubmissionByRequestIdMutation, SetNoSubmissionByRequestIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetNoSubmissionByRequestIdMutation, SetNoSubmissionByRequestIdMutationVariables>(SetNoSubmissionByRequestIdDocument, options);
      }
export type SetNoSubmissionByRequestIdMutationHookResult = ReturnType<typeof useSetNoSubmissionByRequestIdMutation>;
export type SetNoSubmissionByRequestIdMutationResult = Apollo.MutationResult<SetNoSubmissionByRequestIdMutation>;
export type SetNoSubmissionByRequestIdMutationOptions = Apollo.BaseMutationOptions<SetNoSubmissionByRequestIdMutation, SetNoSubmissionByRequestIdMutationVariables>;
export const SetRfqOverrideByRequestIdDocument = gql`
    mutation setRfqOverrideByRequestId($requestId: String!, $rfqInput: UpdateRfqInput!) {
  setRfqOverrideByRequestId(requestId: $requestId, rfqInput: $rfqInput) {
    requestId
    attachments {
      url
      objectKey
      fileName
    }
  }
}
    `;
export type SetRfqOverrideByRequestIdMutationFn = Apollo.MutationFunction<SetRfqOverrideByRequestIdMutation, SetRfqOverrideByRequestIdMutationVariables>;

/**
 * __useSetRfqOverrideByRequestIdMutation__
 *
 * To run a mutation, you first call `useSetRfqOverrideByRequestIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRfqOverrideByRequestIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRfqOverrideByRequestIdMutation, { data, loading, error }] = useSetRfqOverrideByRequestIdMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      rfqInput: // value for 'rfqInput'
 *   },
 * });
 */
export function useSetRfqOverrideByRequestIdMutation(baseOptions?: Apollo.MutationHookOptions<SetRfqOverrideByRequestIdMutation, SetRfqOverrideByRequestIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRfqOverrideByRequestIdMutation, SetRfqOverrideByRequestIdMutationVariables>(SetRfqOverrideByRequestIdDocument, options);
      }
export type SetRfqOverrideByRequestIdMutationHookResult = ReturnType<typeof useSetRfqOverrideByRequestIdMutation>;
export type SetRfqOverrideByRequestIdMutationResult = Apollo.MutationResult<SetRfqOverrideByRequestIdMutation>;
export type SetRfqOverrideByRequestIdMutationOptions = Apollo.BaseMutationOptions<SetRfqOverrideByRequestIdMutation, SetRfqOverrideByRequestIdMutationVariables>;
export const GetAllArchivedRequestsDocument = gql`
    query getAllArchivedRequests {
  allArchivedRequests {
    nodes {
      requestId
      rfq {
        rFQNumber
        title
        status
        residency
        attachments {
          url
          objectKey
          fileName
        }
        buyer {
          id
          name
        }
        rFQPublishedDate
        requestClosureDate
        estimatedStartDate
        contractDuration
        extensionTerms
        workingArrangement
        location
        security
        maxSubmissions
        maxCharge
        supportingInformation
        country
        chargeType
        activityType
        currencyCode
      }
      seller {
        id
        name
      }
      numberOfCandidates
      isOpen
      isDeleted
      noSubmission
      numberSupplied
      status
      rfqOverride {
        rFQNumber
      }
      overrideAttachments {
        url
        objectKey
        fileName
      }
    }
  }
}
    `;

/**
 * __useGetAllArchivedRequestsQuery__
 *
 * To run a query within a React component, call `useGetAllArchivedRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllArchivedRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllArchivedRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllArchivedRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllArchivedRequestsQuery, GetAllArchivedRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllArchivedRequestsQuery, GetAllArchivedRequestsQueryVariables>(GetAllArchivedRequestsDocument, options);
      }
export function useGetAllArchivedRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllArchivedRequestsQuery, GetAllArchivedRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllArchivedRequestsQuery, GetAllArchivedRequestsQueryVariables>(GetAllArchivedRequestsDocument, options);
        }
export type GetAllArchivedRequestsQueryHookResult = ReturnType<typeof useGetAllArchivedRequestsQuery>;
export type GetAllArchivedRequestsLazyQueryHookResult = ReturnType<typeof useGetAllArchivedRequestsLazyQuery>;
export type GetAllArchivedRequestsQueryResult = Apollo.QueryResult<GetAllArchivedRequestsQuery, GetAllArchivedRequestsQueryVariables>;
export const GetAllNonArchivedRequestsDocument = gql`
    query getAllNonArchivedRequests {
  allNonArchivedRequests {
    nodes {
      requestId
      rfq {
        rFQNumber
        title
        status
        residency
        attachments {
          url
          objectKey
          fileName
        }
        buyer {
          id
          name
        }
        rFQPublishedDate
        requestClosureDate
        estimatedStartDate
        contractDuration
        extensionTerms
        workingArrangement
        location
        security
        maxSubmissions
        maxCharge
        supportingInformation
        country
        chargeType
        activityType
        currencyCode
      }
      seller {
        id
        name
      }
      numberOfCandidates
      isOpen
      isDeleted
      noSubmission
      numberSupplied
      status
      rfqOverride {
        rFQNumber
      }
      overrideAttachments {
        url
        objectKey
        fileName
      }
    }
  }
}
    `;

/**
 * __useGetAllNonArchivedRequestsQuery__
 *
 * To run a query within a React component, call `useGetAllNonArchivedRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllNonArchivedRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllNonArchivedRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllNonArchivedRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllNonArchivedRequestsQuery, GetAllNonArchivedRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllNonArchivedRequestsQuery, GetAllNonArchivedRequestsQueryVariables>(GetAllNonArchivedRequestsDocument, options);
      }
export function useGetAllNonArchivedRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllNonArchivedRequestsQuery, GetAllNonArchivedRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllNonArchivedRequestsQuery, GetAllNonArchivedRequestsQueryVariables>(GetAllNonArchivedRequestsDocument, options);
        }
export type GetAllNonArchivedRequestsQueryHookResult = ReturnType<typeof useGetAllNonArchivedRequestsQuery>;
export type GetAllNonArchivedRequestsLazyQueryHookResult = ReturnType<typeof useGetAllNonArchivedRequestsLazyQuery>;
export type GetAllNonArchivedRequestsQueryResult = Apollo.QueryResult<GetAllNonArchivedRequestsQuery, GetAllNonArchivedRequestsQueryVariables>;
export const GetAllRequestsDocument = gql`
    query getAllRequests {
  allRequests {
    nodes {
      requestId
      rfq {
        rFQNumber
        title
        status
        residency
        attachments {
          url
          objectKey
          fileName
        }
        buyer {
          id
          name
        }
        rFQPublishedDate
        requestClosureDate
        estimatedStartDate
        contractDuration
        extensionTerms
        workingArrangement
        location
        security
        maxSubmissions
        maxCharge
        supportingInformation
        country
        chargeType
        activityType
        currencyCode
      }
      seller {
        id
        name
      }
      numberOfCandidates
      isOpen
      isDeleted
      noSubmission
      numberSupplied
      status
      rfqOverride {
        rFQNumber
      }
    }
  }
}
    `;

/**
 * __useGetAllRequestsQuery__
 *
 * To run a query within a React component, call `useGetAllRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRequestsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRequestsQuery, GetAllRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRequestsQuery, GetAllRequestsQueryVariables>(GetAllRequestsDocument, options);
      }
export function useGetAllRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRequestsQuery, GetAllRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRequestsQuery, GetAllRequestsQueryVariables>(GetAllRequestsDocument, options);
        }
export type GetAllRequestsQueryHookResult = ReturnType<typeof useGetAllRequestsQuery>;
export type GetAllRequestsLazyQueryHookResult = ReturnType<typeof useGetAllRequestsLazyQuery>;
export type GetAllRequestsQueryResult = Apollo.QueryResult<GetAllRequestsQuery, GetAllRequestsQueryVariables>;
export const GetArchivedRequestsBySellerIdDocument = gql`
    query GetArchivedRequestsBySellerId($sellerId: String!) {
  archivedRequestsBySellerId(sellerId: $sellerId) {
    nodes {
      requestId
      seller {
        id
      }
      rfq {
        rFQNumber
        title
        status
        buyer {
          id
          name
        }
        rFQPublishedDate
        requestClosureDate
        contractDuration
        country
        chargeType
        activityType
        currencyCode
      }
      numberOfCandidates
      isOpen
      isDeleted
      noSubmission
      numberSupplied
      status
      rfqOverride {
        rFQNumber
      }
    }
  }
}
    `;

/**
 * __useGetArchivedRequestsBySellerIdQuery__
 *
 * To run a query within a React component, call `useGetArchivedRequestsBySellerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedRequestsBySellerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedRequestsBySellerIdQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetArchivedRequestsBySellerIdQuery(baseOptions: Apollo.QueryHookOptions<GetArchivedRequestsBySellerIdQuery, GetArchivedRequestsBySellerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchivedRequestsBySellerIdQuery, GetArchivedRequestsBySellerIdQueryVariables>(GetArchivedRequestsBySellerIdDocument, options);
      }
export function useGetArchivedRequestsBySellerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedRequestsBySellerIdQuery, GetArchivedRequestsBySellerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchivedRequestsBySellerIdQuery, GetArchivedRequestsBySellerIdQueryVariables>(GetArchivedRequestsBySellerIdDocument, options);
        }
export type GetArchivedRequestsBySellerIdQueryHookResult = ReturnType<typeof useGetArchivedRequestsBySellerIdQuery>;
export type GetArchivedRequestsBySellerIdLazyQueryHookResult = ReturnType<typeof useGetArchivedRequestsBySellerIdLazyQuery>;
export type GetArchivedRequestsBySellerIdQueryResult = Apollo.QueryResult<GetArchivedRequestsBySellerIdQuery, GetArchivedRequestsBySellerIdQueryVariables>;
export const GetNonArchivedRequestsBySellerIdDocument = gql`
    query GetNonArchivedRequestsBySellerId($sellerId: String!) {
  nonArchivedRequestsBySellerId(sellerId: $sellerId) {
    nodes {
      requestId
      seller {
        id
      }
      rfq {
        rFQNumber
        title
        status
        buyer {
          id
          name
        }
        rFQPublishedDate
        requestClosureDate
        contractDuration
        country
        chargeType
        activityType
        currencyCode
      }
      numberOfCandidates
      isOpen
      isDeleted
      noSubmission
      numberSupplied
      status
      rfqOverride {
        rFQNumber
      }
    }
  }
}
    `;

/**
 * __useGetNonArchivedRequestsBySellerIdQuery__
 *
 * To run a query within a React component, call `useGetNonArchivedRequestsBySellerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonArchivedRequestsBySellerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonArchivedRequestsBySellerIdQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetNonArchivedRequestsBySellerIdQuery(baseOptions: Apollo.QueryHookOptions<GetNonArchivedRequestsBySellerIdQuery, GetNonArchivedRequestsBySellerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonArchivedRequestsBySellerIdQuery, GetNonArchivedRequestsBySellerIdQueryVariables>(GetNonArchivedRequestsBySellerIdDocument, options);
      }
export function useGetNonArchivedRequestsBySellerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonArchivedRequestsBySellerIdQuery, GetNonArchivedRequestsBySellerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonArchivedRequestsBySellerIdQuery, GetNonArchivedRequestsBySellerIdQueryVariables>(GetNonArchivedRequestsBySellerIdDocument, options);
        }
export type GetNonArchivedRequestsBySellerIdQueryHookResult = ReturnType<typeof useGetNonArchivedRequestsBySellerIdQuery>;
export type GetNonArchivedRequestsBySellerIdLazyQueryHookResult = ReturnType<typeof useGetNonArchivedRequestsBySellerIdLazyQuery>;
export type GetNonArchivedRequestsBySellerIdQueryResult = Apollo.QueryResult<GetNonArchivedRequestsBySellerIdQuery, GetNonArchivedRequestsBySellerIdQueryVariables>;
export const GetRequestByIdDocument = gql`
    query GetRequestById($requestId: String!) {
  requestById(id: $requestId) {
    requestId
    seller {
      id
      name
    }
    rfq {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      industry {
        id
        name
      }
      chargeType
      activityType
      currencyCode
    }
    numberOfCandidates
    isOpen
    isDeleted
    noSubmission
    overrideAttachments {
      url
      objectKey
      fileName
    }
  }
}
    `;

/**
 * __useGetRequestByIdQuery__
 *
 * To run a query within a React component, call `useGetRequestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestByIdQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useGetRequestByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRequestByIdQuery, GetRequestByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestByIdQuery, GetRequestByIdQueryVariables>(GetRequestByIdDocument, options);
      }
export function useGetRequestByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestByIdQuery, GetRequestByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestByIdQuery, GetRequestByIdQueryVariables>(GetRequestByIdDocument, options);
        }
export type GetRequestByIdQueryHookResult = ReturnType<typeof useGetRequestByIdQuery>;
export type GetRequestByIdLazyQueryHookResult = ReturnType<typeof useGetRequestByIdLazyQuery>;
export type GetRequestByIdQueryResult = Apollo.QueryResult<GetRequestByIdQuery, GetRequestByIdQueryVariables>;
export const GetRequestsBySellerIdDocument = gql`
    query GetRequestsBySellerId($sellerId: String!) {
  requestsBySellerId(sellerId: $sellerId) {
    nodes {
      requestId
      seller {
        id
      }
      rfq {
        rFQNumber
        title
        status
        buyer {
          id
          name
        }
        rFQPublishedDate
        requestClosureDate
        contractDuration
        country
        chargeType
        activityType
        currencyCode
      }
      numberOfCandidates
      isOpen
      isDeleted
      noSubmission
      numberSupplied
      status
    }
  }
}
    `;

/**
 * __useGetRequestsBySellerIdQuery__
 *
 * To run a query within a React component, call `useGetRequestsBySellerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRequestsBySellerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRequestsBySellerIdQuery({
 *   variables: {
 *      sellerId: // value for 'sellerId'
 *   },
 * });
 */
export function useGetRequestsBySellerIdQuery(baseOptions: Apollo.QueryHookOptions<GetRequestsBySellerIdQuery, GetRequestsBySellerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRequestsBySellerIdQuery, GetRequestsBySellerIdQueryVariables>(GetRequestsBySellerIdDocument, options);
      }
export function useGetRequestsBySellerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRequestsBySellerIdQuery, GetRequestsBySellerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRequestsBySellerIdQuery, GetRequestsBySellerIdQueryVariables>(GetRequestsBySellerIdDocument, options);
        }
export type GetRequestsBySellerIdQueryHookResult = ReturnType<typeof useGetRequestsBySellerIdQuery>;
export type GetRequestsBySellerIdLazyQueryHookResult = ReturnType<typeof useGetRequestsBySellerIdLazyQuery>;
export type GetRequestsBySellerIdQueryResult = Apollo.QueryResult<GetRequestsBySellerIdQuery, GetRequestsBySellerIdQueryVariables>;
export const CloseRfqDocument = gql`
    mutation closeRfq($closeRfqNumber: String!) {
  closeRfq(rfqNumber: $closeRfqNumber) {
    rFQNumber
  }
}
    `;
export type CloseRfqMutationFn = Apollo.MutationFunction<CloseRfqMutation, CloseRfqMutationVariables>;

/**
 * __useCloseRfqMutation__
 *
 * To run a mutation, you first call `useCloseRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeRfqMutation, { data, loading, error }] = useCloseRfqMutation({
 *   variables: {
 *      closeRfqNumber: // value for 'closeRfqNumber'
 *   },
 * });
 */
export function useCloseRfqMutation(baseOptions?: Apollo.MutationHookOptions<CloseRfqMutation, CloseRfqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseRfqMutation, CloseRfqMutationVariables>(CloseRfqDocument, options);
      }
export type CloseRfqMutationHookResult = ReturnType<typeof useCloseRfqMutation>;
export type CloseRfqMutationResult = Apollo.MutationResult<CloseRfqMutation>;
export type CloseRfqMutationOptions = Apollo.BaseMutationOptions<CloseRfqMutation, CloseRfqMutationVariables>;
export const CreateRfqDocument = gql`
    mutation CreateRfq($rfq: CreateRfqInput!) {
  createRfq(input: $rfq) {
    rFQNumber
    title
    status
    rFQPublishedDate
    requestClosureDate
    estimatedStartDate
    contractDuration
    extensionTerms
    workingArrangement
    location
    security
    maxSubmissions
    maxCharge
    supportingInformation
    attachments {
      objectKey
      fileName
      url
    }
    country
    industryId
    chargeType
  }
}
    `;
export type CreateRfqMutationFn = Apollo.MutationFunction<CreateRfqMutation, CreateRfqMutationVariables>;

/**
 * __useCreateRfqMutation__
 *
 * To run a mutation, you first call `useCreateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRfqMutation, { data, loading, error }] = useCreateRfqMutation({
 *   variables: {
 *      rfq: // value for 'rfq'
 *   },
 * });
 */
export function useCreateRfqMutation(baseOptions?: Apollo.MutationHookOptions<CreateRfqMutation, CreateRfqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRfqMutation, CreateRfqMutationVariables>(CreateRfqDocument, options);
      }
export type CreateRfqMutationHookResult = ReturnType<typeof useCreateRfqMutation>;
export type CreateRfqMutationResult = Apollo.MutationResult<CreateRfqMutation>;
export type CreateRfqMutationOptions = Apollo.BaseMutationOptions<CreateRfqMutation, CreateRfqMutationVariables>;
export const DeleteRfqByNumberDocument = gql`
    mutation deleteRfqByNumber($deleteRfqNumber: String!) {
  deleteRfqByNumber(rfqNumber: $deleteRfqNumber)
}
    `;
export type DeleteRfqByNumberMutationFn = Apollo.MutationFunction<DeleteRfqByNumberMutation, DeleteRfqByNumberMutationVariables>;

/**
 * __useDeleteRfqByNumberMutation__
 *
 * To run a mutation, you first call `useDeleteRfqByNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRfqByNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRfqByNumberMutation, { data, loading, error }] = useDeleteRfqByNumberMutation({
 *   variables: {
 *      deleteRfqNumber: // value for 'deleteRfqNumber'
 *   },
 * });
 */
export function useDeleteRfqByNumberMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRfqByNumberMutation, DeleteRfqByNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRfqByNumberMutation, DeleteRfqByNumberMutationVariables>(DeleteRfqByNumberDocument, options);
      }
export type DeleteRfqByNumberMutationHookResult = ReturnType<typeof useDeleteRfqByNumberMutation>;
export type DeleteRfqByNumberMutationResult = Apollo.MutationResult<DeleteRfqByNumberMutation>;
export type DeleteRfqByNumberMutationOptions = Apollo.BaseMutationOptions<DeleteRfqByNumberMutation, DeleteRfqByNumberMutationVariables>;
export const UpdateRfqDocument = gql`
    mutation updateRfq($input: UpdateRfqInput!) {
  updateRfq(input: $input) {
    rFQNumber
    title
    status
    residency
    attachments {
      url
      objectKey
      fileName
    }
    rFQPublishedDate
    requestClosureDate
    estimatedStartDate
    contractDuration
    extensionTerms
    workingArrangement
    location
    security
    maxSubmissions
    maxCharge
    supportingInformation
    country
    industryId
    chargeType
  }
}
    `;
export type UpdateRfqMutationFn = Apollo.MutationFunction<UpdateRfqMutation, UpdateRfqMutationVariables>;

/**
 * __useUpdateRfqMutation__
 *
 * To run a mutation, you first call `useUpdateRfqMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRfqMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRfqMutation, { data, loading, error }] = useUpdateRfqMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRfqMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRfqMutation, UpdateRfqMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRfqMutation, UpdateRfqMutationVariables>(UpdateRfqDocument, options);
      }
export type UpdateRfqMutationHookResult = ReturnType<typeof useUpdateRfqMutation>;
export type UpdateRfqMutationResult = Apollo.MutationResult<UpdateRfqMutation>;
export type UpdateRfqMutationOptions = Apollo.BaseMutationOptions<UpdateRfqMutation, UpdateRfqMutationVariables>;
export const GetArchivedRfqsDocument = gql`
    query getArchivedRfqs {
  archivedRfqs {
    nodes {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      industryId
      industry {
        id
        name
      }
      chargeType
      activityType
      currencyCode
    }
  }
}
    `;

/**
 * __useGetArchivedRfqsQuery__
 *
 * To run a query within a React component, call `useGetArchivedRfqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetArchivedRfqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetArchivedRfqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetArchivedRfqsQuery(baseOptions?: Apollo.QueryHookOptions<GetArchivedRfqsQuery, GetArchivedRfqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetArchivedRfqsQuery, GetArchivedRfqsQueryVariables>(GetArchivedRfqsDocument, options);
      }
export function useGetArchivedRfqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetArchivedRfqsQuery, GetArchivedRfqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetArchivedRfqsQuery, GetArchivedRfqsQueryVariables>(GetArchivedRfqsDocument, options);
        }
export type GetArchivedRfqsQueryHookResult = ReturnType<typeof useGetArchivedRfqsQuery>;
export type GetArchivedRfqsLazyQueryHookResult = ReturnType<typeof useGetArchivedRfqsLazyQuery>;
export type GetArchivedRfqsQueryResult = Apollo.QueryResult<GetArchivedRfqsQuery, GetArchivedRfqsQueryVariables>;
export const GetNonArchivedRfqsDocument = gql`
    query getNonArchivedRfqs {
  nonArchivedRfqs {
    nodes {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      industryId
      industry {
        id
        name
      }
      chargeType
      activityType
      currencyCode
    }
  }
}
    `;

/**
 * __useGetNonArchivedRfqsQuery__
 *
 * To run a query within a React component, call `useGetNonArchivedRfqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonArchivedRfqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonArchivedRfqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNonArchivedRfqsQuery(baseOptions?: Apollo.QueryHookOptions<GetNonArchivedRfqsQuery, GetNonArchivedRfqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonArchivedRfqsQuery, GetNonArchivedRfqsQueryVariables>(GetNonArchivedRfqsDocument, options);
      }
export function useGetNonArchivedRfqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonArchivedRfqsQuery, GetNonArchivedRfqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonArchivedRfqsQuery, GetNonArchivedRfqsQueryVariables>(GetNonArchivedRfqsDocument, options);
        }
export type GetNonArchivedRfqsQueryHookResult = ReturnType<typeof useGetNonArchivedRfqsQuery>;
export type GetNonArchivedRfqsLazyQueryHookResult = ReturnType<typeof useGetNonArchivedRfqsLazyQuery>;
export type GetNonArchivedRfqsQueryResult = Apollo.QueryResult<GetNonArchivedRfqsQuery, GetNonArchivedRfqsQueryVariables>;
export const GetRfqByNumberDocument = gql`
    query GetRfqByNumber($rfqNumber: String!) {
  rfqByNumber(rfqNumber: $rfqNumber) {
    rFQNumber
    title
    status
    residency
    attachments {
      url
      objectKey
      fileName
    }
    buyer {
      id
      name
    }
    rFQPublishedDate
    requestClosureDate
    estimatedStartDate
    contractDuration
    extensionTerms
    workingArrangement
    location
    security
    maxSubmissions
    maxCharge
    supportingInformation
    country
    industryId
    industry {
      id
      name
    }
    chargeType
    activityType
    currencyCode
  }
}
    `;

/**
 * __useGetRfqByNumberQuery__
 *
 * To run a query within a React component, call `useGetRfqByNumberQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRfqByNumberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRfqByNumberQuery({
 *   variables: {
 *      rfqNumber: // value for 'rfqNumber'
 *   },
 * });
 */
export function useGetRfqByNumberQuery(baseOptions: Apollo.QueryHookOptions<GetRfqByNumberQuery, GetRfqByNumberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRfqByNumberQuery, GetRfqByNumberQueryVariables>(GetRfqByNumberDocument, options);
      }
export function useGetRfqByNumberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRfqByNumberQuery, GetRfqByNumberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRfqByNumberQuery, GetRfqByNumberQueryVariables>(GetRfqByNumberDocument, options);
        }
export type GetRfqByNumberQueryHookResult = ReturnType<typeof useGetRfqByNumberQuery>;
export type GetRfqByNumberLazyQueryHookResult = ReturnType<typeof useGetRfqByNumberLazyQuery>;
export type GetRfqByNumberQueryResult = Apollo.QueryResult<GetRfqByNumberQuery, GetRfqByNumberQueryVariables>;
export const GetAllRfqsDocument = gql`
    query getAllRfqs {
  rfqs {
    nodes {
      rFQNumber
      title
      status
      residency
      attachments {
        url
        objectKey
        fileName
      }
      buyer {
        id
        name
      }
      rFQPublishedDate
      requestClosureDate
      estimatedStartDate
      contractDuration
      extensionTerms
      workingArrangement
      location
      security
      maxSubmissions
      maxCharge
      supportingInformation
      country
      industryId
      industry {
        id
        name
      }
      chargeType
      activityType
      currencyCode
    }
  }
}
    `;

/**
 * __useGetAllRfqsQuery__
 *
 * To run a query within a React component, call `useGetAllRfqsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllRfqsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllRfqsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllRfqsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllRfqsQuery, GetAllRfqsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllRfqsQuery, GetAllRfqsQueryVariables>(GetAllRfqsDocument, options);
      }
export function useGetAllRfqsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllRfqsQuery, GetAllRfqsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllRfqsQuery, GetAllRfqsQueryVariables>(GetAllRfqsDocument, options);
        }
export type GetAllRfqsQueryHookResult = ReturnType<typeof useGetAllRfqsQuery>;
export type GetAllRfqsLazyQueryHookResult = ReturnType<typeof useGetAllRfqsLazyQuery>;
export type GetAllRfqsQueryResult = Apollo.QueryResult<GetAllRfqsQuery, GetAllRfqsQueryVariables>;
export const CreateSellerDocument = gql`
    mutation CreateSeller($createSellerInput: CreateSellerInput!) {
  createSeller(input: $createSellerInput) {
    id
    name
    logo {
      url
      objectKey
      fileName
    }
  }
}
    `;
export type CreateSellerMutationFn = Apollo.MutationFunction<CreateSellerMutation, CreateSellerMutationVariables>;

/**
 * __useCreateSellerMutation__
 *
 * To run a mutation, you first call `useCreateSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSellerMutation, { data, loading, error }] = useCreateSellerMutation({
 *   variables: {
 *      createSellerInput: // value for 'createSellerInput'
 *   },
 * });
 */
export function useCreateSellerMutation(baseOptions?: Apollo.MutationHookOptions<CreateSellerMutation, CreateSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSellerMutation, CreateSellerMutationVariables>(CreateSellerDocument, options);
      }
export type CreateSellerMutationHookResult = ReturnType<typeof useCreateSellerMutation>;
export type CreateSellerMutationResult = Apollo.MutationResult<CreateSellerMutation>;
export type CreateSellerMutationOptions = Apollo.BaseMutationOptions<CreateSellerMutation, CreateSellerMutationVariables>;
export const DeleteSellerDocument = gql`
    mutation DeleteSeller($id: String!) {
  deleteSellerById(id: $id)
}
    `;
export type DeleteSellerMutationFn = Apollo.MutationFunction<DeleteSellerMutation, DeleteSellerMutationVariables>;

/**
 * __useDeleteSellerMutation__
 *
 * To run a mutation, you first call `useDeleteSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSellerMutation, { data, loading, error }] = useDeleteSellerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSellerMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSellerMutation, DeleteSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSellerMutation, DeleteSellerMutationVariables>(DeleteSellerDocument, options);
      }
export type DeleteSellerMutationHookResult = ReturnType<typeof useDeleteSellerMutation>;
export type DeleteSellerMutationResult = Apollo.MutationResult<DeleteSellerMutation>;
export type DeleteSellerMutationOptions = Apollo.BaseMutationOptions<DeleteSellerMutation, DeleteSellerMutationVariables>;
export const UpdateSellerDocument = gql`
    mutation UpdateSeller($UpdateSellerInput: UpdateSellerInput!) {
  updateSeller(input: $UpdateSellerInput) {
    id
    name
    logo {
      url
      objectKey
      fileName
    }
  }
}
    `;
export type UpdateSellerMutationFn = Apollo.MutationFunction<UpdateSellerMutation, UpdateSellerMutationVariables>;

/**
 * __useUpdateSellerMutation__
 *
 * To run a mutation, you first call `useUpdateSellerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSellerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSellerMutation, { data, loading, error }] = useUpdateSellerMutation({
 *   variables: {
 *      UpdateSellerInput: // value for 'UpdateSellerInput'
 *   },
 * });
 */
export function useUpdateSellerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSellerMutation, UpdateSellerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSellerMutation, UpdateSellerMutationVariables>(UpdateSellerDocument, options);
      }
export type UpdateSellerMutationHookResult = ReturnType<typeof useUpdateSellerMutation>;
export type UpdateSellerMutationResult = Apollo.MutationResult<UpdateSellerMutation>;
export type UpdateSellerMutationOptions = Apollo.BaseMutationOptions<UpdateSellerMutation, UpdateSellerMutationVariables>;
export const GetCurrentSellerDocument = gql`
    query GetCurrentSeller {
  currentSeller {
    id
    name
    logo {
      url
      objectKey
      fileName
    }
  }
}
    `;

/**
 * __useGetCurrentSellerQuery__
 *
 * To run a query within a React component, call `useGetCurrentSellerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentSellerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentSellerQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentSellerQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentSellerQuery, GetCurrentSellerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentSellerQuery, GetCurrentSellerQueryVariables>(GetCurrentSellerDocument, options);
      }
export function useGetCurrentSellerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentSellerQuery, GetCurrentSellerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentSellerQuery, GetCurrentSellerQueryVariables>(GetCurrentSellerDocument, options);
        }
export type GetCurrentSellerQueryHookResult = ReturnType<typeof useGetCurrentSellerQuery>;
export type GetCurrentSellerLazyQueryHookResult = ReturnType<typeof useGetCurrentSellerLazyQuery>;
export type GetCurrentSellerQueryResult = Apollo.QueryResult<GetCurrentSellerQuery, GetCurrentSellerQueryVariables>;
export const GetSellersByNameDocument = gql`
    query GetSellersByName($name: String!) {
  sellersByName(name: $name) {
    id
    name
  }
}
    `;

/**
 * __useGetSellersByNameQuery__
 *
 * To run a query within a React component, call `useGetSellersByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellersByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellersByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetSellersByNameQuery(baseOptions: Apollo.QueryHookOptions<GetSellersByNameQuery, GetSellersByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellersByNameQuery, GetSellersByNameQueryVariables>(GetSellersByNameDocument, options);
      }
export function useGetSellersByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellersByNameQuery, GetSellersByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellersByNameQuery, GetSellersByNameQueryVariables>(GetSellersByNameDocument, options);
        }
export type GetSellersByNameQueryHookResult = ReturnType<typeof useGetSellersByNameQuery>;
export type GetSellersByNameLazyQueryHookResult = ReturnType<typeof useGetSellersByNameLazyQuery>;
export type GetSellersByNameQueryResult = Apollo.QueryResult<GetSellersByNameQuery, GetSellersByNameQueryVariables>;
export const GetSellersDocument = gql`
    query GetSellers {
  sellers {
    nodes {
      id
      name
      userList {
        id
        userStatus
        email
        firstName
        lastName
        fullName
        role
        enabled
      }
    }
  }
}
    `;

/**
 * __useGetSellersQuery__
 *
 * To run a query within a React component, call `useGetSellersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSellersQuery(baseOptions?: Apollo.QueryHookOptions<GetSellersQuery, GetSellersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellersQuery, GetSellersQueryVariables>(GetSellersDocument, options);
      }
export function useGetSellersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellersQuery, GetSellersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellersQuery, GetSellersQueryVariables>(GetSellersDocument, options);
        }
export type GetSellersQueryHookResult = ReturnType<typeof useGetSellersQuery>;
export type GetSellersLazyQueryHookResult = ReturnType<typeof useGetSellersLazyQuery>;
export type GetSellersQueryResult = Apollo.QueryResult<GetSellersQuery, GetSellersQueryVariables>;
export const GetSellersByIdDocument = gql`
    query GetSellersById($id: String!) {
  sellersById(id: $id) {
    id
    name
    logo {
      url
      objectKey
      fileName
    }
  }
}
    `;

/**
 * __useGetSellersByIdQuery__
 *
 * To run a query within a React component, call `useGetSellersByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSellersByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSellersByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSellersByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSellersByIdQuery, GetSellersByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSellersByIdQuery, GetSellersByIdQueryVariables>(GetSellersByIdDocument, options);
      }
export function useGetSellersByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSellersByIdQuery, GetSellersByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSellersByIdQuery, GetSellersByIdQueryVariables>(GetSellersByIdDocument, options);
        }
export type GetSellersByIdQueryHookResult = ReturnType<typeof useGetSellersByIdQuery>;
export type GetSellersByIdLazyQueryHookResult = ReturnType<typeof useGetSellersByIdLazyQuery>;
export type GetSellersByIdQueryResult = Apollo.QueryResult<GetSellersByIdQuery, GetSellersByIdQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($user: CreateUserInput!) {
  createUser(input: $user) {
    id
    email
    firstName
    lastName
    fullName
    role
    seller {
      name
      id
    }
    enabled
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($deleteUserId: String!) {
  deleteUserById(id: $deleteUserId)
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      deleteUserId: // value for 'deleteUserId'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DisableUserDocument = gql`
    mutation DisableUser($userId: String!) {
  disableUserById(userId: $userId)
}
    `;
export type DisableUserMutationFn = Apollo.MutationFunction<DisableUserMutation, DisableUserMutationVariables>;

/**
 * __useDisableUserMutation__
 *
 * To run a mutation, you first call `useDisableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableUserMutation, { data, loading, error }] = useDisableUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDisableUserMutation(baseOptions?: Apollo.MutationHookOptions<DisableUserMutation, DisableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DisableUserMutation, DisableUserMutationVariables>(DisableUserDocument, options);
      }
export type DisableUserMutationHookResult = ReturnType<typeof useDisableUserMutation>;
export type DisableUserMutationResult = Apollo.MutationResult<DisableUserMutation>;
export type DisableUserMutationOptions = Apollo.BaseMutationOptions<DisableUserMutation, DisableUserMutationVariables>;
export const EnableUserDocument = gql`
    mutation EnableUser($userId: String!) {
  enableUserById(userId: $userId)
}
    `;
export type EnableUserMutationFn = Apollo.MutationFunction<EnableUserMutation, EnableUserMutationVariables>;

/**
 * __useEnableUserMutation__
 *
 * To run a mutation, you first call `useEnableUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableUserMutation, { data, loading, error }] = useEnableUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useEnableUserMutation(baseOptions?: Apollo.MutationHookOptions<EnableUserMutation, EnableUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EnableUserMutation, EnableUserMutationVariables>(EnableUserDocument, options);
      }
export type EnableUserMutationHookResult = ReturnType<typeof useEnableUserMutation>;
export type EnableUserMutationResult = Apollo.MutationResult<EnableUserMutation>;
export type EnableUserMutationOptions = Apollo.BaseMutationOptions<EnableUserMutation, EnableUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($UpdateUserInput: UpdateUserInput!) {
  updateUser(input: $UpdateUserInput) {
    id
    seller {
      id
      name
    }
    email
    firstName
    lastName
    fullName
    role
    enabled
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      UpdateUserInput: // value for 'UpdateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUserByIdDocument = gql`
    query GetUserById($getUserId: String!) {
  userById(id: $getUserId) {
    id
    email
    firstName
    lastName
    fullName
    role
    enabled
    seller {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *   },
 * });
 */
export function useGetUserByIdQuery(baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
      }
export function useGetUserByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
        }
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<GetUserByIdQuery, GetUserByIdQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  users {
    nodes {
      id
      email
      firstName
      lastName
      fullName
      role
      seller {
        name
        id
      }
      enabled
      userStatus
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;