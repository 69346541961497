import { Channel } from '@chatkitty/core';
import React, { createContext, ReactNode } from 'react';
import { isFunction } from '../../shared/utils/TypeGuardUtils';
import UseChatSession, { ChatUsers } from '../hooks/UseChatSession';
import { GSMessage } from '../models/GSMessage';

export type ChatSessionContextType = {
  sendMessage: (text: string, mute?: boolean) => void;
  fetchMoreMessages: () => void;
  fetchingMore: boolean;
  messages: Array<GSMessage>;
  loading: boolean;
  error: string;
  users?: ChatUsers;
};

export type ChatSessionProviderProps = {
  children: ReactNode | ((values: ChatSessionContextType) => ReactNode);
  channel: Channel;
  sellerName?: string;
  onMessage?: (msg: GSMessage) => void;
  onMessagesLoaded?: () => void;
};

export const ChatSessionContext = createContext<ChatSessionContextType>({} as ChatSessionContextType);

export default function ChatSessionProvider({
  channel,
  children,
  onMessage,
  onMessagesLoaded,
  sellerName,
}: ChatSessionProviderProps): JSX.Element {
  const session = UseChatSession({
    channel,
    onMessageReceived: onMessage,
    onMessagesLoaded: onMessagesLoaded,
    sellerName: sellerName,
  });

  return (
    <ChatSessionContext.Provider value={session}>
      {isFunction(children) ? children(session) : children}
    </ChatSessionContext.Provider>
  );
}
