import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Channel } from '@chatkitty/core';
import { Alert, Button, message, Modal, Tabs } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Request,
  useCloseRequestMutation,
  useDeleteRequestMutation,
  useEnsureRequestChatIsInitialisedMutation,
} from '../../../graphql/generated';
import Messenger from '../../chat/components/messages/Messenger';
import { chatService } from '../../chat/context/ChatService';
import ChatSessionProvider from '../../chat/context/ChatSessionContext';
import useChatStore from '../../chat/hooks/UseChatStore';
import AdminRequestSubmissionsPage from '../../request-submission/pages/AdminRequestSubmissionsPage';
import RequestSubmissionsPage from '../../request-submission/pages/RequestSubmissionsPage';
import RichTextReader from '../../shared/components/form/RichTextReader';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import useOverrideBreadcrumbStore from '../../shared/hooks/UseOverrideBreadcrumbStore';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import { editRequestInCache, removeRequestFromCache } from '../requestUtils';
import { RequestDescription } from './RequestDescription';
import SupportingInformationToDocx from './SupportingInformationToDocx';

enum RequestTab {
  Details = 'Details',
  SupportingInformation = 'Supporting Information',
  Submissions = 'Request Submissions',
  Discussion = 'Discussion',
}

type RequestDetailsProps = {
  request: Request;
  isSeller?: boolean;
};

export default function RequestDetails({ request, isSeller }: RequestDetailsProps) {
  const setBreadcrumbs = useOverrideBreadcrumbStore((x) => x.setCrumbForPath);
  const [deleteRequest] = useDeleteRequestMutation();
  const chatLoading = useChatStore((x) => x.loading);
  const [closeRequest] = useCloseRequestMutation();
  const [ensureInitialised, { loading: channelLoading }] = useEnsureRequestChatIsInitialisedMutation();
  const navigate = useNavigate();
  const [channelError, setChannelError] = useState<string>('');
  const [channel, setChannel] = useState<Channel>();
  const messagesRef = useRef<HTMLDivElement>(null);

  const initChannel = async () => {
    try {
      await ensureInitialised({ variables: { requestId: request.requestId } });
      const channel = await chatService.getChannelByName(request.requestId);
      setChannel(channel);
    } catch (ex) {
      setChannelError('An issue occurred whilst loading the chat, please try again in 5 minutes or contact support.');
    }
  };

  useEffect(() => {
    setBreadcrumbs({ resourceRequestId: request?.rfq?.title ?? request.requestId });
  }, []);

  useEffect(() => {
    if (!chatLoading) {
      initChannel();
    }
  }, [chatLoading]);

  const confirmDeleteRequest = (requestId: string) => {
    Modal.confirm({
      title: `Delete Request`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Request? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteRequest({
          variables: {
            id: request.requestId,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the Request, please try again in 5 minutes or contact support.',
            );
          },
        });
        removeRequestFromCache(requestId);
        navigate(localRoutingConstants.requests.root);
      },
    });
  };
  const confirmCloseRequest = (requestId: string) => {
    Modal.confirm({
      title: `Close Request`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to close this Request?',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await closeRequest({
          variables: {
            closeRequestId: requestId,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying to close the Request, please check if Request is already closed or please try again in 5 minutes or contact support.',
            );
          },
          onCompleted: () => {
            message.success('Request was successfully closed.');
          },
        });
        editRequestInCache(request);
        navigate(localRoutingConstants.requests.root);
      },
    });
  };

  return (
    <>
      <div className="flex flex-row justify-end gap-2">
        {!isSeller && (
          <>
            <Button
              onClick={() => confirmCloseRequest(request.requestId)}
              type="primary"
              disabled={request.isOpen ? !request.isOpen : true}
            >
              Close Request
            </Button>
            <Button onClick={() => confirmDeleteRequest(request.requestId)} type="primary" danger>
              Delete
            </Button>
          </>
        )}
      </div>
      <Tabs defaultActiveKey="1">
        <Tabs.TabPane tab={RequestTab.Details} key={RequestTab.Details}>
          <RequestDescription request={request} />
        </Tabs.TabPane>
        {request.rfq?.supportingInformation && (
          <Tabs.TabPane tab={RequestTab.SupportingInformation} key={RequestTab.SupportingInformation}>
            <SupportingInformationToDocx request={request} />
            <RichTextReader content={request.rfq?.supportingInformation} />
          </Tabs.TabPane>
        )}

        <Tabs.TabPane tab={RequestTab.Submissions} key={RequestTab.Submissions}>
          {isSeller ? <RequestSubmissionsPage request={request} /> : <AdminRequestSubmissionsPage request={request} />}
        </Tabs.TabPane>

        <Tabs.TabPane tab={RequestTab.Discussion} key={RequestTab.Discussion}>
          {channelError && (
            <Alert
              showIcon
              type="error"
              message="Chat could not be loaded due to an unknown error, please try again in 5 minutes or contact support."
            />
          )}
          {channelLoading && <SpinLoader />}
          {!channelError && !channelLoading && channel && (
            <ChatSessionProvider
              channel={channel}
              onMessagesLoaded={() => {
                messagesRef.current?.scrollTo({ top: messagesRef.current.scrollHeight, behavior: 'smooth' });
              }}
            >
              <div className="flex flex-row justify-center w-full">
                <Messenger
                  mute={true} //No notifications for messages.
                  innerRef={messagesRef}
                  className="h-96 max-w-4xl w-full"
                  recipientUserName={isSeller ? 'GSAdmin' : request.seller?.id ?? 'Unknown'}
                  recipientDisplayName={isSeller ? 'Go Sourcing' : request.seller?.name ?? 'Unknown'}
                />
              </div>
            </ChatSessionProvider>
          )}
        </Tabs.TabPane>
      </Tabs>
    </>
  );
}
