import { UserPresence } from '@chatkitty/core';
import _ from 'lodash';
import { MutableRefObject, ReactNode, useContext, useEffect, useRef } from 'react';
import SpinLoader from '../../../shared/components/loader/SpinLoader';
import { ChatSessionContext } from '../../context/ChatSessionContext';
import Message from './Message';
import MessageInput from './MessageInput';
import MessengerHeader from './MessengerHeader';

type MessagesProps = {
  recipientUserName: string;
  recipientDisplayName: string;
  mute?: boolean;
  status?: UserPresence;
  innerRef?: MutableRefObject<HTMLDivElement | null>;
  className?: string;
  actionButtons?: ReactNode;
};

/**
 * This component is resonsible for rendering the UI for a chat session. Must be a child of a ChatSession component
 * otherwise it will fail to render without the correct context.
 */
export default function Messenger({
  innerRef,
  className,
  recipientDisplayName,
  recipientUserName,
  mute,
  actionButtons,
}: MessagesProps) {
  const context = useContext(ChatSessionContext);
  useEffect(() => {
    if (_.isEqual(context, {})) {
      throw new Error('Messenger must be underneath a <ChatSessionProvider/> component.');
    }
  }, []);

  const { sendMessage, messages, loading, fetchMoreMessages, fetchingMore, users } = context;
  const privateRef = useRef<HTMLDivElement | null>(null);

  const loadMoreAtTop = () => {
    const { scrollTop } = privateRef.current!;
    if (scrollTop === 0) {
      fetchMoreMessages();
    }
  };

  if (loading) {
    return (
      <div className="flex-grow flex flex-col">
        <SpinLoader />
      </div>
    );
  }

  return (
    <div className={`flex-grow flex flex-col ${className}`}>
      <MessengerHeader
        status={users && users[recipientUserName] ? users[recipientUserName].status : undefined}
        displayName={recipientDisplayName}
        actionButtons={actionButtons}
      />
      <div
        ref={(el: HTMLDivElement) => {
          if (innerRef) {
            innerRef.current = el;
          }
          privateRef.current = el;
        }}
        className="flex-grow overflow-auto"
        onScroll={loadMoreAtTop}
      >
        <div className={`transition-all duration-700 ${fetchingMore ? 'opacity-100' : 'opacity-0'} h-8`}>
          <SpinLoader className="h-10" />
        </div>

        {messages.map((msg) => {
          return <Message key={msg.id} body={msg.body} senderName={msg.user.name} sentAgo={msg.sentAgo} />;
        })}
      </div>
      <MessageInput onTextMessageSend={(msg) => sendMessage(msg, mute)} />
    </div>
  );
}
