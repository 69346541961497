import { Image, Layout as AntLayout, Menu } from 'antd';
import MenuItem from 'antd/lib/menu/MenuItem';
import React, { useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import GoMenuLogo from '../../../../assets/images/go_menu_logo.png';
import { useGetCurrentSellerQuery } from '../../../../graphql/generated';
import SellerFloatingChatMenu from '../../../chat/components/floating-chat-menu/SellerFloatingChatMenu';
import { useCurrentUserStore } from '../../hooks/UseCurrentUserStore';
import useLayoutStore from '../../hooks/UseLayoutStore';
import { CurrentUserRole } from '../../models/user/UserRole';
import { authenticatedRoutes } from '../../routing/Routes';
import Breadcrumbs from '../breadcrumbs/Breadcrumbs';
import TopNavigation from '../TopNavigation/TopNavigation';
import './Layout.less';
import GSLogo from '../../../../assets/images/go_menu_logo.png';
const { Content, Sider } = AntLayout;

const Layout: React.FC = () => {
  const user = useCurrentUserStore((store) => store.user);
  const { marginWidth, showBreadcrumbs, paddingWidth } = useLayoutStore();
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();
  const { data } = useGetCurrentSellerQuery();

  return (
    <AntLayout style={{ minHeight: '100vh' }}>
      {user?.role === CurrentUserRole.SellerAdmin && <SellerFloatingChatMenu />}
      <Sider className="bg-white" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <Link className="logo-container cursor-pointer" to={'/'}>
          <img className="logo" src={GoMenuLogo} />
        </Link>
        <Menu
          style={{ fontSize: '16px', fontWeight: '500' }}
          className="bg-white"
          mode="inline"
          selectedKeys={Object.values(authenticatedRoutes)
            .filter((route) => location.pathname.includes(route.path))
            .map((x) => x.path)}
        >
          {Object.values(authenticatedRoutes)
            .filter((route) => {
              return route.inSidebar && route.allowedRoles.some((x) => x === user?.role);
            })
            .map((route) => {
              if (route.RenderSidebarTab) {
                const CustomSidebarTab = route.RenderSidebarTab;
                return (
                  <MenuItem className="relative" key={route.path} icon={route.icon}>
                    <Link key={route.path} to={route.path} style={{ textDecoration: 'none' }}>
                      <CustomSidebarTab key={route.path} />
                    </Link>
                  </MenuItem>
                );
              }

              return (
                <MenuItem key={route.path} icon={route.icon}>
                  <Link key={route.path} to={route.path} style={{ textDecoration: 'none' }}>
                    {route.label}
                  </Link>
                </MenuItem>
              );
            })}
        </Menu>
        {!collapsed && user?.role == CurrentUserRole.SellerAdmin && (
          <>
            <hr className="w-11/12 text-secondary" />
            <div className="grid grid-flow-row auto-rows-max gap-3">
              <div className="flex flex-row justify-center font-bold text-lg">{data?.currentSeller.name}</div>
              {data?.currentSeller.logo ? (
                <Image
                  preview={false}
                  className="flex m-auto flex-row justify-center object-contain max-h-32 max-w-[92%]"
                  src={data?.currentSeller.logo?.url as string}
                />
              ) : (
                <>
                  <div className="text-center">Contact support to place your logo below!</div>
                  <Image className="flex flex-row justify-center object-contain max-h-32" src={GSLogo} />
                </>
              )}
            </div>
            <hr className="w-11/12 text-secondary mt-4" />
          </>
        )}
        {!collapsed && (
          <a
            href="mailto: goconnectsupport@gosourcing.com.au"
            className="flex flex-row justify-center text-secondary mt-9"
          >
            Contact Support
          </a>
        )}
      </Sider>
      <AntLayout className="site-layout">
        <TopNavigation />
        <Content style={{ margin: `0px ${marginWidth}px ${marginWidth}px ${marginWidth}px` }}>
          <div className="flex flex-col h-full">
            {showBreadcrumbs && <Breadcrumbs />}
            <div className="site-layout-background" style={{ padding: paddingWidth }}>
              <Outlet />
            </div>
          </div>
        </Content>
      </AntLayout>
    </AntLayout>
  );
};

export default Layout;
