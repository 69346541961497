import { SubmissionStatus } from '../../../../graphql/generated';

export const SubmissionStatusToDisplayText: { [key in SubmissionStatus]: string } = {
  [SubmissionStatus.Wip]: 'WIP',
  [SubmissionStatus.NotSubmitted]: 'Not Submitted',
  [SubmissionStatus.Shortlisted]: 'Shortlisted',
  [SubmissionStatus.BuyerPending]: 'Buyer Pending',
  [SubmissionStatus.Interview]: 'Interview',
  [SubmissionStatus.Successful]: 'Successful',
  [SubmissionStatus.Unsuccessful]: 'Unsuccessful',
  [SubmissionStatus.Withdrawn]: 'Withdrawn',
};

const customOrder: SubmissionStatus[] = [
  SubmissionStatus.Wip,
  SubmissionStatus.NotSubmitted,
  SubmissionStatus.Shortlisted,
  SubmissionStatus.BuyerPending,
  SubmissionStatus.Interview,
  SubmissionStatus.Successful,
  SubmissionStatus.Unsuccessful,
  SubmissionStatus.Withdrawn,
];

export const RequestSubmissionStatusComboBoxItems = customOrder
  .map((val) => ({
    value: val,
    displayValue: SubmissionStatusToDisplayText[val],
  }))
  .sort((a, b) => {
    const aIndex = customOrder.indexOf(a.value);
    const bIndex = customOrder.indexOf(b.value);
    return aIndex - bIndex;
  });
