import { Channel } from '@chatkitty/core';
import { ChatUsers } from '../hooks/UseChatSession';
import { chatkitty } from '../utils/ChatConfig';

class ChatService {
  //Messages
  async sendMessage(text: string, channel: Channel, mute?: boolean) {
    const result = await chatkitty.sendMessage({
      channel: channel,
      body: text,
      properties: {
        mute: mute ?? false,
      },
    });

    if (!result.succeeded) {
      throw Error(
        'An error occurred whilst sending the message, confirm the channel is correctly formed and that chat servers are running',
      );
    }
  }

  async getMessagesPaginatorForChannel(channel: Channel) {
    const messageRequest = await chatkitty.listMessages({
      channel: channel,
    });

    if (messageRequest.succeeded) {
      return messageRequest.paginator;
    } else {
      throw Error(
        'An error occurred whilst fetching messages, confirm the channel is correctly formed and that chat servers are running',
      );
    }
  }

  //Users
  async getUsersForChannel(channel: Channel): Promise<ChatUsers> {
    const membersRequest = await chatkitty.listChannelMembers({ channel: channel });
    if (membersRequest.succeeded) {
      const chatUsers = {} as ChatUsers;
      membersRequest.paginator.items.forEach((user) => {
        chatUsers[user.name] = { displayName: user.displayName, status: user.presence };
      });
      return chatUsers;
    } else {
      throw Error(
        'An error occurred whilst fetching users, confirm the channel is correctly formed and that chat servers are running',
      );
    }
  }

  //Channels
  async getChannelByName(name: string | undefined): Promise<Channel> {
    const result = await chatkitty.listChannels({ filter: { name: name } });
    if (result.succeeded && result.paginator.items[0]) {
      return result.paginator.items[0] as Channel;
    } else {
      throw Error(
        'An error occurred whilst fetching the channel, confirm the name is correct and that chat servers are running',
      );
    }
  }

  async getChannelsForSellers(sellerIds: Array<string>): Promise<Array<Channel>> {
    //TODO: Channel list could get long after a large number of requsts exist, may need optimising in the future
    const result = await chatkitty.listChannels({ filter: { joined: true } });

    let channels: Array<Channel> = [];
    if (result.succeeded) {
      channels = channels.concat(result.paginator.items as Array<Channel>);
      return channels.filter((ch) => sellerIds.includes(ch.name));
    } else {
      throw Error(
        'An error occurred whilst fetching channels, confirm the name is correct and that chat servers are running',
      );
    }
  }
}

//We will use a singleton class for now, in the future could move to using a context provider.
export const chatService = new ChatService();
