import { Channel, ChatKittyPaginator, ChatSession, Message, UserPresence } from '@chatkitty/core';
import _, { isEqual } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useStateWithCallback } from '../../shared/hooks/UseStateWithCallback';
import { chatService } from '../context/ChatService';
import { GSMessage } from '../models/GSMessage';
import { chatkitty } from '../utils/ChatConfig';
import { processMessage } from '../utils/ChatUtils';
const ONE_MINUTE_IN_MILLISECONDS = 60000;

type UseChatSessionType = {
  sendMessage: (text: string, mute?: boolean) => void;
  fetchMoreMessages: () => void;
  fetchingMore: boolean;
  messages: Array<GSMessage>;
  loading: boolean;
  error: string;
  users?: ChatUsers;
};

interface UseChatSessionProps {
  channel: Channel;
  sellerName?: string;
  onMessageReceived?: (msg: GSMessage) => void;
  onMessagesLoaded?: () => void;
}

export type ChatUsers = {
  [username: string]: {
    displayName: string;
    status: UserPresence;
  };
};

export default function UseChatSession({
  channel,
  onMessagesLoaded,
  sellerName,
  onMessageReceived: onMessage,
}: UseChatSessionProps): UseChatSessionType {
  const paginator = useRef<ChatKittyPaginator<Message>>();
  const [users, setUsers] = useState<ChatUsers>({});
  const [chatSession, setChatSession] = useState<ChatSession>();
  const [loading, setLoading] = useState<boolean>(true);
  const [fetchingMore, setFetchingMore] = useState<boolean>(false);
  const [messages, setMessages] = useStateWithCallback<Array<GSMessage>>([]);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(() => {
      //Update sent at time for messages.
      setMessages((messages) => messages.map((msg) => processMessage(msg)));
    }, ONE_MINUTE_IN_MILLISECONDS);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    onMessagesLoaded && onMessagesLoaded();
  }, [loading]);

  useEffect(() => {
    const sessionRes = chatkitty.startChatSession({
      channel: channel,
      onMessageReceived: (msg) => onMessageReceived(processMessage(msg as Message)),
      onParticipantPresenceChanged: (user) => {
        setUsers((users) => {
          const newUsers = _.clone(users);
          newUsers[user.name] = {
            displayName: user.displayName,
            status: { online: user.presence.online, status: user.presence.status },
          };
          return newUsers;
        });
      },
    });
    if (sessionRes.succeeded) {
      setChatSession(sessionRes.session);
    } else {
      setError(
        'An unknown error has occurred whilst connecting to the server, please try again in five minutes or contact support.',
      );
    }
    return () => {
      sessionRes.session.end();
    };
  }, [channel]);

  useEffect(() => {
    if (chatSession) {
      initialiseSession();
    }
  }, [chatSession]);

  const initialiseSession = async () => {
    setLoading(true);
    try {
      //Attempt to get chat users.
      const [members, chatPaginator] = await Promise.all([
        chatService.getUsersForChannel(channel),
        chatService.getMessagesPaginatorForChannel(channel),
      ]);

      //Get messages
      paginator.current = chatPaginator as ChatKittyPaginator<Message>;
      const messages = paginator.current?.items.map((msg) => processMessage(msg, { sellerName })) as Array<GSMessage>; // Handle messages

      //Update state
      setMessages(messages.reverse());
      setUsers(members);
    } catch (ex) {
      setError(
        'An unknown error has occurred whilst connecting to the chat server, please try again in five minutes or contact support.',
      );
    }

    setLoading(false);
  };

  const fetchMoreMessages = async () => {
    if (paginator.current?.hasNextPage && !fetchingMore) {
      setFetchingMore(true);
      const next = await paginator.current.nextPage();
      paginator.current = next;
      const messages = paginator.current?.items.map((msg) => processMessage(msg)) as Array<GSMessage>; // Handle messages
      setMessages((prev) => {
        const newMessages = [...messages.reverse(), ...prev];
        return newMessages;
      });
      setFetchingMore(false);
    }
  };

  const onMessageReceived = async (message: Message) => {
    const processedMessage = processMessage(message);
    setMessages(
      (prev) => _.uniqWith([...prev, processedMessage], isEqual),
      () => {
        onMessagesLoaded && onMessagesLoaded();
      },
    );

    onMessage && onMessage(processedMessage);
  };

  const sendMessage = async (text: string, mute?: boolean) => {
    try {
      await chatService.sendMessage(text, channel, mute);
    } catch (ex) {
      setError('An unknown error has occurred whilst trying to send the message');
    }
  };

  return {
    messages,
    loading,
    sendMessage,
    error,
    fetchingMore,
    users,
    fetchMoreMessages,
  };
}
