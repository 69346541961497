import { Spin } from 'antd';

interface SpinLoaderProps {
  className?: string;
}

export default function SpinLoader({ className }: SpinLoaderProps) {
  return (
    <div className={`flex justify-center align-middle items-center h-full ${className}`}>
      <Spin size="large" />
    </div>
  );
}
