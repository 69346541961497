import { Badge } from 'antd';
import { RequestStatus } from '../../../../graphql/generated';
import { RequestStatusToDisplayText } from '../../models/request/RequestStatus';

type Props = {
  status: RequestStatus;
};

export default function RequestStatusRenderer(props: Props) {
  const statusText = RequestStatusToDisplayText[props.status];
  let statusType: 'success' | 'warning' | 'error' | 'processing' = 'success';

  switch (props.status) {
    case RequestStatus.Wip:
    case RequestStatus.BuyerPending:
      statusType = 'processing';
      break;
    case RequestStatus.Shortlisted:
    case RequestStatus.Withdrawn:
      statusType = 'warning';
      break;
    case RequestStatus.NotSubmitted:
    case RequestStatus.Unsuccessful:
    case RequestStatus.Closed:
      statusType = 'error';
      break;
    case RequestStatus.Interview:
    case RequestStatus.Successful:
    case RequestStatus.Open:
      statusType = 'success';
      break;
    case RequestStatus.OptOut:
      statusType = 'warning';
      break;
    default:
      break;
  }

  return <Badge status={statusType} text={statusText} />;
}
