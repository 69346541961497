import { Message } from '@chatkitty/core';
import _ from 'lodash';
import moment from 'moment';
import { CurrentUserRole } from '../../shared/models/user/UserRole';
import { GSMessage } from '../models/GSMessage';

export const isSentByUser = (currentRole: CurrentUserRole | undefined, senderName: string) => {
  const isSentByAdmin = senderName === 'GSAdmin';
  if (currentRole === CurrentUserRole.GSAdmin) {
    return isSentByAdmin;
  } else {
    return !isSentByAdmin;
  }
};

export const getInitialsFromName = (value: string) => {
  return value
    .split(' ')
    .map((word) => word[0])
    .splice(0, 2)
    .join('')
    .toUpperCase();
};

//Adds required metadata to chat kitty message
interface MessageProcessOptions {
  sellerName?: string;
}
export const processMessage = (message: Message, options?: MessageProcessOptions): GSMessage => {
  const processMessage = _.clone(message) as GSMessage;
  if (options?.sellerName) {
    processMessage.user.name = processMessage.user.name === 'GSAdmin' ? 'GSAdmin' : options.sellerName;
  }
  processMessage.sentAgo = moment(new Date(message.createdTime)).fromNow();
  return processMessage;
};
