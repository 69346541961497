import { Alert, Button, message } from 'antd';
import { FieldArray, Form, Formik } from 'formik';
import { Fragment, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/CreateRequestTable.less';
import {
  CreateMultiRequestInput,
  CreateRequestInput,
  GetSellersQuery,
  Request,
  Rfq,
  useCreateMultiRequestMutation,
  useGetSellersQuery,
} from '../../../graphql/generated';
import ComboBoxInput from '../../shared/components/form/ComboBoxInput';
import NumberFormInput from '../../shared/components/form/NumberFormInput';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { addRequestToCache } from '../requestUtils';
import { createRequestValidator } from '../validators/RequestValidator';
import { getApolloErrorMessage } from '../../shared/utils/ApolloErrorUtils';

type CreateRequestFormProps = {
  className: string;
  rfq: Rfq;
};

export default function CreateRequestTable({ className, rfq }: CreateRequestFormProps) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>();
  const { loading: sellerLoading, data: sellerData, error: sellerError } = useGetSellersQuery();
  const [createMultiRequests] = useCreateMultiRequestMutation();
  const initialValues = {
    requests: [] as Array<Request>,
  };
  const handleCreateRequests = async (multiRequests: CreateMultiRequestInput): Promise<Array<Request>> => {
    const response = await createMultiRequests({
      variables: {
        requests: multiRequests,
      },
    });

    return response.data?.createMultiRequests as Array<Request>;
  };

  return (
    <Formik
      validationSchema={createRequestValidator}
      initialValues={initialValues}
      onSubmit={async (values) => {
        setLoading(true);
        try {
          const multiRequests = await handleCreateRequests({
            inputs: values.requests as unknown as Array<CreateRequestInput>,
          });

          multiRequests.map((request) => addRequestToCache(request));
          message.success(`The Requests for ${rfq.rFQNumber} was successfully created !`);
          navigate(`./../..`);
        } catch (ex: any) {
          const errMessage = getApolloErrorMessage(ex);
          message.error(
            errMessage ??
              'An unknown error has occurred whilst creating the request, please try again later or contact support.',
          );
        }
        setLoading(false);
      }}
    >
      {({ submitForm, values }) => {
        if (sellerLoading) {
          return <SpinLoader />;
        } else if (sellerError) {
          return (
            <Alert
              showIcon
              type="error"
              message="An unknown error has occurred, please wait two minutes and try again or contact support."
            />
          );
        }
        return (
          <Form className={`${className} max-w-full`}>
            <table className="create-request-table">
              <thead className="ant-head-thead w-96">
                <tr>
                  <th className="ant-table-cell" scope="col">
                    Seller
                  </th>
                  <th className="ant-table-cell" scope="col">
                    Number Of Submissions
                  </th>
                  <th className="ant-table-cell" scope="col">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="ant-table-tbody w-80">
                <FieldArray name="requests">
                  {(arrayHelpers) => {
                    return (
                      <Fragment>
                        {arrayHelpers.form.values.requests.map((request: Request, idx: number) => {
                          return (
                            <VariantRow
                              key={idx}
                              index={idx}
                              sellerData={sellerData}
                              onRemove={() => arrayHelpers.remove(idx)}
                            />
                          );
                        })}
                        <tr key="edit">
                          <td colSpan={16}>
                            <div className="flex justify-center">
                              <Button
                                onClick={() => {
                                  arrayHelpers.push({
                                    rFQNumber: rfq.rFQNumber,
                                    sellerId: '',
                                    numberOfCandidates: 1,
                                    isOpen: true,
                                    isDeleted: false,
                                  });
                                }}
                                loading={sellerLoading}
                                type="primary"
                              >
                                Add a New Request
                              </Button>
                            </div>
                          </td>
                        </tr>
                      </Fragment>
                    );
                  }}
                </FieldArray>
              </tbody>
            </table>
            <div className="flex flex-row justify-end gap-2 mx-4 my-2">
              <Link to="./../..">
                <Button type="default">Cancel</Button>
              </Link>
              <Button loading={loading} type="primary" onClick={submitForm}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
interface VariantRowProps {
  index: number;
  onRemove?: () => void;
  sellerData: GetSellersQuery | undefined;
}

function VariantRow({ index, onRemove, sellerData }: VariantRowProps) {
  //rfqN
  return (
    <tr className="ant-table-measure-row" key={index}>
      <td className="w-64">
        <ComboBoxInput
          name={`requests[${index}].sellerId`}
          placeholder="Seller"
          label=""
          availableItems={sellerData?.sellers?.nodes?.map((x) => ({ displayValue: x.name, value: x.id })) ?? []}
        />
      </td>
      <td>
        <NumberFormInput name={`requests[${index}].numberOfCandidates`} placeholder="Number of Submissions" label="" />
      </td>
      <td className="align-top text-right">
        {onRemove && (
          <Button onClick={() => onRemove()} type="primary" danger>
            Remove
          </Button>
        )}
      </td>
    </tr>
  );
}
