import { useState } from 'react';
import UserGuide from '../assets/UserGuide.pdf';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
import { Button } from 'antd';

type Props = {};

export default function UserGuidePage({}: Props) {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function onDocumentLoadSuccess({ numPages }: any) {
    setNumPages(numPages);
  }

  function goToPrevPage() {
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  }

  function goToNextPage() {
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  }

  return (
    <>
      <h2>User Guide</h2>
      <div>
        <nav>
          <div className="grid grid-cols-2 gap-4 w-1/3">
            <Button className="h-12 text-2xl" type="primary" onClick={goToPrevPage}>
              Prev
            </Button>
            <Button className="h-12 text-2xl" type="primary" onClick={goToNextPage}>
              Next
            </Button>
          </div>
          <div className="grid grid-cols-1 gap-4 align-middle">
            <h2>
              Page {pageNumber} of {numPages}
            </h2>
          </div>
        </nav>
        <Document file={UserGuide} onLoadSuccess={onDocumentLoadSuccess} className="outline outline-primary">
          <Page
            pageNumber={pageNumber}
            renderAnnotationLayer={false}
            renderTextLayer={false}
            renderInteractiveForms={false}
          />
        </Document>
      </div>
    </>
  );
}
