import { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import Changelog from '../assets/CHANGELOG.md';

export default function ChangelogPage() {
  const [changelogText, setChangelogText] = useState('');
  useEffect(() => {
    fetch(Changelog)
      .then((res) => res.text())
      .then((text) => setChangelogText(text));
  });
  return (
    <>
      <ReactMarkdown rehypePlugins={[rehypeRaw]}>{changelogText}</ReactMarkdown>
    </>
  );
}
