import { Alert } from 'antd';
import { useParams } from 'react-router-dom';
import { Rfq, useGetRfqByNumberQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import RFQDetailedDescription from '../components/RFQDetailedDescription';

export default function ViewRfqPage() {
  const { rfqNumber } = useParams();

  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRfqByNumberQuery({
    variables: {
      rfqNumber: rfqNumber as string,
    },

    fetchPolicy: 'no-cache',
  });

  const rfq = data?.rfqByNumber as Rfq;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching RFQs, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2>RFQ: {rfq.rFQNumber}</h2>
      </div>
      <RFQDetailedDescription rfq={rfq} />
    </>
  );
}
