import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useDeleteSellerMutation, User } from '../../../graphql/generated';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { Seller } from '../../shared/models/sellers/Seller'; //TODO: this needs to come from generated, check before making changes.
import { removeSellerFromCache } from '../SellerUtils';

type SellersTableProps = {
  sellers: Array<Seller>;
};
export default function SellersTable({ sellers }: SellersTableProps) {
  const getColumnSearchProps = UseGetColumnSearchProps<Seller>();
  const [deleteSeller] = useDeleteSellerMutation();

  const confirmDeleteSeller = (sellerId: string, sellerName: string) => {
    Modal.confirm({
      title: `Delete Seller`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Seller? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteSeller({
          variables: {
            id: sellerId,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the Seller, please try again in 5 minutes or contact support.',
            );
          },
        });
        removeSellerFromCache(sellerId);
        message.success(`Seller: ${sellerName} was successfully deleted.`);
      },
    });
  };
  const requestColumns: ColumnsType<Seller> = useMemo(
    () => [
      {
        title: 'Seller Name',
        dataIndex: 'name',
        render: (name, id) => {
          if (name != null) {
            return <span>{id.name}</span>;
          }
          return <span>N/A</span>;
        },
        ...getColumnSearchProps('name'),
        filterSearch: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Number of Users',
        dataIndex: 'userList',
        render: (userList: Array<User>) => {
          if (userList != null) return <span>{userList.length}</span>;
          return <span>N/A</span>;
        },
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (sellerId, row) => (
          <div className="flex flex-row gap-x-2">
            <Link to={`${sellerId}`}>
              <Button type="primary">View</Button>
            </Link>
            <Link to={`${sellerId}/edit`}>
              <Button type="primary">Edit</Button>
            </Link>
            <Button onClick={() => confirmDeleteSeller(sellerId, row.name)} type="primary" danger>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      scroll={{ x: 'max-content' }}
      columns={requestColumns}
      dataSource={sellers.map((Seller) => ({ ...Seller, key: Seller.id }))}
    />
  );
}
