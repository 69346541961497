import { useEffect, useState } from 'react';
import { useGetCitiesComboBoxItems } from './UseGetCitiesComboBoxItems';
import { ComboBoxItem } from '../components/form/ComboBoxInput';

export const useCitiesState = (country: string) => {
  const [cities, setCities] = useState<ComboBoxItem[]>();

  const [citiesData] = useGetCitiesComboBoxItems(country);

  useEffect(() => {
    if (citiesData) {
      setCities(citiesData);
    }
  }, [citiesData]);

  return cities;
};
