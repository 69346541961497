import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ApolloQueryResult } from '@apollo/client';
import { Badge, Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { ActivityType, Buyer, GetAllRfqsQuery, Rfq, useDeleteRfqByNumberMutation } from '../../../graphql/generated';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { ActivityTypeToDisplayText } from '../../shared/models/rfq/ActivityType';
import { removeRfqFromCache } from '../rfqUtils';

type RFQManagementTableProps = {
  rfqs: Array<Rfq>;
  createRequest?: boolean;
  refetch?: () => Promise<ApolloQueryResult<GetAllRfqsQuery>>;
};

export default function RfqManagementTable({ rfqs, createRequest, refetch }: RFQManagementTableProps) {
  const [deleteRfq] = useDeleteRfqByNumberMutation();
  const getColumnSearchProps = UseGetColumnSearchProps<Rfq>();

  const confirmDeleteRfq = (rfqNumber: string) => {
    Modal.confirm({
      title: `Delete Rfq: ${rfqNumber}`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you wish to delete this RFQ? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteRfq({
          variables: {
            deleteRfqNumber: rfqNumber,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the RFQ, please try again in 5 minutes or contact support.',
            );
          },
          onCompleted: () => {
            message.success(`RFQ: ${rfqNumber} was successfully deleted.`);
            removeRfqFromCache(rfqNumber);
            if (refetch) {
              refetch();
            }
          },
        });
      },
    });
  };

  const rfqsColumns: ColumnsType<Rfq> = useMemo(
    () => [
      {
        title: 'Buyer',
        dataIndex: 'buyer',
        ...getColumnSearchProps('buyer.name'),
        render: (buyer: Buyer) => {
          if (buyer) {
            return <span>{buyer.name ?? 'Unassigned'}</span>;
          }
          return <span>Unassigned</span>;
        },
        sorter: (a, b) => a.buyer?.name.localeCompare(b.buyer?.name ?? '') ?? 1,
      },
      {
        title: 'RFQ Number',
        dataIndex: 'rFQNumber',
        ...getColumnSearchProps('rFQNumber'),
        sorter: (a, b) => a.rFQNumber.localeCompare(b.rFQNumber),
      },
      {
        title: 'Title',
        dataIndex: 'title',
        ...getColumnSearchProps('title'),
        sorter: (a, b) => a.title.localeCompare(b.title),
      },
      {
        title: 'Activity Type',
        dataIndex: 'activityType',
        ...getColumnSearchProps('activityType'),
        render: (activityType: ActivityType) => {
          if (activityType) {
            return <span>{ActivityTypeToDisplayText[activityType] ?? 'Unassigned'}</span>;
          }
          return <span>Unassigned</span>;
        },
        sorter: (a, b) => a.activityType.localeCompare(b.activityType),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        render: (val) => (val ? <Badge status="success" text="Open" /> : <Badge status="error" text="Closed" />),
        sorter: (a) => (a.status ? 1 : -1),
      },
      {
        title: 'Actions',
        dataIndex: 'rFQNumber',
        fixed: 'right',
        render: (rFQNumber: string, rfq) =>
          createRequest ? (
            <div className="flex flex-row gap-x-2">
              <Link to={`/request/${rFQNumber}/create`}>
                <Button type="primary">Create Request</Button>
              </Link>
            </div>
          ) : (
            <div className="flex flex-row gap-x-2">
              <Link to={`${rFQNumber}`}>
                <Button type="primary">View</Button>
              </Link>
              <Link to={`${rFQNumber}/edit`}>
                <Button type="primary" disabled={rfq.status != null ? !rfq.status : true}>
                  Edit
                </Button>
              </Link>
              <Button onClick={() => confirmDeleteRfq(rFQNumber)} type="primary" danger>
                Delete
              </Button>
            </div>
          ),
      },
    ],
    [],
  );
  return (
    <Table
      scroll={{ x: 'max-content' }}
      columns={rfqsColumns}
      dataSource={rfqs.map((rfq) => ({ ...rfq, key: rfq.rFQNumber }))}
    />
  );
}
