import RfqForm from '../components/RfqForm';

export default function CreateRfqPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <RfqForm className="max-w-3xl" />
      </div>
    </>
  );
}
