import create from 'zustand';
const DEFAULT_MARGIN_SIZE = 16;
const DEFAULT_PADDING_SIZE = 24;

interface LayoutState {
  showBreadcrumbs: boolean;
  marginWidth: number;
  paddingWidth: number;
  setShowBreadcrumbs: (showBreadcrumbs: boolean) => void;
  setMarginWidth: (setMarginWidth?: number) => void;
  setPaddingWidth: (paddingWidth?: number) => void;
  setDefaultLayout: () => void;
}

const useLayoutStore = create<LayoutState>((set) => ({
  showBreadcrumbs: true,
  marginWidth: DEFAULT_MARGIN_SIZE,
  paddingWidth: DEFAULT_PADDING_SIZE,
  setShowBreadcrumbs: (showBreadcrumbs: boolean) => {
    set((prev) => ({ ...prev, showBreadcrumbs }));
  },
  setMarginWidth: (marginWidth?: number) => {
    marginWidth = marginWidth ?? DEFAULT_MARGIN_SIZE;
    set((prev) => ({ ...prev, marginWidth }));
  },
  setPaddingWidth: (paddingWidth?: number) => {
    paddingWidth = paddingWidth ?? DEFAULT_MARGIN_SIZE;
    set((prev) => ({ ...prev, paddingWidth }));
  },
  setDefaultLayout: () => {
    set(() => ({ paddingWidth: DEFAULT_PADDING_SIZE, marginWidth: DEFAULT_MARGIN_SIZE, showBreadcrumbs: true }));
  },
}));

export default useLayoutStore;
