import { SolutionOutlined } from '@ant-design/icons';
import BuyersPage from '../../buyer/pages/BuyersPage';
import CreateBuyerPage from '../../buyer/pages/CreateBuyerPage';
import { CurrentUserRole } from '../models/user/UserRole';
import { localRoutingConstants } from './RoutingConstants';

export const buyerRoutes = {
  buyers: {
    label: 'Buyers',
    path: localRoutingConstants.buyers.root,
    icon: <SolutionOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <BuyersPage />,
  },
  createBuyer: {
    label: 'Create Buyer',
    path: localRoutingConstants.buyers.create,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <CreateBuyerPage />,
  },
};
