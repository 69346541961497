import { StyleSheet } from '@react-pdf/renderer';

export const DefaultPDFStyles = StyleSheet.create({
  page: {
    backgroundColor: '#FAFAFA',
    paddingHorizontal: 30,
    flexGrow: 1,
  },
  logo: {
    width: '45%',
    paddingBottom: 10,
  },
  title: {
    fontSize: 32,
    paddingBottom: 10,
  },
  h1: {
    paddingVertical: 5,
    fontSize: 16,
  },
  h2: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    marginVertical: 10,
  },
  tableRow: { margin: 'auto', flexDirection: 'row' },
  tableCol: { width: '50%', borderStyle: 'solid', borderWidth: 1, borderLeftWidth: 0, borderTopWidth: 0 },
  tableCell: { marginTop: 5, marginLeft: 5, fontSize: 14, textAlign: 'left' },
});
