import { Popover } from 'antd';
import { ReactNode } from 'react';

type ConfirmPopoverProps = {
  open: boolean;
  content: ReactNode;
  children: ReactNode;
};

export default function ConfirmPopover(props: ConfirmPopoverProps) {
  return (
    <Popover
      title="Remove RFQ Override?"
      trigger="click"
      open={props.open}
      className="relative"
      content={props.content}
    >
      {props.children}
    </Popover>
  );
}
