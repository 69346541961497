import { Button, Image, message } from 'antd';
import { UploadFile } from 'antd/es/upload/interface';
import { Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import {
  CreateSellerInput,
  Seller,
  UpdateSellerInput,
  useCreateSellerMutation,
  useUpdateSellerMutation,
  useGetSellersByIdQuery,
} from '../../../graphql/generated';
import MultiFileUploader from '../../shared/components/files/MultiFileUploader';
import TextFormInput from '../../shared/components/form/TextFormInput';
import useMultiFileUpload, { AttachmentInfo, GSFile, GSFiles } from '../../shared/hooks/UseMultiFileUpload';
import { getApolloErrorType } from '../../shared/utils/ApolloErrorUtils';
import { addSellerToCache, editSellerInCache } from '../SellerUtils';
import { createSellerValidator, editSellerValidator } from '../validators/SellerValidator';

type CreateOrEditSellerFormProps = {
  className: string;
  editSeller?: Seller;
};

export default function CreateOrEditSellerForm({ className, editSeller }: CreateOrEditSellerFormProps) {
  const navigate = useNavigate();
  const [createSeller, { loading: createLoading }] = useCreateSellerMutation();
  const [updateSeller, { loading: updateLoading }] = useUpdateSellerMutation();
  const { setFiles, uploadingInfo: uploading, handleMultiFileUpload } = useMultiFileUpload();
  const { data: sellerData, refetch } = useGetSellersByIdQuery({
    variables: {
      id: editSeller?.id as string,
    },
  });

  const handleCreateSeller = async (seller: CreateSellerInput): Promise<Seller> => {
    const response = await createSeller({
      variables: {
        createSellerInput: seller,
      },
    });

    const logo = response.data?.createSeller.logo;
    if (logo) {
      await handleMultiFileUpload([logo as AttachmentInfo]);
    }

    return response.data?.createSeller as Seller;
  };

  const handleUpdateSeller = async (seller: UpdateSellerInput): Promise<Seller> => {
    const response = await updateSeller({
      variables: {
        UpdateSellerInput: seller,
      },
    });

    const logo = response.data?.updateSeller.logo;
    if (logo) {
      await handleMultiFileUpload([logo as AttachmentInfo]);
    }

    return response.data?.updateSeller as Seller;
  };

  return (
    <Formik
      validationSchema={editSeller ? editSellerValidator : createSellerValidator}
      initialValues={createSellerValidator.cast(
        {
          ...editSeller,
          logo: editSeller ? editSeller.logo?.fileName : '',
        } ?? {},
      )}
      onSubmit={async (values) => {
        const logo = values.logo?.[0] ?? null;
        //If initial values are not set, then it's a create form.
        try {
          let sellerResult: Seller;
          if (editSeller) {
            sellerResult = await handleUpdateSeller({
              id: editSeller.id,
              name: values.name,
              //NOTE: The logo comes from the component as an array of logos.
              logo,
            });
            editSellerInCache(sellerResult);
          } else {
            sellerResult = await handleCreateSeller({ ...values, logo });
            addSellerToCache(sellerResult);
          }
          navigate('./..');
          message.success('Seller was successfully' + (editSeller ? ' updated' : ' created'));
          //get the newly uploaded logo
          refetch();
        } catch (ex) {
          if (getApolloErrorType(ex)) {
            message.error(`The request could not be fulfilled because Seller already exists.`);
          } else {
            message.error(`An error occurred whilst creating the Seller.`);
          }
        }
      }}
    >
      {({ submitForm }) => {
        return (
          <Form className={`${className} w-full`}>
            <TextFormInput required name="name" placeholder="Name" label="Name" />
            <div className="grid grid-cols-8 gap-1 max-h-32">
              <div className="col-span-7">
                <MultiFileUploader
                  singleFileUpload
                  fileTypes={['.png', '.jpg', '.webp']}
                  name={'logo'}
                  label={'Upload a Logo'}
                  maxSize={20000000}
                  maxCount={10}
                  initialFiles={new Array<UploadFile>().fill({
                    name: editSeller?.logo?.fileName,
                    uid: editSeller?.logo?.objectKey,
                    status: 'done',
                  } as UploadFile)}
                  onFilesChanged={(files) => {
                    const fileObj = {} as GSFiles;
                    files.forEach((file) => {
                      fileObj[file.name] = new GSFile(file);
                    });
                    setFiles(fileObj);
                  }}
                  progress={uploading.completion}
                />
              </div>
              {sellerData?.sellersById?.logo ? (
                <Image
                  className="mt-[22px] col-span-1"
                  height={92}
                  width="auto"
                  src={sellerData?.sellersById?.logo?.url as string}
                  preview={false}
                />
              ) : (
                <div className="h-92 w-auto text-center mt-[22px] p-2">Upload a logo to see a preview</div>
              )}
            </div>
            <div className="flex flex-row justify-end gap-2 mt-4">
              <Link to={'./..'}>
                <Button type="default">Cancel</Button>
              </Link>
              <Button loading={createLoading || updateLoading} type="primary" onClick={submitForm}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
