import { InputNumber } from 'antd';
import { useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import { ChargeType } from '../../../../graphql/generated';
import { ChargeTypeOptionsToAbbreviatedText } from '../../models/rfq/RfqChargeType';
import FormError from './FormError';

type Props = {
  disabled?: boolean;
  name: string;
  placeholder: string;
  label: string;
  required?: boolean;
  currency?: boolean;
  appendedElement?: ReactNode;
  defaultValue?: number;
  chargeType?: ChargeType;
};

export default function NumberFormInput(props: Props) {
  const { setFieldValue, setFieldTouched } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <div className="flex flex-col">
      <label className="font-bold whitespace-nowrap	">
        {props.label} {props.required && '*'}
      </label>
      {props.currency ? (
        <div>
          <InputNumber
            className="w-full"
            disabled={props.disabled}
            formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            onChange={async (value) => {
              await setFieldValue(props.name, value);
              await setFieldTouched(props.name, true);
            }}
            status={meta.error && meta.touched ? 'error' : ''}
            defaultValue={props.defaultValue}
            addonAfter={ChargeTypeOptionsToAbbreviatedText[props.chargeType as ChargeType]}
          />
        </div>
      ) : (
        <div>
          <InputNumber
            className="w-full"
            disabled={props.disabled}
            {...field}
            defaultValue={parseFloat(field.value)}
            onChange={(value) => {
              setFieldValue(props.name, value);
              setFieldTouched(props.name, true);
            }}
            status={meta.error && meta.touched ? 'error' : ''}
          />
        </div>
      )}
      {props.appendedElement}
      <FormError>{meta.error && meta.touched ? meta.error : ''}</FormError>
    </div>
  );
}
