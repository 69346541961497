import { SearchOutlined } from '@ant-design/icons';
import { Avatar, Input, Modal } from 'antd';
import { useState } from 'react';
import { Seller } from '../../../../graphql/generated';
import SpinLoader from '../../../shared/components/loader/SpinLoader';
import styles from './SellerSelectionModal.module.less';

type SellerSelectionModalProps = {
  sellers: Array<Seller>;
  show: boolean;
  close: () => void;
  onSelected: (selected: Seller) => Promise<void>;
  noneFoundText?: string;
  excludedIds?: Array<string>;
};

export default function SellerSelectionModal({
  sellers,
  close,
  onSelected,
  show,
  excludedIds,
  noneFoundText,
}: SellerSelectionModalProps) {
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const handleSelection = async (seller: Seller) => {
    setLoading(true);
    await onSelected(seller);
    setLoading(false);
    close();
  };

  const render = () => {
    if (loading) {
      return <SpinLoader />;
    }

    if (sellers.length === 0) {
      return <div className="font-bold">{noneFoundText ?? 'No Sellers were found matching the criteria.'}</div>;
    }

    return (
      <div>
        {sellers
          .filter((seller) => {
            if (excludedIds && excludedIds?.includes(seller.id)) {
              return false;
            }
            return seller.name?.toLowerCase()?.includes(search?.toLowerCase());
          })
          .map((seller) => {
            return (
              <div
                onClick={() => {
                  handleSelection(seller);
                }}
                className={styles.wrapper}
                key={seller.id}
              >
                <Avatar className="font-bold mx-3" size="large">
                  <span className="text-lg">{seller.name[0]}</span>
                </Avatar>

                <div className="font-bold">{seller.name}</div>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <Modal footer={null} closable={true} open={show} onCancel={close} title="Select Seller">
      <Input
        className="mb-2"
        onChange={(e) => setSearch(e.target.value)}
        prefix={<SearchOutlined />}
        placeholder="Search Sellers"
      />
      {render()}
    </Modal>
  );
}
