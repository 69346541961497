import { SendOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
import { useState } from 'react';

interface MessageInputProps {
  onTextMessageSend?: (msg: string) => void;
}

export default function MessageInput({ onTextMessageSend }: MessageInputProps) {
  const [text, setText] = useState<string>('');
  const send = () => {
    if (text.trim() !== '') {
      onTextMessageSend && onTextMessageSend(text);
    }
    setText('');
  };

  return (
    <form onSubmit={send} className="w-full p-3 flex flex-row items-end">
      <div className="flex-grow mx-2 pb-1">
        <TextArea
          value={text}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              e.stopPropagation();
              send();
            }
          }}
          onChange={(e) => setText(e.target.value)}
          autoSize={true}
          className="w-full"
          placeholder="Type a message here..."
        />
      </div>
      <SendOutlined
        onClick={send}
        className="transition-colors text-primary hover:text-primary-50 text-3xl cursor-pointer"
      />
    </form>
  );
}
