import { Alert } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { Request, useGetRequestByIdQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import CreateSubmissionTableForm from '../components/CreateSubmissionTableForm';

type Props = {};

export default function CreateRequestSubmissionPage({}: Props) {
  const { requestId } = useParams();

  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRequestByIdQuery({
    variables: {
      requestId: requestId as string,
    },

    fetchPolicy: 'no-cache',
  });

  const request = data?.requestById as Request;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching Request, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Create Submission for Request</h2>
      </div>
      <h3>
        {request.rfq?.rFQNumber} - {request.rfq?.title}
      </h3>
      <h3>Submissions requested: {request.numberOfCandidates}</h3>
      <h3>Request Closure Date: {moment(request.rfq?.requestClosureDate).format('MMMM Do YYYY, h:mm:ss a')}</h3>
      <CreateSubmissionTableForm request={request} />
    </div>
  );
}
