import CreateOrEditSellerForm from '../components/CreateOrEditSellerForm';

export default function CreateSellerPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2>New Seller</h2>

        <CreateOrEditSellerForm className="max-w-3xl" />
      </div>
    </>
  );
}
