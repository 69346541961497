import { Avatar } from 'antd';
import { useCurrentUserStore } from '../../../shared/hooks/UseCurrentUserStore';
import { isSentByUser } from '../../utils/ChatUtils';
import styles from './Message.module.less';

type Props = {
  body: string;
  senderName: string;
  sentAgo: string;
};

export default function Message({ body, sentAgo, senderName }: Props) {
  const { user } = useCurrentUserStore();
  const sentByUser = isSentByUser(user?.role, senderName);

  return (
    <div className={`${styles.message} ${sentByUser ? 'justify-end' : 'justify-start'}`}>
      {/* Avatar */}
      <MessageAvatar hide={sentByUser} name={senderName[0].toUpperCase()} />

      {/* Content */}
      <div className="flex flex-col m-2 mb-3 relative">
        <div
          style={{ minWidth: 110 }}
          className={`p-3 overflow-clip max-w-sm rounded-md ${sentByUser ? 'bg-primary' : 'bg-gray-200'}`}
        >
          <div className={`${sentByUser ? 'text-white' : 'text-black'} break-words`}>{body}</div>
        </div>
        <div className="absolute -bottom-4 text-xs text-gray-400">{sentAgo}</div>
      </div>

      {/* Avatar */}
      <MessageAvatar hide={!sentByUser} name={senderName[0].toUpperCase()} />
    </div>
  );
}

function MessageAvatar({ name, hide }: { name: string; hide?: boolean }) {
  if (!hide) {
    return (
      <Avatar className="font-bold mx-3 flex-shrink-0" size="large">
        <span>{name[0]}</span>
      </Avatar>
    );
  }
  return null;
}
