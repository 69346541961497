import { PlusCircleFilled } from '@ant-design/icons';
import styles from './ChannelPreview.module.less';

interface ChannelPreviewProps {
  onClick?: () => void;
}

export default function CreateChannelPreview(props: ChannelPreviewProps) {
  return (
    <div onClick={props.onClick} className={styles.wrapper}>
      {/* Avatar */}
      <PlusCircleFilled
        style={{ fontSize: '40px' }}
        className="mx-3 transition-colors text-primary w-10 h-10 cursor-pointer"
      />

      {/* Preview col */}
      <div className="flex flex-col">
        <div className="font-bold">Start new chat</div>
        <div>
          <span>Start a new conversation.</span>
        </div>
      </div>

      <div className={styles.timeDisplay}></div>
    </div>
  );
}
