import { Theme } from '@aws-amplify/ui-react';

export const authenticatorTheme: Theme = {
  name: 'Go Sourcing Authenticator',
  tokens: {
    colors: {
      background: {
        secondary: {
          value: '#167ED0',
        },
      },
      font: {
        interactive: {
          value: '#167ED0',
        },
      },
    },
  },
};
