import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { Buyer, useDeleteBuyerByIdMutation } from '../../../graphql/generated';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { removeBuyerFromCache } from '../BuyerUtils';

type BuyersTableProps = {
  buyers: Array<Buyer>;
};
export default function BuyersTable({ buyers }: BuyersTableProps) {
  const getColumnSearchProps = UseGetColumnSearchProps<Buyer>();
  const [deleteBuyer] = useDeleteBuyerByIdMutation();

  const confirmDeleteBuyer = (buyerId: string) => {
    Modal.confirm({
      title: `Delete Buyer`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Buyer? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteBuyer({
          variables: {
            id: buyerId,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the Buyer, please try again in 5 minutes or contact support.',
            );
          },
        });
        removeBuyerFromCache(buyerId);
        message.success(`Buyer was successfully deleted.`);
      },
    });
  };
  const buyerColumns: ColumnsType<Buyer> = useMemo(
    () => [
      {
        title: 'Buyer Name',
        dataIndex: 'name',
        render: (name) => <span className="font-bold">{name}</span>,
        ...getColumnSearchProps('name'),
        filterSearch: true,
        sorter: (a, b) => a.name.localeCompare(b.name),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        render: (buyerId) => (
          <div className="flex flex-row gap-x-2">
            <Button onClick={() => confirmDeleteBuyer(buyerId)} type="primary" danger>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      scroll={{ x: 'max-content' }}
      columns={buyerColumns}
      dataSource={buyers.map((Buyer) => ({ ...Buyer, key: Buyer.id }))}
    />
  );
}
