import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { Link } from 'react-router-dom';
import { Industry, useGetAllIndustriesQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import IndustriesTable from '../components/IndustriesTable';

export default function IndustriesPage() {
  const { data, loading, error } = useGetAllIndustriesQuery();

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Industries could not be loaded due to an unknown error, please try again in 5 minutes or contact support."
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Industries Page</h2>
      </div>
      <div className="flex flex-row justify-between">
        <h3>Active Industries</h3>
        <Link to="create">
          <Button icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <IndustriesTable industries={(data?.industries?.nodes as unknown as Array<Industry>) ?? []} />
    </div>
  );
}
