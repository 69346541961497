import CreateOrEditUserForm from '../components/CreateOrEditUserForm';

export default function CreateUserPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2>Create User</h2>
        <div className="py-2">
          Filling out the form below will result in an invitation being sent to the user allowing them to join the Go
          Sourcing Admin Portal.
        </div>
        <CreateOrEditUserForm className="max-w-3xl" />
      </div>
    </>
  );
}
