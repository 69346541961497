import SpinLoader from '../../shared/components/loader/SpinLoader';
import {
  RequestSubmission,
  Request,
  useGetRequestSubmissionsBySellerAndRequestQuery,
} from '../../../graphql/generated';
import { Alert } from 'antd';
import { useCurrentUserStore } from '../../shared/hooks/UseCurrentUserStore';
import RequestSubmissionTable from '../components/RequestSubmissionsTable';
import { CurrentUserRole } from '../../shared/models/user/UserRole';

type Props = {
  request: Request;
};

export default function RequestSubmissionsPage({ request }: Props) {
  const currentUser = useCurrentUserStore((state) => state.user);

  const { data, loading, error, refetch } = useGetRequestSubmissionsBySellerAndRequestQuery({
    variables: {
      sellerId: currentUser?.sellerId as string,
      requestId: request.requestId as string,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching Requests, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <div>
      <RequestSubmissionTable
        isSeller={currentUser?.role == CurrentUserRole.SellerAdmin ? true : false}
        request={request}
        handleRefetch={refetch}
        requestSubmissions={
          (data?.requestSubmissionsBySellerAndRequest?.nodes as unknown as Array<RequestSubmission>) ?? []
        }
      />
    </div>
  );
}
