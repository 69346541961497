import { PDFDownloadLink } from '@react-pdf/renderer';
import { Alert, Button } from 'antd';
import { useParams } from 'react-router-dom';
import { Request, useGetRequestByIdQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { useCurrentUserStore } from '../../shared/hooks/UseCurrentUserStore';
import RequestDetails from '../components/RequestDetails';
import RequestPDF from '../components/RequestToPDF';

export default function ViewRequestPage() {
  const { requestId } = useParams();
  const currentUser = useCurrentUserStore((state) => state.user);
  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRequestByIdQuery({
    variables: {
      requestId: requestId as string,
    },
    fetchPolicy: 'no-cache',
  });

  const request = data?.requestById as Request;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching Requests, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <>
      <div className="flex flex-col items-end">
        <Button className="flex flex-col items-end" type="primary">
          <PDFDownloadLink
            document={<RequestPDF request={request} />}
            fileName={'request_for_RFQ_' + request.rfq?.rFQNumber + '.pdf'}
          >
            {({ loading }) => (loading ? 'Processing PDF' : 'Download As PDF')}
          </PDFDownloadLink>
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <h2>Request Details</h2>
      </div>
      {currentUser?.role == 'GSAdmin' ? (
        <RequestDetails request={request} />
      ) : (
        <RequestDetails isSeller request={request} />
      )}
    </>
  );
}
