import { Formik, Form } from 'formik';
import { CreateBuyerInput, Buyer, useCreateBuyerMutation } from '../../../graphql/generated';
import { Link, useNavigate } from 'react-router-dom';
import { getApolloErrorType, ServerErrorCode } from '../../shared/utils/ApolloErrorUtils';
import { Button, message } from 'antd';
import TextFormInput from '../../shared/components/form/TextFormInput';
import { createBuyerValidator } from '../validators/BuyerValidator';
import { addBuyerToCache } from '../BuyerUtils';

type CreateBuyerFormProps = {
  className?: string;
  isModal?: boolean;
  handleClose?: () => void;
};

export default function CreateBuyerForm({ className, isModal, handleClose }: CreateBuyerFormProps) {
  const navigate = useNavigate();
  const [createBuyer, { loading: createLoading }] = useCreateBuyerMutation();

  const handleCreateBuyer = async (buyer: CreateBuyerInput): Promise<Buyer> => {
    const response = await createBuyer({
      variables: {
        createBuyerInput: buyer,
      },
    });

    return response.data?.createBuyer as Buyer;
  };

  return (
    <Formik
      validationSchema={createBuyerValidator}
      initialValues={createBuyerValidator.cast({})}
      onSubmit={async (values) => {
        //If initial values are not set, then it's a create form.
        try {
          const buyer = await handleCreateBuyer(values as CreateBuyerInput);
          addBuyerToCache(buyer);
          handleClose && handleClose();
          if (!isModal) {
            navigate('./..');
          }
          message.success(`Buyer was successfully created !`);
        } catch (ex) {
          const apolloError = getApolloErrorType(ex);
          if (apolloError) {
            //This is a server error of some kind
            if (apolloError === ServerErrorCode.RESOURCE_ALREADY_EXISTS) {
              message.error(`The request could not be fulfilled because Buyer already exists.`);
            }
          } else {
            message.error(`An error occurred whilst creating the Buyer.`);
          }
        }
      }}
    >
      {({ submitForm }) => {
        return (
          <Form className={`${className} w-full`}>
            <TextFormInput required name="name" placeholder="Name" label="Name" />
            <div className="flex flex-row justify-end gap-2">
              {!isModal && (
                <Link to={'./..'}>
                  <Button type="default">Cancel</Button>
                </Link>
              )}
              <Button loading={createLoading} type="primary" onClick={submitForm}>
                Submit
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
