import { GetUsersDocument, GetUsersQuery, User } from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addUserToCache = (user: User): void => {
  const query = apolloClient.cache.readQuery<GetUsersQuery>({
    query: GetUsersDocument,
  });
  const newUsers = query?.users?.nodes?.concat({ ...user });
  apolloClient.cache.updateQuery<GetUsersQuery>({ query: GetUsersDocument }, () => ({
    users: { nodes: newUsers },
  }));
};

export const editUserInCache = (newUser: User): void => {
  const query = apolloClient.cache.readQuery<GetUsersQuery>({
    query: GetUsersDocument,
  });
  const newUsers = query?.users?.nodes?.map((user) => {
    if (newUser.email == user.email) {
      return newUser;
    }
    return user;
  });
  apolloClient.cache.updateQuery<GetUsersQuery>({ query: GetUsersDocument }, () => ({
    users: { nodes: newUsers },
  }));
};

export const removeUserFromCache = (deletedId: string): void => {
  const query = apolloClient.cache.readQuery<GetUsersQuery>({
    query: GetUsersDocument,
  });
  const updatedUsers = query?.users?.nodes?.filter((user) => {
    if (deletedId == user.id) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetUsersQuery>({ query: GetUsersDocument }, () => ({
    users: { nodes: updatedUsers },
  }));
};

export const updateUserEnabledInCache = (updateUserId: string, enabled: boolean): void => {
  const query = apolloClient.cache.readQuery<GetUsersQuery>({
    query: GetUsersDocument,
  });
  const existingUser = query?.users?.nodes?.find((user) => user.id == updateUserId) as User;
  if (!existingUser) {
    return;
  }
  const editUser = { ...existingUser, enabled };
  const userArray = [...(query?.users?.nodes || [])];
  const replaceIndex = userArray.findIndex((user) => user.id === editUser.id);
  if (replaceIndex === -1) {
    return;
  }
  userArray[replaceIndex] = editUser;
  apolloClient.cache.updateQuery<GetUsersQuery>({ query: GetUsersDocument }, () => ({
    users: { nodes: userArray },
  }));
};
