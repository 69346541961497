export enum ResourceAvailability {
  Immediate = 'IMMEDIATE',
  OneWeek = 'ONE_WEEK',
  TwoWeeks = 'TWO_WEEKS',
  ThreeWeeks = 'THREE_WEEKS',
  FourWeeks = 'FOUR_WEEKS',
  GreaterThanFourWeeks = 'GREATER_THAN_FOUR_WEEKS',
}

export const ResourceAvailabilityToDisplayText: { [key in ResourceAvailability]: string } = {
  [ResourceAvailability.Immediate]: 'Immediate',
  [ResourceAvailability.OneWeek]: '1 Week',
  [ResourceAvailability.TwoWeeks]: '2 Weeks',
  [ResourceAvailability.ThreeWeeks]: '3 Weeks',
  [ResourceAvailability.FourWeeks]: '4 Weeks',
  [ResourceAvailability.GreaterThanFourWeeks]: '> 4 Weeks',
};

export const ResourceAvailabilityComboBoxItems = Object.values(ResourceAvailability).map((val) => ({
  value: val,
  displayValue: ResourceAvailabilityToDisplayText[val],
}));
