import { Modal } from 'antd';
import CreateIndustryForm from '../../industries/components/CreateIndustryForm';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

export default function RFQCreateIndustryModal(props: Props) {
  return (
    <Modal title="Add New Industry" open={props.isOpen} onCancel={() => props.onClose()} footer={null}>
      <CreateIndustryForm isModal={true} handleClose={props.onClose} />
    </Modal>
  );
}
