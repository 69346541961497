import { Select } from 'antd';
import { useField, useFormikContext } from 'formik';
import { ReactNode } from 'react';
import FormError from './FormError';

export interface ComboBoxItem {
  value: string;
  displayValue: string;
}

interface ComboBoxInputProps {
  disabled?: boolean;
  name: string;
  initialValue?: string;
  placeholder: string;
  label: string;
  availableItems: Array<ComboBoxItem>;
  appendedElement?: ReactNode;
  required?: boolean;
  onChange?: (value: any) => void;
}

export default function ComboBoxInput(props: ComboBoxInputProps) {
  const [field, meta] = useField(props);
  const { setFieldValue, setFieldTouched } = useFormikContext();

  return (
    <div className="flex flex-col w-full">
      <label className="font-bold whitespace-nowrap	">
        {props.label} {props.required && '*'}
      </label>
      <div className="flex flex-row  w-full">
        <Select
          disabled={props.disabled}
          className="flex-grow flex-1  w-full"
          showSearch
          defaultValue={
            props.initialValue
              ? props.initialValue
              : props.availableItems.find((x) => {
                  return x.value === field.value;
                })?.displayValue
          }
          placeholder={props.placeholder}
          optionFilterProp="children"
          onBlur={() => setFieldTouched(props.name, true)}
          onChange={(value) => {
            setFieldValue(props.name, value);
            setFieldTouched(props.name, true);
            if (props.onChange) {
              props.onChange(value);
            }
          }}
          filterOption={(input, option) =>
            (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())
          }
        >
          {props.availableItems.map((x, idx) => {
            return (
              <Select.Option key={idx} value={x.value}>
                {x.displayValue}
              </Select.Option>
            );
          })}
        </Select>
        {props.appendedElement}
      </div>
      <FormError>{meta.error && meta.touched ? meta.error : ''}</FormError>
    </div>
  );
}
