import { UsergroupAddOutlined } from '@ant-design/icons';
import CreateUserPage from '../../user-management/pages/CreateUserPage';
import EditUserPage from '../../user-management/pages/EditUserPage';
import UserManagementPage from '../../user-management/pages/UserManagementPage';
import { CurrentUserRole } from '../models/user/UserRole';
import { localRoutingConstants } from './RoutingConstants';

export const userManagementRoutes = {
  users: {
    label: 'Users',
    path: localRoutingConstants.users.root,
    icon: <UsergroupAddOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <UserManagementPage />,
  },
  createUser: {
    label: 'Create User',
    path: localRoutingConstants.users.create,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <CreateUserPage />,
  },
  editUser: {
    label: 'Edit User',
    path: localRoutingConstants.users.edit,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <EditUserPage />,
  },
};
