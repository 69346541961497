export const validatorConstants = {
  textField: {
    minLength: 1,
    small: 64,
    medium: 255,
    large: 2200,
    xLarge: 33000,
    xxl: 100000,
    xxxl: 200000,
  },
};
