import { Message, UserSentMessageNotification, UserSession } from '@chatkitty/core';
import create from 'zustand';
import { chatkitty } from '../utils/ChatConfig';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import notificationWav from '../../../assets/audio/notification-tone.wav';
import { Auth } from 'aws-amplify';
type ChatNotification = {
  id: number;
  title: string;
  body: string;
};

interface ChatNotificationState {
  listenForNotifications: (username: string, idToken: string) => void;
  stopListeningForNotifications: () => void;
  ackNotification: (id: number) => void;
  ackAllNotifications: () => void;
  notifications: Array<ChatNotification>;
  session: UserSession | undefined;
  loading: boolean;
  error: string;
}

const useChatStore = create<ChatNotificationState>((set, state) => ({
  session: undefined,
  notifications: [],
  loading: true,
  error: '',
  listenForNotifications: async (usrName) => {
    if (state().session == undefined) {
      const sessionRes = await chatkitty.startSession({
        username: usrName,
        authParams: {
          idToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
      });
      if (sessionRes.failed) {
        set((store) => ({
          ...store,
          session: undefined,
          error:
            'An error has occurred whilst trying to connect to the chat session. Please try again in five minutes or contact support.',
          loading: false,
        }));
      } else {
        set((store) => ({ ...store, session: sessionRes.session, loading: false }));
      }

      chatkitty.onNotificationReceived((notification: UserSentMessageNotification) => {
        if (!(notification.data?.message as Message)?.properties?.mute) {
          const audio = new Audio(notificationWav);
          audio.play();
          set((prev) => ({
            ...prev,
            notifications: [
              ...prev.notifications,
              { body: notification.body, title: notification.title, id: notification.id },
            ],
          }));
        }
      });
    }
  },
  stopListeningForNotifications: async () => {
    await chatkitty.endSession();
    set((prev) => ({ ...prev, notifications: [] }));
  },
  ackNotification: (id: number) => {
    set((prev) => ({ ...prev, notifications: prev.notifications.filter((x) => x.id != id) }));
  },
  ackAllNotifications: () => {
    set((prev) => ({ ...prev, notifications: [] }));
  },
}));

export default useChatStore;
