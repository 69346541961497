import * as Yup from 'yup';
import { validatorConstants } from '../../shared/components/form/form-constants/ValidatorConstants';

export const createRequestValidator = Yup.object({
  requests: Yup.array(
    Yup.object({
      rFQNumber: Yup.string()
        .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
        .required('RFQ is required')
        .default(''),
      sellerId: Yup.string()
        .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
        .required('Seller is required')
        .default(''),
      numberOfCandidates: Yup.number()
        .positive('Number of Submissions must be greater than 0')
        .typeError('Number of Submissions must be a number')
        .integer('Value must be an Integer')
        .min(1, 'At least one candidate should be requested')
        .max(9999, 'Max. number of Submissions exceeded.')
        .required('Number of Submissions is a required field.')
        .default(1),
    }),
  )
    .default([])
    .min(1, 'You need to provide at least one request before submitting'),
});
