import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Switch } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Rfq, useGetNonArchivedRfqsQuery, useGetArchivedRfqsQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import RFQManagementTable from '../components/RFQManagementTable';

export default function RFQManagementPage() {
  const {
    data: nonArchivedData,
    loading: nonArchivedLoading,
    error: nonArchivedError,
    refetch: nonArchivedRefetch,
  } = useGetNonArchivedRfqsQuery({ fetchPolicy: 'no-cache' });
  const {
    data: archivedData,
    loading: archivedLoading,
    error: archivedError,
    refetch: archivedRefetch,
  } = useGetArchivedRfqsQuery({ fetchPolicy: 'no-cache' });
  const openRFQs = nonArchivedData?.nonArchivedRfqs?.nodes?.filter((val) => val.status);
  const closedRFQs = nonArchivedData?.nonArchivedRfqs?.nodes?.filter((val) => !val.status);
  const [showClosed, setShowClosed] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  if (nonArchivedLoading || archivedLoading) {
    return <SpinLoader />;
  } else if (nonArchivedError || archivedError) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching RFQs, please wait two minutes and try again or contact support."
      />
    );
  }
  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Open RFQs</h2>
        <Link to="create">
          <Button icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <RFQManagementTable rfqs={(openRFQs as unknown as Array<Rfq>) ?? []} refetch={nonArchivedRefetch} />
      <Switch
        className="my-5"
        unCheckedChildren="Hide Closed"
        checkedChildren="Show Closed"
        onChange={() => setShowClosed(!showClosed)}
      />
      <Switch
        className="my-5 ml-4"
        unCheckedChildren="Hide Archived"
        checkedChildren="Show Archived"
        onChange={() => setShowArchived(!showArchived)}
      />
      {showClosed && (
        <>
          <div className="flex flex-row justify-between">
            <h2>Closed RFQs</h2>
          </div>
          <RFQManagementTable rfqs={(closedRFQs as Array<Rfq>) ?? []} refetch={nonArchivedRefetch} />
        </>
      )}
      {showArchived && (
        <>
          <div className="flex flex-row justify-between">
            <h2>Archived RFQs</h2>
          </div>
          <RFQManagementTable
            rfqs={(archivedData?.archivedRfqs?.nodes as Array<Rfq>) ?? []}
            refetch={archivedRefetch}
          />
        </>
      )}
    </div>
  );
}
