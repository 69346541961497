import { Authenticator, AuthenticatorProps, Image, translations, useTheme, View } from '@aws-amplify/ui-react';
import { DefaultComponents } from '@aws-amplify/ui-react/dist/types/components/Authenticator/hooks/useCustomComponents/defaultComponents';
import Amplify, { I18n } from 'aws-amplify';
import { AuthConfig } from '../AuthConfig';
import GS_logo from './../../../assets/images/go_menu_logo.png';
import triber_logo from './../../../assets/images/triber/horizontal/Triber_Horizontal_Positive_Trans_BKG.png';

//Configure AWS and overrides (recommended way in docs to set custom error messages....)
Amplify.configure(AuthConfig);
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('en', {
  'User is disabled.':
    'Your account has been disabled by an administrator, please contact support to have your account reactivated.',
});

export default function GSAuthenticator(props: AuthenticatorProps) {
  return <Authenticator {...props} components={AuthenticatorComponents} />;
}

const AuthenticatorComponents: DefaultComponents = {
  Header() {
    const { tokens } = useTheme();
    return (
      <>
        <View textAlign="center" padding={tokens.space.large}>
          <Image alt="Go Sourcing logo" src={GS_logo} />
        </View>
        <div className="text-secondary font-bold text-5xl">Sign In</div>
        <div className="pt-2 pb-8 text-lg">Please sign in with your email and password below</div>
      </>
    );
  },
  Footer() {
    return (
      <>
        <a href="https://www.gosourcing.com.au/" className="flex flex-row justify-center mt-9 text-secondary">
          Return to Home
        </a>
        <Image
          alt="Triber logo"
          src={triber_logo}
          className="relative bottom-0 2xl:bottom-0 2xl:right-0 2xl:absolute"
        />
      </>
    );
  },
};
