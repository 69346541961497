import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Request,
  RequestStatus,
  Rfq,
  Seller,
  useDeleteRequestMutation,
  useSetNoSubmissionByRequestIdMutation,
} from '../../../graphql/generated';
import RfqForm from '../../rfq-management/components/RfqForm';
import RequestStatusRenderer from '../../shared/components/request-status/RequestStatusRenderer';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { ActivityTypeToDisplayText } from '../../shared/models/rfq/ActivityType';
import { localRoutingConstants } from '../../shared/routing/RoutingConstants';
import { removeRequestFromCache } from '../requestUtils';
import { RequestStatusToDisplayText, RequestStatusCompare } from '../../shared/models/request/RequestStatus';

type RequestTableProps = {
  requests: Array<Request>;
  createSubmission?: boolean;
  onSuccessfulDelete?: () => void;
};

export default function RequestTable({ requests, createSubmission, onSuccessfulDelete }: RequestTableProps) {
  const getColumnSearchProps = UseGetColumnSearchProps<Request>();
  const [deleteRequest] = useDeleteRequestMutation();
  const [setNoSubmissionByRequestId] = useSetNoSubmissionByRequestIdMutation();
  const navigate = useNavigate();
  const [editRequest, setEditRequest] = useState<Request | null>(null);

  const confirmDeleteRequest = (requestId: string) => {
    Modal.confirm({
      title: `Delete Request`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this Request? Once deleted data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteRequest({
          variables: {
            id: requestId,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the Request, please try again in 5 minutes or contact support.',
            );
          },
          onCompleted: () => {
            removeRequestFromCache(requestId);
            onSuccessfulDelete && onSuccessfulDelete();
            navigate(localRoutingConstants.requests.root);
            message.success(`Request was successfully deleted.`);
          },
        });
      },
    });
  };

  const confirmOptOut = (requestId: string) => {
    Modal.confirm({
      title: `Opt Out of Request`,
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to opt out of submissions? Opting out means that you will not be submitting any applicants for this request. This action cannot be undone.',
      okText: 'Opt Out',
      cancelText: 'Go back',
      onOk: async () => {
        await setNoSubmissionByRequestId({
          variables: {
            requestId: requestId,
            noSubmissionValue: true,
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying opt out of the Request, please try again in 5 minutes or contact support.',
            );
          },
        });
        message.success('Successfully opted out of the request');
      },
    });
  };

  const statusFilterProps = getColumnSearchProps('Status');
  statusFilterProps.onFilter = (value, record) => {
    if (!record) return false;

    if (!record.status) return false;

    return RequestStatusToDisplayText[record.status].toLowerCase().startsWith((value as string).toLowerCase());
  };

  const adminRequestColumns: ColumnsType<Request> = useMemo(
    () => [
      {
        title: 'Buyer',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.buyer.name'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq && rfq.buyer) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{rfq.buyer.name}</span>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) =>
          a.rfq && a.rfq.buyer && b.rfq && b.rfq.buyer ? a.rfq.buyer.name.localeCompare(b.rfq.buyer.name) : 1,
      },
      {
        title: 'RFQ Number',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.rFQNumber'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{rfq.rFQNumber}</span>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.rfq?.rFQNumber.localeCompare(b.rfq?.rFQNumber ?? '') ?? 1,
      },
      {
        title: 'RFQ Title',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.title'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{rfq.title}</span>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.rfq?.title.localeCompare(b.rfq?.title ?? '') ?? 1,
      },
      {
        title: 'Activity Type',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.activityType'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return (
              <span className={request.rfqOverride ? 'font-bold' : ''}>
                {ActivityTypeToDisplayText[rfq.activityType]}
              </span>
            );
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.rfq?.activityType.localeCompare(b.rfq?.activityType ?? '') ?? 1,
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        ...getColumnSearchProps('seller.name'),
        render: (seller: Seller) => {
          if (seller) {
            return <Link to={`../sellers/${seller.id}`}>{seller.name ?? 'N/A'}</Link>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.seller?.name.localeCompare(b.seller?.name ?? '') ?? 1,
        hidden: { createSubmission },
      },
      {
        title: 'Request Closure Date',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.requestClosureDate'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return (
              <span className={request.rfqOverride ? 'font-bold' : ''}>
                {moment(rfq.requestClosureDate).format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            );
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => moment(a.rfq?.requestClosureDate).unix() - moment(b.rfq?.requestClosureDate).unix(),
      },
      {
        title: 'Max. Submissions',
        dataIndex: 'rfq',
        width: 100,
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{request.numberOfCandidates}</span>;
          }
          return <span>N/A</span>;
        },
      },
      {
        title: 'Seller Submissions',
        dataIndex: 'rfq',
        width: 100,
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{request.numberSupplied}</span>;
          }
          return <span>N/A</span>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        ...statusFilterProps,
        render: (_, request) => {
          return (
            <div className={request.rfqOverride ? 'font-bold' : ''}>
              <RequestStatusRenderer status={request.status as RequestStatus} />
            </div>
          );
        },
        sorter: CompareStatus,
      },
      {
        title: 'Actions',
        fixed: 'right',
        dataIndex: 'requestId',
        render: (requestId, request) => (
          <div className="flex flex-row gap-x-2">
            <Link to={`/request/${requestId}`}>
              <Button type="primary">View</Button>
            </Link>
            <Button
              onClick={() => setEditRequest(requests.find((r) => r.requestId == requestId) as Request)}
              type="primary"
              disabled={request.noSubmission}
            >
              Edit
            </Button>
            <Button onClick={() => confirmDeleteRequest(requestId)} type="primary" danger>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  const sellerRequestColumns: ColumnsType<Request> = useMemo(
    () => [
      {
        title: 'Buyer',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.buyer.name'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq && rfq.buyer) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{rfq.buyer.name}</span>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) =>
          a.rfq && a.rfq.buyer && b.rfq && b.rfq.buyer ? a.rfq.buyer.name.localeCompare(b.rfq.buyer.name) : 1,
      },
      {
        title: 'RFQ Number',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.rFQNumber'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{rfq.rFQNumber}</span>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.rfq?.rFQNumber.localeCompare(b.rfq?.rFQNumber ?? '') ?? 1,
      },
      {
        title: 'RFQ Title',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.title'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{rfq.title}</span>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.rfq?.title.localeCompare(b.rfq?.title ?? '') ?? 1,
      },
      {
        title: 'Activity Type',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.activityType'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return (
              <span className={request.rfqOverride ? 'font-bold' : ''}>
                {ActivityTypeToDisplayText[rfq.activityType]}
              </span>
            );
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.rfq?.activityType.localeCompare(b.rfq?.activityType ?? '') ?? 1,
      },
      {
        title: 'Request Closure Date',
        dataIndex: 'rfq',
        ...getColumnSearchProps('rfq.requestClosureDate'),
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return (
              <span className={request.rfqOverride ? 'font-bold' : ''}>
                {moment(rfq.requestClosureDate).format('MMMM Do YYYY, h:mm:ss a')}
              </span>
            );
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => moment(a.rfq?.requestClosureDate).unix() - moment(b.rfq?.requestClosureDate).unix(),
      },
      {
        title: 'Max. Submissions',
        dataIndex: 'rfq',
        width: 100,
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{request.numberOfCandidates}</span>;
          }
          return <span>N/A</span>;
        },
      },
      {
        title: 'Seller Submissions',
        dataIndex: 'rfq',
        width: 100,
        render: (rfq: Rfq, request: Request) => {
          if (rfq) {
            return <span className={request.rfqOverride ? 'font-bold' : ''}>{request.numberSupplied}</span>;
          }
          return <span>N/A</span>;
        },
      },
      {
        title: 'Status',
        dataIndex: 'status',
        ...statusFilterProps,
        render: (_, request) => {
          return (
            <div className={request.rfqOverride ? 'font-bold' : ''}>
              <RequestStatusRenderer status={request.status as RequestStatus} />
            </div>
          );
        },
        sorter: CompareStatus,
      },
      {
        title: 'Actions',
        dataIndex: 'requestId',
        render: (requestId: string, request) => (
          <div className="flex flex-row gap-x-2">
            <Link to={`/seller-request/${requestId}`}>
              <Button type="primary">View</Button>
            </Link>
            <Link to={`/seller-request/${requestId}/create`}>
              <Button
                type="primary"
                disabled={!request.isOpen || (request.noSubmission ? request.noSubmission : false)}
              >
                Create Submission
              </Button>
            </Link>
            <Button
              danger
              type="primary"
              disabled={!request.isOpen || (request.noSubmission ? request.noSubmission : false)}
              onClick={() => confirmOptOut(requestId)}
            >
              Opt Out
            </Button>
          </div>
        ),
      },
    ],
    [],
  );
  return (
    <>
      <Table
        scroll={{ x: 'max-content' }}
        columns={!createSubmission ? adminRequestColumns : sellerRequestColumns}
        dataSource={requests.map((request) => ({
          ...request,
          key: request.requestId,
        }))}
      />
      <Modal open={editRequest != null} onCancel={() => setEditRequest(null)} footer={null} width={800} destroyOnClose>
        <RfqForm overrideRequest={editRequest as Request} editRfq={editRequest?.rfq as Rfq} />
      </Modal>
    </>
  );
}

function CompareStatus(a: Request, b: Request): number {
  if (a && b && a.status && b.status) {
    return RequestStatusCompare(a.status, b.status);
  } else {
    return 0;
  }
}
