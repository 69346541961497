import { ApolloError } from '@apollo/client';

export enum ServerErrorCode {
  RESOURCE_NOT_FOUND = 'RESOURCE_NOT_FOUND',
  RESOURCE_ALREADY_EXISTS = 'RESOURCE_ALREADY_EXISTS',
  CANNOT_DELETE_LAST_ADMIN_USER = 'CANNOT_DELETE_LAST_ADMIN_USER',
  DATA_UPDATE_EXCEPTION = 'DATA_UPDATE_EXCEPTION',
  DUPLICATE_REQUEST_EXCEPTION = 'DUPLICATE_REQUEST_EXCEPTION',
  EMAIL_EXCEPTION = 'EMAIL_EXCEPTION',
  FILE_HANDLING_EXCEPTION = 'FILE_HANDLING_EXCEPTION',
  LIMIT_REACHED = 'LIMIT_REACHED',
}

export const getApolloErrorType = (ex: unknown): string | undefined => {
  if (ex instanceof ApolloError) {
    if (ex.graphQLErrors.length > 0) {
      const code = ex.graphQLErrors[0].extensions.code as string;
      return ServerErrorCode[code as keyof typeof ServerErrorCode];
    }
    if ((ex.networkError as any).result.errors.length > 0) {
      const code = (ex.networkError as any).result.errors[0].extensions.code as string;
      return ServerErrorCode[code as keyof typeof ServerErrorCode];
    }
  }
};

/**
 * Returns the error message of the first error in the error stack.
 */
export const getApolloErrorMessage = (ex: unknown): string | undefined => {
  if (ex instanceof ApolloError) {
    if (ex.graphQLErrors.length > 0) {
      return ex.graphQLErrors[0].message;
    }
    if ((ex.networkError as any).result.errors.length > 0) {
      return (ex.networkError as any).result.errors[0].message;
    } else return undefined;
  } else {
    return (ex as Error)?.message;
  }
};
