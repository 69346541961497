import { getCodes } from 'country-list';
import { data as currencyData } from 'currency-codes';
import {
  ActivityType,
  ChargeType,
  Residencies,
  SecurityClearance,
  WorkingArrangement,
} from '../../../graphql/generated';
import { validatorConstants } from '../../shared/components/form/form-constants/ValidatorConstants';
import * as Yup from 'yup';

const currencies = currencyData.map((c) => c.code);

export const createRfqValidator = Yup.object({
  rFQNumber: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .required('RFQNumber is a required field')
    .default(''),
  title: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .required('Title is a required field')
    .default(''),
  status: Yup.boolean().default(true),
  buyerId: Yup.string().required('Buyer is a required field.').default(''),
  rFQPublishedDate: Yup.date().typeError('Published Date is a required field').default(null),
  requestClosureDate: Yup.date()
    .min(Yup.ref('rFQPublishedDate'), 'Request Closure Date cannot be before published date')
    .typeError('RFQ Request Closure Date is a required field')
    .default(null),
  estimatedStartDate: Yup.date()
    .min(Yup.ref('requestClosureDate'), 'Start date cannot be before closing date')
    .typeError('Estimated Start Date is a required field')
    .default(null),
  contractDuration: Yup.date()
    .min(Yup.ref('estimatedStartDate'), 'Contract duration cannot be before start date')
    .nullable(),
  extensionTerms: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .default(''),
  residency: Yup.array()
    .of(Yup.string().oneOf(Object.values(Residencies)))
    .min(1, 'Residency is a required field')
    .required('Residency is a required field')
    .default([]),
  workingArrangement: Yup.string()
    .required('Working Arrangement is a required field')
    .oneOf(Object.values(WorkingArrangement))
    .default(''),
  location: Yup.array().of(Yup.string().required('Location is a required field').default('')),
  security: Yup.string()
    .required('Security Clearance is a required field')
    .oneOf(Object.values(SecurityClearance))
    .default(''),
  maxSubmissions: Yup.number()
    .positive('Max Submissions must be greater than 0')
    .typeError('Max Submissions must be a number')
    .integer('Value must be an Integer')
    .min(1, 'At least one candidate should be requested')
    .max(9999, 'Max. number of Submissions exceeded')
    .required('Max Submissions is a required field')
    .default(1),
  maxCharge: Yup.number()
    .nullable()
    .min(0, 'Max Charge must be greater than 0')
    .max(99999999, 'Max Charge must be less than 100,000,000.00'),
  supportingInformation: Yup.string()
    .max(validatorConstants.textField.xxxl, `Cannot exceed ${validatorConstants.textField.xxxl} characters`)
    .default(''),
  attachments: Yup.array(Yup.string()).default([]),
  country: Yup.string().required('Country is a required field').oneOf(Object.values(getCodes())).default(''),
  industryId: Yup.string().required('Industry is a required field.').default(''),
  chargeType: Yup.string().oneOf(Object.values(ChargeType)).required('Charge Type is a required field.'),
  activityType: Yup.string().oneOf(Object.values(ActivityType)).required('Activity Type is a required field.'),
  currencyCode: Yup.string().oneOf(Object.values(currencies)).required('Currency is a required field.'),
});

export const editRfqValidator = Yup.object({
  rFQNumber: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .required('RFQNumber is a required field')
    .default(''),
  title: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .required('Title is a required field')
    .default(''),
  extensionTerms: Yup.string()
    .max(validatorConstants.textField.small, `Cannot exceed ${validatorConstants.textField.small} characters`)
    .default(''),
  residency: Yup.array()
    .of(Yup.string().oneOf(Object.values(Residencies)))
    .min(1, 'Residency is a required field')
    .required('Residency is a required field')
    .default([]),
  workingArrangement: Yup.string()
    .required('Working Arrangement is a required field')
    .oneOf(Object.values(WorkingArrangement))
    .default(''),
  location: Yup.array().of(Yup.string().required('Location is a required field').default('')),
  security: Yup.string()
    .required('Security Clearance is a required field')
    .oneOf(Object.values(SecurityClearance))
    .default(''),
  maxSubmissions: Yup.number()
    .positive('Max Submissions must be greater than 0')
    .typeError('Max Submissions must be a number')
    .integer('Value must be an Integer')
    .min(1, 'At least one candidate should be requested')
    .max(9999, 'Max. number of Submissions exceeded')
    .required('Max Submissions is a required field')
    .default(1),
  maxCharge: Yup.number()
    .nullable()
    .min(0, 'Max Charge must be greater than 0')
    .max(99999999, 'Max Charge must be less than 100,000,000.00'),
  supportingInformation: Yup.string()
    .max(validatorConstants.textField.xxxl, `Cannot exceed ${validatorConstants.textField.xxxl} characters`)
    .default(''),
  chargeType: Yup.string().oneOf(Object.values(ChargeType)).required('Charge Type is a required field.'),
  requestClosureDate: Yup.date().required('Request Closure Date is a required field'),
  estimatedStartDate: Yup.date().required('Estimated Start Date is a required field'),
  contractDuration: Yup.date()
    .min(Yup.ref('estimatedStartDate'), 'Contract duration cannot be before start date')
    .nullable()
    .default(null),
  attachments: Yup.array(Yup.string()).default([]),
  currencyCode: Yup.string().oneOf(Object.values(currencies)).required('Currency is a required field.'),
});
