import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from 'apollo-link-context';
import { Auth } from 'aws-amplify';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_GRAPHQL_SERVER_URL,
});

const authMiddleware = setContext(async (operation, forward) => {
  // Retrieve jwt token from store.
  const authToken = (await Auth.currentSession()).getIdToken().getJwtToken();
  return {
    headers: {
      ...forward.headers,
      authorization: 'Bearer ' + authToken,
    },
  };
});

const apolloClient = new ApolloClient({
  //Must first cast as unknown due to inconsistencies between apollo link and apollo client.
  link: from([authMiddleware as unknown as ApolloLink, httpLink]),
  cache: new InMemoryCache(),
});

export default apolloClient;
