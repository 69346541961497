import { GetAllBuyersDocument, GetAllBuyersQuery, Buyer } from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addBuyerToCache = (buyer: Buyer): void => {
  const query = apolloClient.cache.readQuery<GetAllBuyersQuery>({
    query: GetAllBuyersDocument,
  });

  const newBuyers = query?.buyers?.nodes?.concat({ ...buyer });

  apolloClient.cache.updateQuery<GetAllBuyersQuery>({ query: GetAllBuyersDocument }, () => ({
    buyers: { nodes: newBuyers },
  }));
};

export const removeBuyerFromCache = (deletedId: string): void => {
  const query = apolloClient.cache.readQuery<GetAllBuyersQuery>({
    query: GetAllBuyersDocument,
  });
  const updatedBuyer = query?.buyers?.nodes?.filter((buyer) => {
    if (deletedId == buyer.id) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetAllBuyersQuery>({ query: GetAllBuyersDocument }, () => ({
    buyers: { nodes: updatedBuyer },
  }));
};
