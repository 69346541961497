import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Badge, Button, message, Modal } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Seller, useDeleteUserMutation, UserRole, User, UserStatus } from '../../../graphql/generated';
import UseGetColumnSearchProps from '../../shared/hooks/UseGetColumnSearchProps';
import { UserRoleToDisplayText } from '../../shared/models/user/UserRole';
import { removeUserFromCache } from '../userUtils';
import { useCurrentUserStore } from '../../../features/shared/hooks/UseCurrentUserStore';

type UserManagementTableProps = {
  users: Array<User>;
};

export default function UserManagementTable({ users }: UserManagementTableProps) {
  const [deleteUser] = useDeleteUserMutation();
  const currentUserId = useCurrentUserStore((store) => store.user?.id);

  const getColumnSearchProps = UseGetColumnSearchProps<User>();
  const confirmDeleteUser = (id: string) => {
    Modal.confirm({
      title: 'Delete User',
      okType: 'danger',
      icon: <ExclamationCircleOutlined />,
      content:
        'Are you sure you wish to delete this user, once deleted their data will be lost and they cannot be restored.',
      okText: 'Confirm',
      cancelText: 'Go back',
      onOk: async () => {
        await deleteUser({
          variables: {
            deleteUserId: id,
          },
          onCompleted: () => {
            message.success('The selected user was successfully deleted.');
            removeUserFromCache(id);
          },
          onError: () => {
            message.error(
              'An error occurred whilst trying delete the user, you can not delete Go Sourcing Administrators, please try again in 5 minutes or contact support.',
            );
          },
        });
      },
    });
  };

  const userColumns: ColumnsType<User> = useMemo(
    () => [
      {
        title: 'Name',
        dataIndex: 'fullName',
        render: (name) => <span className="font-bold">{name}</span>,
        ...getColumnSearchProps('fullName'),
        sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...getColumnSearchProps('email'),
        sorter: (a, b) => a.email.localeCompare(b.email),
      },
      {
        title: 'Role',
        dataIndex: 'role',
        render: (role) => UserRoleToDisplayText[role as UserRole],
        sorter: (a, b) => a.role.localeCompare(b.role),
      },
      {
        title: 'Seller',
        dataIndex: 'seller',
        ...getColumnSearchProps('seller.name'),
        render: (seller: Seller) => {
          if (seller) {
            return <Link to={`../sellers/${seller.id}`}>{seller.name ?? 'N/A'}</Link>;
          }
          return <span>N/A</span>;
        },
        sorter: (a, b) => a.seller?.name.localeCompare(b.seller?.name ?? '') ?? 1,
      },
      {
        title: 'Registration Status',
        dataIndex: 'userStatus',
        render: (val) => (val === UserStatus.Confirmed ? 'Registered' : 'Pending'),
        sorter: (a) => (a.userStatus === UserStatus.Confirmed ? 1 : -1),
      },
      {
        title: 'Enabled/Disabled',
        dataIndex: 'enabled',
        render: (val) => <Badge status={val ? 'success' : 'error'} text={val ? 'Enabled' : 'Disabled'} />,
        sorter: (a) => (a.enabled ? 1 : -1),
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        fixed: 'right',
        render: (id, user) => (
          <div className="flex flex-row gap-x-2">
            <Link to={`${id}/edit`}>
              <Button type="primary">Edit</Button>
            </Link>
            <Button onClick={() => confirmDeleteUser(id)} type="primary" danger disabled={user.id === currentUserId}>
              Delete
            </Button>
          </div>
        ),
      },
    ],
    [],
  );

  return (
    <Table
      scroll={{ x: 'max-content' }}
      columns={userColumns}
      dataSource={users.map((user) => ({ ...user, key: user.email }))}
    />
  );
}
