import { ActivityType } from '../../../../graphql/generated';

export const ActivityTypeToDisplayText: { [key in ActivityType]: string } = {
  [ActivityType.LabourHire]: 'Labour Hire',
  [ActivityType.Permanent]: 'Permanent',
  [ActivityType.Services]: 'Goods & Services',
};

export const ActivityTypeToComboBoxItems = Object.values(ActivityType).map((val) => ({
  value: val,
  displayValue: ActivityTypeToDisplayText[val],
}));
