import { GetAllRequestsDocument, GetAllRequestsQuery, Request } from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addRequestToCache = (request: Request): void => {
  const query = apolloClient.cache.readQuery<GetAllRequestsQuery>({
    query: GetAllRequestsDocument,
  });
  const newRequests = query?.allRequests?.nodes?.concat({ ...request });
  apolloClient.cache.updateQuery<GetAllRequestsQuery>({ query: GetAllRequestsDocument }, () => ({
    allRequests: { nodes: newRequests },
  }));
};

export const editRequestInCache = (newRequest: Request): void => {
  const query = apolloClient.cache.readQuery<GetAllRequestsQuery>({
    query: GetAllRequestsDocument,
  });
  const newRequests = query?.allRequests?.nodes?.map((request) => {
    if (newRequest.requestId === request.requestId) {
      return newRequest;
    }
    return request;
  });
  apolloClient.cache.updateQuery<GetAllRequestsQuery>({ query: GetAllRequestsDocument }, () => ({
    allRequests: { nodes: newRequests },
  }));
};

export const removeRequestFromCache = (deletedRequestId: string): void => {
  const query = apolloClient.cache.readQuery<GetAllRequestsQuery>({
    query: GetAllRequestsDocument,
  });
  const updatedRequests = query?.allRequests?.nodes?.filter((request) => {
    if (deletedRequestId == request.requestId) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetAllRequestsQuery>({ query: GetAllRequestsDocument }, () => ({
    allRequests: { nodes: updatedRequests },
  }));
};
