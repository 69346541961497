import { translations } from '@aws-amplify/ui-react';
import Amplify, { I18n } from 'aws-amplify';

export const AuthConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_WEB_CLIENT_ID,
  },
};

//Configure AWS
Amplify.configure(AuthConfig);

//Override error messages using I18 internationalisation library (recommended way in docs to set custom error messages....smh)
I18n.putVocabularies(translations);
I18n.putVocabulariesForLanguage('en', {
  'User is disabled':
    'Your account has been disabled by an administrator, please contact support to have your account reactivated.',
});
