import { GetSellersDocument, GetSellersQuery, Seller } from '../../graphql/generated';
import apolloClient from '../shared/utils/ApolloClient';

export const addSellerToCache = (seller: Seller): void => {
  const query = apolloClient.cache.readQuery<GetSellersQuery>({
    query: GetSellersDocument,
  });

  const newSellers = query?.sellers?.nodes?.concat({ ...seller });

  apolloClient.cache.updateQuery<GetSellersQuery>({ query: GetSellersDocument }, () => ({
    sellers: { nodes: newSellers },
  }));
};

export const editSellerInCache = (newSeller: Seller): void => {
  const query = apolloClient.cache.readQuery<GetSellersQuery>({
    query: GetSellersDocument,
  });
  const newSellers = query?.sellers?.nodes?.map((seller) => {
    if (newSeller.id === seller.id) {
      return newSeller;
    }
    return seller;
  });
  apolloClient.cache.updateQuery<GetSellersQuery>({ query: GetSellersDocument }, () => ({
    sellers: { nodes: newSellers },
  }));
};

export const removeSellerFromCache = (deletedId: string): void => {
  const query = apolloClient.cache.readQuery<GetSellersQuery>({
    query: GetSellersDocument,
  });
  const updatedSeller = query?.sellers?.nodes?.filter((seller: { id: string }) => {
    if (deletedId == seller.id) {
      return false;
    }
    return true;
  });
  apolloClient.cache.updateQuery<GetSellersQuery>({ query: GetSellersDocument }, () => ({
    sellers: { nodes: updatedSeller },
  }));
};
