import { Alert, Button } from 'antd';
import SellersTable from '../components/SellersTable';
import { Link } from 'react-router-dom';
import { useGetSellersQuery } from '../../../graphql/generated';
import { Seller } from '../../shared/models/sellers/Seller';
import { PlusOutlined } from '@ant-design/icons';
import SpinLoader from '../../shared/components/loader/SpinLoader';

export default function SellersPage() {
  const { data, loading, error } = useGetSellersQuery();

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="Seller could not be loaded due to an unknown error, please try again in 5 minutes or contact support."
      />
    );
  }

  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>Sellers Page</h2>
      </div>
      <div className="flex flex-row justify-between">
        <h3>Sellers</h3>
        <Link to="create">
          <Button icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <SellersTable sellers={(data?.sellers?.nodes as unknown as Array<Seller>) ?? []} />
    </div>
  );
}
