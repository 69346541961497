import { BankOutlined } from '@ant-design/icons';
import CreateIndustryPage from '../../industries/pages/CreateIndustryPage';
import IndustriesPage from '../../industries/pages/IndustriesPage';
import { CurrentUserRole } from '../models/user/UserRole';
import { localRoutingConstants } from './RoutingConstants';

export const industryRoutes = {
  industries: {
    label: 'Industries',
    path: localRoutingConstants.industries.root,
    icon: <BankOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <IndustriesPage />,
  },
  createIndustries: {
    label: 'Create Industries',
    path: localRoutingConstants.industries.create,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <CreateIndustryPage />,
  },
};
