import { PlusOutlined } from '@ant-design/icons';
import { Alert, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useGetUsersQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { User } from '../../shared/models/user/User';
import UserManagementTable from '../components/UserManagementTable';

export default function UserManagementPage() {
  const { data, loading, error } = useGetUsersQuery();

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching users, please wait two minutes and try again or contact support."
      />
    );
  }
  return (
    <div>
      <div className="flex flex-row justify-between">
        <h2>All Users</h2>
        <Link to="create">
          <Button icon={<PlusOutlined />} type="primary">
            Add New
          </Button>
        </Link>
      </div>
      <UserManagementTable users={(data?.users?.nodes as unknown as Array<User>) ?? []} />
    </div>
  );
}
