import { SecurityClearance } from '../../../../graphql/generated';

export const SecurityClearanceOptionsToDisplayText: { [key in SecurityClearance]: string } = {
  [SecurityClearance.Baseline]: 'Baseline',
  [SecurityClearance.Nv1]: 'NV1',
  [SecurityClearance.Nv2]: 'NV2',
  [SecurityClearance.Na]: 'N/A',
};

function getOrderedSecurityClearences(): SecurityClearance[] {
  //add security clearances in implicit order
  const clearances: SecurityClearance[] = [
    SecurityClearance.Nv1,
    SecurityClearance.Nv2,
    SecurityClearance.Baseline,
    SecurityClearance.Na,
  ];

  //create set to see which clearances we've worked with
  const contained: Set<SecurityClearance> = new Set();
  for (const clearance of clearances) {
    contained.add(clearance);
  }

  //add any remaining clearances
  for (const clearance of Object.values(SecurityClearance)) {
    if (!contained.has(clearance)) {
      clearances.push(clearance);
    }
  }

  return clearances;
}

//store in const so we only work this out once
const orderedClearances = getOrderedSecurityClearences();

export const SecurityClearanceComboboxItems = orderedClearances.map((val) => ({
  value: val,
  displayValue: SecurityClearanceOptionsToDisplayText[val],
}));
