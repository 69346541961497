import { Alert } from 'antd';
import { useParams } from 'react-router-dom';
import { Rfq, useGetRfqByNumberQuery } from '../../../graphql/generated';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import CreateRequestTable from '../components/CreateRequestTable';

type Props = {};

export default function CreateRequestPage({}: Props) {
  const { rfqNumber } = useParams();

  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRfqByNumberQuery({
    variables: {
      rfqNumber: rfqNumber as string,
    },

    fetchPolicy: 'no-cache',
  });

  const rfq = data?.rfqByNumber as Rfq;

  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching RFQ, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <h2>Create Request </h2>
      <h3>
        {rfq.rFQNumber} - {rfq.title}
      </h3>
      <CreateRequestTable rfq={rfq} className="max-w-3xl" />
    </div>
  );
}
