import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import moment from 'moment';
import GSLogo from '../../../assets/images/go_menu_logo_with_text.png';
import {
  ChargeType,
  Residencies,
  RequestSubmission,
  SecurityClearance,
  SubmissionStatus,
} from '../../../graphql/generated';
import { DefaultPDFStyles } from '../../shared/components/PDF/DefaultPDFStyles';
import { SubmissionStatusToDisplayText } from '../../shared/models/request-submissions/RequestSubmissionStatus';
import { ResidenciesToDisplayText } from '../../shared/models/rfq/Residencies';
import { ResourceAvailability, ResourceAvailabilityToDisplayText } from '../../shared/models/rfq/ResourceAvailability';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceOptionsToDisplayText } from '../../shared/models/rfq/RfqSecurityClearance';
import { CurrencyFormatter } from '../../shared/utils/IntlUtils';

type Props = {
  requestSubmission: RequestSubmission;
};

export default function RequestSubmissionPDF(props: Props) {
  const styles = DefaultPDFStyles;

  const dateTimeRetrieved = new Date().toLocaleString();

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <Image source={GSLogo} style={styles.logo} />
        <View>
          <Text style={styles.title}>Request Submission</Text>
          <Text style={styles.h1}>RFQ Number: {props.requestSubmission.request?.rfq?.rFQNumber}</Text>
          <Text style={styles.h1}>RFQ Title: {props.requestSubmission.request?.rfq?.title}</Text>
          <Text style={styles.h1}>Seller: {props.requestSubmission.request?.seller?.name}</Text>
          <Text style={styles.h1}>Retrieved: {dateTimeRetrieved}</Text>
        </View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Name/Proposal</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.requestSubmission.name}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Location</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{props.requestSubmission.location.join(', ')}</Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Security</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {
                  // Defaults to N/A when no value is found. This is probably fine
                  SecurityClearanceOptionsToDisplayText[props.requestSubmission.security as SecurityClearance]
                }
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Charge</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {props.requestSubmission.chargeRate
                  ? CurrencyFormatter.format(props.requestSubmission.chargeRate) +
                    ChargeTypeOptionsToAbbreviatedText[props.requestSubmission.request?.rfq?.chargeType as ChargeType]
                  : 'N/A'}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Residency</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {ResidenciesToDisplayText[props.requestSubmission.residency as Residencies]}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Availability</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>
                {ResourceAvailabilityToDisplayText[props.requestSubmission.availability as ResourceAvailability]}
              </Text>
            </View>
          </View>

          <View style={styles.tableRow}>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>Submission Status</Text>
            </View>
            <View style={styles.tableCol}>
              <Text style={styles.tableCell}>{SubmissionStatusToDisplayText[props.requestSubmission.status]}</Text>
            </View>
          </View>
          {props.requestSubmission.status.toString() == SubmissionStatus.Interview ? (
            <>
              <View style={styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>Interview Time</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {moment(props.requestSubmission.interviewTime).format('MMMM Do YYYY, h:mm:ss a')}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>Interview Additional Information</Text>
                  </View>
                  <View style={styles.tableCol}>
                    <Text style={styles.tableCell}>{props.requestSubmission.interviewDetails}</Text>
                  </View>
                </View>
              </View>
            </>
          ) : null}
        </View>
      </Page>
    </Document>
  );
}
