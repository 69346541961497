import { Badge, Descriptions, Upload, UploadFile } from 'antd';
import { getName } from 'country-list';
import moment from 'moment';
import { Fragment } from 'react';
import { ActivityType, ChargeType, Request } from '../../../graphql/generated';
import { ActivityTypeToDisplayText } from '../../shared/models/rfq/ActivityType';
import { ResidenciesToDisplayText } from '../../shared/models/rfq/Residencies';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { WorkingArrangementsToDisplayText } from '../../shared/models/rfq/RfqWorkingArrangements';
import { CurrencyFormatter } from '../../shared/utils/IntlUtils';

interface RequestDescriptionProps {
  request: Request;
}

export function RequestDescription({ request }: RequestDescriptionProps) {
  return (
    <Fragment>
      <Descriptions title="Request Info" bordered>
        <Descriptions.Item label="Requested Seller">{request.seller?.name}</Descriptions.Item>
        <Descriptions.Item span={2} label="Number of Submissions Requested">
          {request.numberOfCandidates}
        </Descriptions.Item>
      </Descriptions>
      <Descriptions title="RFQ Overview" bordered>
        <Descriptions.Item span={3} label="RFQ Number">
          {request.rfq?.rFQNumber}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Rfq Title">
          {request.rfq?.title}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Activity Type">
          {ActivityTypeToDisplayText[request.rfq?.activityType as ActivityType]}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Status">
          <Badge status={request.rfq?.status ? 'success' : 'error'} text={request.rfq?.status ? 'Open' : 'Closed'} />
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Industry">
          {request.rfq?.industry?.name ?? 'Unassigned'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Buyer">
          {request.rfq?.buyer?.name ?? 'Unassigned'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Country">
          {getName(request?.rfq?.country || '') ?? 'Unassigned'}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Location">
          {request.rfq?.location.join(', ')}
        </Descriptions.Item>
        {request.rfq?.extensionTerms && (
          <Descriptions.Item span={3} label="Extension Terms">
            {request.rfq?.extensionTerms}
          </Descriptions.Item>
        )}
        <Descriptions.Item span={3} label="Residency">
          {request.rfq?.residency.map((value, index) => (
            <p key={index}>{ResidenciesToDisplayText[value]}</p>
          ))}
        </Descriptions.Item>
        <Descriptions.Item label="RFQ Published Date">
          {moment(request.rfq?.rFQPublishedDate).format('MMMM Do YYYY')}
        </Descriptions.Item>
        <Descriptions.Item label="Request Closure Date">
          {moment(request.rfq?.requestClosureDate).format('MMMM Do YYYY, h:mm:ss a')}
        </Descriptions.Item>
        <Descriptions.Item span={3} label="Estimated Start Date">
          {moment(request.rfq?.estimatedStartDate).format('MMMM Do YYYY')}
        </Descriptions.Item>
        {request.rfq?.contractDuration && (
          <Descriptions.Item span={3} label="Contract Duration">
            {moment(request.rfq?.contractDuration).format('MMMM Do YYYY')}
          </Descriptions.Item>
        )}
        {request.rfq?.workingArrangement && (
          <Descriptions.Item span={3} label="Working Arrangement">
            {WorkingArrangementsToDisplayText[request.rfq?.workingArrangement]}
          </Descriptions.Item>
        )}
        <Descriptions.Item span={3} label="Security Clearance">
          {request.rfq?.security}
        </Descriptions.Item>
        <Descriptions.Item label="Max. Submissions">{request.numberOfCandidates}</Descriptions.Item>
        <Descriptions.Item span={3} label={request.rfq?.maxCharge ? 'Max. Charge' : 'Charge Type'}>
          {`${request.rfq?.maxCharge ? CurrencyFormatter.format(request.rfq.maxCharge) : ''} ${
            request.rfq?.currencyCode
          } ${ChargeTypeOptionsToAbbreviatedText[request.rfq!.chargeType]}`}
        </Descriptions.Item>
        <Descriptions.Item
          span={3}
          label={request.overrideAttachments?.length != 0 ? 'Original Attachments' : 'Attachments'}
        >
          <Upload
            style={{ maxHeight: undefined }}
            disabled={true}
            fileList={
              request.rfq?.attachments?.map((att) => {
                return {
                  name: att?.fileName,
                  uid: att?.objectKey,
                  url: att?.url,
                };
              }) as Array<UploadFile>
            }
            listType="text"
          ></Upload>
        </Descriptions.Item>
        {request.overrideAttachments?.length != 0 && (
          <Descriptions.Item span={3} label="New Attachments">
            <Upload
              disabled={true}
              fileList={
                request.overrideAttachments?.map((att) => {
                  return {
                    name: att?.fileName,
                    uid: att?.objectKey,
                    url: att?.url,
                  };
                }) as Array<UploadFile>
              }
              listType="text"
            ></Upload>
          </Descriptions.Item>
        )}
      </Descriptions>
    </Fragment>
  );
}
