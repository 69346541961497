import CreateBuyerForm from '../components/CreateBuyerForm';

export default function CreateBuyerPage() {
  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h2>New Buyer</h2>

        <CreateBuyerForm className="max-w-3xl" />
      </div>
    </>
  );
}
