import { Alert, Button, message } from 'antd';
import { Form, Formik } from 'formik';
import {
  Attachment,
  ChargeType,
  RequestSubmission,
  SellerUpdateRequestSubmissionInput,
  useGetRequestSubmissionsByIdQuery,
  useSellerUpdateRequestSubmissionMutation,
} from '../../../graphql/generated';
import MultiFileUploader from '../../shared/components/files/MultiFileUploader';
import { ComboBoxItem } from '../../shared/components/form/ComboBoxInput';
import NumberFormInput from '../../shared/components/form/NumberFormInput';
import SelectInput from '../../shared/components/form/SelectInput';
import TextFormInput from '../../shared/components/form/TextFormInput';
import SpinLoader from '../../shared/components/loader/SpinLoader';
import { useCitiesState } from '../../shared/hooks/UseCitiesState';
import useMultiFileUpload, { AttachmentInfo, GSFile, GSFiles } from '../../shared/hooks/UseMultiFileUpload';
import { ResidenciesToComboBoxItems } from '../../shared/models/rfq/Residencies';
import { ResourceAvailabilityComboBoxItems } from '../../shared/models/rfq/ResourceAvailability';
import { ChargeTypeOptionsToAbbreviatedText } from '../../shared/models/rfq/RfqChargeType';
import { SecurityClearanceComboboxItems } from '../../shared/models/rfq/RfqSecurityClearance';
import { SellerEditRequestSubmissionValidator } from '../validators/RequestSubmissionValidator';

type EditRequestSubmissionFormProps = {
  requestSubmissionId?: string | null;
  handleSubmit: () => void;
  handleCancel: () => void;
};

export default function SellerEditRequestSubmissionForm({
  requestSubmissionId,
  handleSubmit,
  handleCancel,
}: EditRequestSubmissionFormProps) {
  const {
    data: data,
    loading: loading,
    error: error,
  } = useGetRequestSubmissionsByIdQuery({
    variables: {
      requestSubmissionId: requestSubmissionId as string,
    },
    fetchPolicy: 'no-cache',
  });
  const [sellerEditRequestSubmission] = useSellerUpdateRequestSubmissionMutation();
  const { setFiles, uploadingInfo: uploading, handleMultiFileUpload } = useMultiFileUpload();
  const requestSubmission = data?.requestSubmissionById as RequestSubmission;
  const cities = useCitiesState(requestSubmission?.request?.rfq?.country as string);

  const handleUpdateRequestSubmission = async (
    submmission: SellerUpdateRequestSubmissionInput,
  ): Promise<RequestSubmission> => {
    const response = await sellerEditRequestSubmission({
      variables: {
        input: submmission,
      },
    });

    const attachments = response.data?.sellerUpdateRequestSubmission.attachments;
    if (attachments) {
      await handleMultiFileUpload(attachments as AttachmentInfo[]);
    }

    return response.data?.sellerUpdateRequestSubmission as RequestSubmission;
  };
  if (loading) {
    return <SpinLoader />;
  } else if (error) {
    return (
      <Alert
        showIcon
        type="error"
        message="An unknown error has occurred whilst fetching request submissions, please wait two minutes and try again or contact support."
      />
    );
  }

  return (
    <Formik
      initialValues={SellerEditRequestSubmissionValidator.cast(
        {
          ...requestSubmission,
          attachments: requestSubmission ? requestSubmission.attachments?.map((x) => x?.fileName) : [],
        } ?? {},
      )}
      validationSchema={SellerEditRequestSubmissionValidator}
      onSubmit={async (values) => {
        try {
          //Create the file in backend
          const validSubmission = SellerEditRequestSubmissionValidator.cast(values, { stripUnknown: true });
          const response: RequestSubmission = await handleUpdateRequestSubmission(
            validSubmission as SellerUpdateRequestSubmissionInput,
          );

          //Use the presigned url in the response to upload to s3
          await handleMultiFileUpload(response.attachments as AttachmentInfo[]);

          message.success('Successfully updated the request submission');
          handleSubmit();
        } catch (ex) {
          console.error(ex);
          message.error('An error ocurred whilst editing the request submission');
        }
      }}
    >
      {({ submitForm, values }) => {
        return (
          <Form>
            <TextFormInput name="name" placeholder="Name" label="Name/Proposal" />
            {cities ? (
              <SelectInput
                name="location"
                availableItems={cities as ComboBoxItem[]}
                placeholder="Location"
                label="Location"
                selectMultiple
                defaultValue={values.location}
              />
            ) : (
              <SpinLoader />
            )}
            <SelectInput
              name="residency"
              availableItems={ResidenciesToComboBoxItems}
              placeholder="Residency"
              label="Residency"
            />
            <SelectInput
              name="security"
              availableItems={SecurityClearanceComboboxItems}
              placeholder="Security"
              label="Security"
            />
            <SelectInput
              name="availability"
              availableItems={ResourceAvailabilityComboBoxItems}
              placeholder="Availability"
              label="Availability"
            />
            <NumberFormInput
              name="chargeRate"
              placeholder="Charge Rate"
              label={`Charge (${requestSubmission.request?.rfq?.currencyCode} ${
                ChargeTypeOptionsToAbbreviatedText[requestSubmission.request?.rfq?.chargeType as ChargeType]
              })`}
              currency
              defaultValue={values.chargeRate}
              chargeType={requestSubmission.request?.rfq?.chargeType as ChargeType}
            />
            <MultiFileUploader
              name="attachments"
              maxSize={20000000}
              maxCount={10}
              initialFiles={requestSubmission?.attachments?.map((att) => {
                const attach = att as Attachment;
                return { name: attach.fileName, uid: attach.objectKey, status: 'done' };
              })}
              onFilesChanged={(files) => {
                const fileObj = {} as GSFiles;
                files.forEach((file) => {
                  fileObj[file.name] = new GSFile(file);
                });
                setFiles(fileObj);
              }}
              progress={uploading.completion}
            />

            <div className="flex flex-row justify-end gap-2">
              <Button type="default" onClick={handleCancel}>
                Cancel
              </Button>
              <Button loading={loading} type="primary" onClick={submitForm}>
                Save
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}
