import { Channel } from '@chatkitty/core';
import { message } from 'antd';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { isFunction } from '../../shared/utils/TypeGuardUtils';
import { LastMessageSummary } from '../models/LastMessageSummary';
import { chatService } from './ChatService';

export type AdminChannelListContextType = {
  channels: Array<Channel>;
  loading: boolean;
  fetchChannelList: () => Promise<void>;
  updateChannelLastReceivedMessage: (channelId: number, lastReceivedMessage: LastMessageSummary) => void;
};

export const AdminChannelListContext = React.createContext<AdminChannelListContextType>(
  {} as AdminChannelListContextType,
);

interface AdminChannelListProviderProps {
  children: React.ReactNode | ((values: AdminChannelListContextType) => React.ReactNode);
  sellerIds: Array<string>;
}

export default function AdminChannelListProvider({ children, sellerIds }: AdminChannelListProviderProps): JSX.Element {
  const [channels, setChannels] = useState<Array<Channel>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    fetchChannelList();
  }, []);

  const updateChannelLastReceivedMessage = (channelId: number, lastReceivedMessage: LastMessageSummary) => {
    setChannels((prevChannels) => {
      const channels = _.cloneDeep(prevChannels);
      return channels.map((channel) => {
        if (channel.id === channelId) {
          return {
            ...channel,
            lastReceivedMessage: {
              body: lastReceivedMessage.body,
              createdTime: lastReceivedMessage.time,
              user: {
                name: lastReceivedMessage.senderName,
              },
            },
          };
        }
        return channel;
      });
    });
  };

  const fetchChannelList = async () => {
    setLoading(true);
    try {
      const channels = await chatService.getChannelsForSellers(sellerIds);
      setChannels(channels.map((ch) => ({ ...ch } as Channel)));
    } catch (ex) {
      message.error(
        'An error occurred whilst loading the list of conversations, please try again in two minutes or contact support.',
      );
    }
    setLoading(false);
  };

  const value = {
    channels,
    loading,
    fetchChannelList,
    updateChannelLastReceivedMessage,
  };

  return (
    <AdminChannelListContext.Provider value={value}>
      {isFunction(children) ? children(value) : children}
    </AdminChannelListContext.Provider>
  );
}
