import { BookOutlined, DiffOutlined, MessageOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import ChangelogPage from '../../changelog/pages/ChangelogPage';
import ChatNavigationTab from '../../chat/components/navigation-tab/ChatNavigationTab';
import AdminChatPage from '../../chat/pages/AdminChatPage';
import UserGuidePage from '../../user-guide/pages/UserGuidePage';
import { CurrentUserRole } from '../models/user/UserRole';
import { buyerRoutes } from './BuyerRoutes';
import { sellerRoutes } from './SellerRoutes';
import { requestRoutes } from './RequestRoutes';
import { rfqRoutes } from './RfqRoutes';
import { localRoutingConstants } from './RoutingConstants';
import { userManagementRoutes } from './UserManagementRoutes';
import { industryRoutes } from './IndustryRoutes';

export interface GuardedRoute extends BreadcrumbsRoute {
  label: string;
  path: string;
  allowedRoles: Array<CurrentUserRole>;
  element: ReactNode;
  icon?: ReactNode;
  inSidebar?: boolean;
  RenderSidebarTab?: () => JSX.Element;
}

interface GuardedRoutes {
  [key: string]: GuardedRoute;
}

export const authenticatedRoutes: GuardedRoutes = {
  ...requestRoutes,
  ...rfqRoutes,
  ...buyerRoutes,
  ...sellerRoutes,
  ...userManagementRoutes,
  ...industryRoutes,
  chat: {
    label: 'Chat',
    path: localRoutingConstants.chat.root,
    icon: <MessageOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin],
    element: <AdminChatPage />,
    RenderSidebarTab: function () {
      return <ChatNavigationTab />;
    },
  },
  userGuide: {
    label: 'User Guide',
    path: localRoutingConstants.userGuide.root,
    icon: <BookOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin, CurrentUserRole.SellerAdmin],
    element: <UserGuidePage />,
  },
  changelog: {
    label: 'Changelog',
    path: localRoutingConstants.changelog.root,
    icon: <DiffOutlined />,
    inSidebar: true,
    allowedRoles: [CurrentUserRole.GSAdmin, CurrentUserRole.SellerAdmin],
    element: <ChangelogPage />,
  },
};
